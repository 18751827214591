import styled from 'styled-components';

export const Header = styled.div`
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #404b5a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
`;

export const Icon = styled.img`
    @media (max-width: 959px) {
        display: none;
    }
`;
