import React, { useRef, useEffect } from 'react';
import { Close } from '@material-ui/icons';

import { Wrapper, Backdrop, Modal, Header, Title, CloseButton, Body } from './components';

const DesktopModal = ({ children, title, active, onClose, maxWidth = 1280, fixedWidth = false }) => {
    const backdropRef = useRef(null);

    useEffect(() => {
        if (active) {
            document.body.classList.add('hidden');
        } else {
            document.body.classList.remove('hidden');
        }
    }, [active]);

    const handleClose = () => {
        onClose();
    };

    const handleBackdropClick = event => {
        if (event.target === backdropRef.current) {
            handleClose();
        }
    };

    return (
        <Wrapper isShow={active}>
            <Backdrop isShow={active} onClick={handleBackdropClick} ref={backdropRef}>
                <Modal isShow={active} maxWidth={maxWidth} fixedWidth={fixedWidth}>
                    <Header>
                        <Title>{title}</Title>

                        <CloseButton onClick={handleClose} size="small">
                            <Close />
                        </CloseButton>
                    </Header>

                    <Body>{children}</Body>
                </Modal>
            </Backdrop>
        </Wrapper>
    );
};

export default DesktopModal;
