import React, { Fragment } from 'react';
import { useStyles } from './styles';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import useLocalization from '../../../../contexts/localization/hook';

const RoutesInfo = props => {
    const classes = useStyles();
    const { language } = useLocalization();

    const { routes, included } = props;
    let data = [];

    _.map(routes, function (route, index) {
        const { segments } = route;
        const firstSegment = _.first(segments);

        if (firstSegment) {
            if (segments.length > 1) {
                const lastSegment = _.last(segments);
                data[index] = [firstSegment.departure.city, lastSegment.arrival.city];
            } else {
                data[index] = [firstSegment.departure.city, firstSegment.arrival.city];
            }
        }
    });

    const countData = data.length;

    if (countData === 2 && data[0][0] === data[1][1]) {
        const departure = included.city[data[0][0]][`name_${language}`];
        const arrival = included.city[data[1][0]][`name_${language}`];

        return (
            <Grid container>
                <Grid item>{departure}</Grid>
                <Grid item className={classes.arrowIcon}>
                    <SyncAltIcon />
                </Grid>
                <Grid item>{arrival}</Grid>
            </Grid>
        );
    } else if (countData > 0) {
        const departureFirstRoute = included.city[_.first(data)[0]][`name_${language}`];
        const arrivalFirstRoute = included.city[_.first(data)[1]][`name_${language}`];
        let departureSecondRoute = '';

        if (countData > 1) {
            departureSecondRoute = included.city[data[1][0]][`name_${language}`];
        }

        return (
            <Fragment>
                <Grid container>
                    <Grid item>{departureFirstRoute}</Grid>
                    <Grid item className={classes.arrowIcon}>
                        <TrendingFlatIcon />
                    </Grid>
                    <Grid item>{arrivalFirstRoute}</Grid>
                    {countData > 1 && <Grid item>, {departureSecondRoute}...</Grid>}
                </Grid>
            </Fragment>
        );
    } else {
        return null;
    }
};

export default RoutesInfo;
