import React, { useState } from 'react';

import {
    Accordion,
    AccordionHeader,
    AccordionHeaderTitle,
    AccordionHeaderButton,
    AccordionHeaderButtonIcon,
    AccordionBody,
    AccordionFooter,
    AccordionFooterButton,
} from './components';

const AccordionComponent = ({ children, isActive, title, footerButtonText = null, footerButtonOnClick }) => {
    const [active, setActive] = useState(isActive);

    return (
        <Accordion>
            <AccordionHeader>
                <AccordionHeaderTitle onClick={() => setActive(prev => !prev)}>{title}</AccordionHeaderTitle>

                <AccordionHeaderButton onClick={() => setActive(prev => !prev)}>
                    <AccordionHeaderButtonIcon className={active ? 'active' : ''} />
                </AccordionHeaderButton>
            </AccordionHeader>

            <AccordionBody active={active}>{children}</AccordionBody>

            {footerButtonText && (
                <AccordionFooter active={active}>
                    <AccordionFooterButton onClick={footerButtonOnClick}>{footerButtonText}</AccordionFooterButton>
                </AccordionFooter>
            )}
        </Accordion>
    );
};

export default AccordionComponent;
