import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

function NativeSelectStatelessUI(props) {
    const { input, disabled, value, items, fieldError } = props;

    const downCaret =
        "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='%23000000'><polygon points='0,0 50,50 100,0'/></svg>";

    const useStyles = makeStyles({
        root: {
            width: '100%',
            color: '#000',
            border: '0 !important',
            fontSize: '18px',
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            background: `url("${downCaret}") no-repeat`,
            backgroundSize: '12px',
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
            display: 'block',
            padding: '0 !important',
            margin: '0 !important',
            borderRadius: '12px',
        },
    });
    const useCustom = makeStyles({
        block: {
            boxSizing: 'border-box',
            border: `${fieldError || (props?.meta?.touched && props?.meta?.error) ? '2px' : '1px'} solid ${
                fieldError || (props?.meta?.touched && props?.meta?.error) ? '#c62828' : '#C3D6E8'
            }`,
            borderRadius: '12px',
            padding: '26px 14px 12px',
            backgroundColor: '#fff',
            '& > *': {
                width: '100%',
            },
        },
    });

    const classes = useStyles();
    const customStyles = useCustom();

    return (
        <div className={customStyles.block}>
            <select {...input} disabled={disabled} className={classes.root}>
                {items.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default NativeSelectStatelessUI;
