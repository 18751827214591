import React from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import Slider from 'react-slick';
import config from '../../../../../../config';

import { useStyles } from './styles';
import { NavLink } from 'react-router-dom';

const Carousel = ({ tours }) => {
    const classes = useStyles();
    const { envConfigs } = config;

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '15%',
        infinite: true,
        pauseOnHover: true,
        arrows: true,
        dots: true,
        customPaging: i => <div className={classes.carouselDot} />,
        dotsClass: `slick-dots ${classes.carouselDots}`,
        autoplay: false,
        autoplaySpeed: 0,
        speed: 300,
        draggable: true,
        useCSS: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    draggable: false,
                    arrows: false,
                },

                breakpoint: 959,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <Grid container className={classes.carouselBlock}>
            <Grid item xs={12}>
                <Slider {...settings} className={classes.carouselItemBlock}>
                    {tours.map((item, index) => {
                        return (
                            <div className={classes.carouselItemBlock} key={index}>
                                <NavLink className={classes.carouselItem} to={`/tours/${item.slug}`}>
                                    <img
                                        src={item.photo ? `${envConfigs.uploadHost}${item.photo}` : null}
                                        className={classes.carouselItemImage}
                                    />
                                </NavLink>
                            </div>
                        );
                    })}
                </Slider>
            </Grid>
        </Grid>
    );
};

export default Carousel;
