import styled from 'styled-components';

export const Title = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #404b5a;
`;

export const Image = styled.img`
    width: 100%;
`;

export const Description = styled.h3`
    text-align: left;
    margin-bottom: 16px;
    color: #404b5a;
`;

export const Body = styled.div`
    padding-bottom: 32px;

    & img {
        max-width: 100% !important;
        height: auto !important;
    }
`;

export const Date = styled.h5`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #404b5a;
`;
