import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { normalizePrice } from '../../../../../../lib/normalize';
import RangeSlider from '../rangeSlider';
import FilterBlock from '../filterBlock';
import { setFilterParams } from '../../../../../../store/filters/actions';
import useLocalization from '../../../../../../contexts/localization/hook';

const PriceFilter = () => {
    const { t } = useLocalization();
    const { filterParams, filtersData } = useSelector(state => state.filters);
    const { price } = filterParams;
    const { currency } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();

    useEffect(() => {
        handleChangeCurrency();
    }, [currency, filtersData]);

    const handleChangeCurrency = () => {
        if (
            price.values.min < filtersData.priceRange.min ||
            price.values.min > filtersData.priceRange.max ||
            price.values.max < filtersData.priceRange.min ||
            price.values.max > filtersData.priceRange.max
        ) {
            dispatch(
                setFilterParams({
                    type: 'price',
                    values: {
                        isActive: false,
                        values: filtersData.priceRange,
                    },
                })
            );
        }
    };

    const handleChange = value => {
        if (value.min !== filtersData.priceRange.min || value.max !== filtersData.priceRange.max) {
            dispatch(
                setFilterParams({
                    type: 'price',
                    values: {
                        isActive: true,
                        values: value,
                    },
                })
            );
        } else {
            dispatch(
                setFilterParams({
                    type: 'price',
                    values: {
                        isActive: false,
                        values: value,
                    },
                })
            );
        }
    };

    const TitleComponent = (minValue, maxValue) => {
        return (
            <div>
                {normalizePrice(minValue)} — {normalizePrice(maxValue)} {currency}
            </div>
        );
    };

    return (
        <FilterBlock title={t.price} isOpen borderBottom>
            <RangeSlider
                range={filtersData.priceRange}
                filter={price.values}
                handleChange={handleChange}
                titleComponent={TitleComponent}
            />
        </FilterBlock>
    );
};

export default PriceFilter;
