export const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_ALL_FILTERS = 'SET_ALL_FILTERS';
export const SET_ADDITIONALLY_OPTINS = 'SET_ADDITIONALLY_OPTINS';
export const SET_BRAND_FARES = 'SET_BRAND_FARES';
export const SET_BRAND_RULES = 'SET_BRAND_RULES';
export const SET_BRAND_FARE_REC_ID = 'SET_BRAND_FARE_REC_ID';
export const RESET_AAL_FILTERS = 'RESET_AAL_FILTERS';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';

export const BY_MIN_PRICE = 'BY_MIN_PRICE';
export const BY_MAX_PRICE = 'BY_MAX_PRICE';

export const COLOR_THEME = {
    0: '#6F5BA6',
    1: '#5BA554',
    2: '#FFA726',
    3: '#42A5F5',
    4: '#EF5350',
    5: '#26A69A',
};
