import { SET_ORDERS_DATA, SET_ORDERS_FILTERS, SET_CHILD_PARTNERS, SET_SHOW_ACTIONS_FOR_ORDER } from './constants';

export const setOrdersData = data => dispatch =>
    dispatch({
        type: SET_ORDERS_DATA,
        payload: data,
    });

export const setOrdersFilters = data => dispatch =>
    dispatch({
        type: SET_ORDERS_FILTERS,
        payload: data,
    });

export const setChildPartners = data => dispatch =>
    dispatch({
        type: SET_CHILD_PARTNERS,
        payload: data,
    });

export const setShowActionsForOrder = data => dispatch =>
    dispatch({
        type: SET_SHOW_ACTIONS_FOR_ORDER,
        payload: data,
    });
