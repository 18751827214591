import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Container } from '@material-ui/core';
import moment from 'moment';

import { setAppConfigs } from '../../store/appConfigs/actions';
import useLocalization from '../../contexts/localization/hook';
import API from '../../lib/api';
import configs from '../../config';

import { Title, Image, Body, Date } from './components';
import Loading from '../../components/design_v2/ui/loading';
import NotFoundContent from '../../components/design_v2/ui/notFoundContent';

const SingleNews = props => {
    const {
        match: { params },
    } = props;
    const { slug } = params;
    const { envConfigs } = configs;

    const dispatch = useDispatch();
    const location = useLocation();
    const { t, language } = useLocalization();

    const containerRef = useRef(null);

    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(location.pathname);

    useEffect(() => {
        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    useEffect(() => {
        getOneNews();
    }, [language]);

    useEffect(() => {
        if (location.pathname !== currentLocation) {
            getOneNews();
            setCurrentLocation(location.pathname);
        }
    }, [location, currentLocation]);

    const executeScroll = () => {
        const y = containerRef?.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const getOneNews = async () => {
        setIsLoading(true);
        setNotFound(false);

        await API.apiGetNewsBySlug({ slug, language }).then(response => {
            if (response.data) {
                setNews(response.data);
                document.title = `${response.data.contents[0].title} | ${envConfigs.appTitle}`;
            } else {
                setNotFound(true);
                document.title = `${t.page_home} | ${envConfigs.appTitle}`;
            }
        });

        setIsLoading(false);
        executeScroll();
    };

    return (
        <Container fixed ref={containerRef}>
            {isLoading && <Loading />}

            {!isLoading && notFound && <NotFoundContent />}

            {!isLoading && !notFound && (
                <>
                    <Title>{news.contents[0].title}</Title>
                    {news.photo && <Image src={`${envConfigs.uploadHost}${news.photo}`} alt="News image" />}
                    <Date>{moment(news.news_date).format('DD.MM.YYYY')}</Date>
                    <Body dangerouslySetInnerHTML={{ __html: news.contents[0].body }} />
                </>
            )}
        </Container>
    );
};

export default SingleNews;
