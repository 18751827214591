import { Container } from '@material-ui/core';
import styled from 'styled-components';

export const CarouselBlock = styled(Container)`
    margin: 8px 0 32px;
`;

export const Header = styled.div`
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #404b5a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
`;

export const Icon = styled.img`
    @media (max-width: 959px) {
        display: none;
    }
`;

export const SliderItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;

    @media (max-width: 599px) {
        height: 80px;
    }

    & > a {
        height: 100%;
        display: flex;
        padding: 24px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }
`;

export const SliderImage = styled.img`
    display: block;
    width: 100%;
    height: auto;
`;
