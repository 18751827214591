import React from 'react';
import { useHistory } from 'react-router-dom';
import defaultLogo from '../../../../../images/mainLogo/fly55.png';
import iataLogo from '../../../../../images/mainLogo/others/iataBlack.png';
import tkpLogo from '../../../../../images/mainLogo/others/tkpBlack.png';
import { Wrapper, LogoImage, IataLogoImage, TKPLogoImage } from './components';

const Logo = () => {
    const history = useHistory();
    const handleOnClick = () => {
        history.push('/');
    };
    return (
        <Wrapper>
            <LogoImage src={defaultLogo} onClick={() => handleOnClick()} alt="55-fly-logo" />
            <IataLogoImage alt="Iata logo icon" src={iataLogo} />
            <TKPLogoImage alt="TKP logo icon" src={tkpLogo} />
        </Wrapper>
    );
};

export default Logo;
