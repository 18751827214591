import React from 'react';
import RoutesData from './routesData';
import PriceForm from './priceForm';
import PassengerInfoForm from './passengerInfoForm';
import LocalTimeText from '../ui/components/LocalTimeText/LocalTimeText';
import { useStyles } from '../../containers/booking/styles';
import _ from 'lodash';
import { Container, Grid } from '@material-ui/core';

export default function BookingForm(props) {
    const classes = useStyles();

    const {
        totalPrice,
        passengers,
        onUpdateCitizenship,
        getCountries,
        resetCountries,
        onSendBooking,
        currency,
        countries,
        setPassengerType,
        formValues,
        routes,
        clearDocumentData,
        formErrors,
        clientFees,
        setPayerEmailToPassenger,
        setPayerPhoneToPassenger,
        onSelectAvailableDocumentTypes,
    } = props;

    const lastRoute = _.last(routes);
    const deadlineDate = _.last(lastRoute.segments).arrival.time;

    const partnerFees = _.isUndefined(formValues) ? 0 : formValues.partnerFees;

    return (
        <Container fixed className={classes.bookingContainer}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={9} className={classes.blockLeft}>
                    <Grid container>
                        <Grid item xs={12}>
                            <RoutesData {...props} />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalTimeText />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={3} className={classes.blockRight}>
                    <PriceForm
                        currency={currency}
                        totalPrice={totalPrice}
                        partnerFees={partnerFees}
                        clientFees={clientFees}
                    />
                </Grid>
            </Grid>
            
            <Grid container spacing={4}>
                <Grid item xs={12} lg={9} className={classes.blockPassengers}>
                    <PassengerInfoForm
                        typeArrayForm="passengers"
                        formErrors={formErrors}
                        onUpdateCitizenship={onUpdateCitizenship}
                        passengers={passengers}
                        getCountries={getCountries}
                        resetCountries={resetCountries}
                        countries={countries}
                        onSendBooking={onSendBooking}
                        setPassengerType={setPassengerType}
                        formValues={formValues}
                        clearDocumentData={clearDocumentData}
                        deadlineDate={deadlineDate}
                        setPayerEmailToPassenger={setPayerEmailToPassenger}
                        setPayerPhoneToPassenger={setPayerPhoneToPassenger}
                        routes={routes}
                        fareRules={props.fareRules}
                        included={props.included}
                        onSelectAvailableDocumentTypes={onSelectAvailableDocumentTypes}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
