import React from 'react';
import useLocalization from '../../../../contexts/localization/hook';

import { Wrapper, Title, Description } from './components';

const NotFoundContent = () => {
    const { t } = useLocalization();

    return (
        <Wrapper>
            <Title>{t.not_found_content}</Title>
            <Description>{t.not_found_content_text}</Description>
        </Wrapper>
    );
};

export default NotFoundContent;
