import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
`;

export const Paragraph = styled.p`
    margin: 4px 0 8px;
    font-size: 14px;
`;

export const StyledButton = styled(Button)`
    background-color: #32abca !important;
    color: #fff !important;
    padding: 8px 12px !important;

    & > span {
        display: flex;
        align-items: center;
        line-height: 1;
    }

    &:hover {
        background-color: #32abca !important;
        opacity: 1;
    }
`;
