import styled from 'styled-components';

export const Title = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #404b5a;
`;

export const FormGroup = styled.div`
    width: 100%;
    max-width: 570px;
    margin: 0 auto 20px;
`;

export const PassengersTitle = styled.h2`
    text-align: center;
    color: #404b5a;
    margin: 32px 0;
`;

export const PassengerItem = styled.div`
    width: 100%;
    max-width: 570px;
    margin: 0 auto 48px;
`;

export const PassengerRemoveBlock = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const PassengerRemoveButton = styled.button`
    color: #ff0000;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
`;

export const PassengerFormInput = styled.div`
    margin-bottom: 20px;
`;

export const PassengerAddButtonBlock = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const PassengerAddButton = styled.button`
    padding: 12px 24px;
    font-size: 14px;
    font-weight: bold;
    background-color: #e4e9f3;
    color: #404b5a;
    border: none;
    border-radius: 12px;
    cursor: pointer;
`;

export const SubmitButton = styled.button`
    margin: 20px auto 48px;
    height: 60px;
    min-width: 184px;
    background-color: #00ddff;
    border: none;
    border-radius: 12px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    display: block;

    @media (max-width: 959px) {
        margin-top: 30px;
        font-size: 20px;
    }
`;
