import { IconButton } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    
    to {
        transform: translateY(100%); 
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    display: ${props => (props.isShow ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 250;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 251;
    animation-name: ${props => (props.isShow ? fadeIn : fadeOut)};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: 90%;
    width: ${props => (props.fixedWidth ? `${props.maxWidth}px` : 'auto')};
    max-width: ${props => props.maxWidth}px;
    position: relative;
    border-radius: 8px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: 18px;
`;

export const CloseButton = styled(IconButton)`
    margin-right: 16px;
`;

export const Body = styled.div`
    padding: 0 16px 16px;
    overflow-y: auto;
`;
