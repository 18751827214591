import React from 'react';
import { F, M } from '../../containers/booking/constants';
import useLocalization from '../../contexts/localization/hook';
import SelectFormUI from '../ui/components/SelectFormUI/SelectFormUI';

export default function GenderField(props) {
    const { t } = useLocalization();

    const genderItem = [
        { value: '', label: t.choose_gender, disabled: true },
        { value: M, label: t.male },
        { value: F, label: t.female },
    ];

    return <SelectFormUI {...props} items={genderItem} width="100%" height="auto" label={t.gender} />;
}
