import { BY_MIN_PRICE } from './constants';

const initialState = {
    filterParams: {
        baggage: {
            isActive: false,
            values: {
                withBaggage: true,
                withoutBaggage: true,
            },
        },

        transfers: {
            isActive: false,
            values: {
                withoutTransfers: true,
                oneTransfer: true,
                manyTransfers: true,
            },
        },

        sorting: {
            isActive: false,
            value: BY_MIN_PRICE,
        },

        airlines: {
            isActive: false,
            values: [],
        },

        flightNumbers: {
            isActive: false,
            values: [],
        },

        price: {
            isActive: false,
            values: {
                min: 0,
                max: 100,
            },
        },

        airports: {
            isActive: false,
            values: [], // { routeIndex: 0, departures: ['LED', 'DYU'], arrivals: ['LED', 'DME'] }
        },

        duration: {
            isActive: false,
            values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
        },

        departureTime: {
            isActive: false,
            values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
        },

        arrivalTime: {
            isActive: false,
            values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
        },

        pricesTable: {
            isActive: false,
            values: {
                type: null,
                supplier: null,
                price: null,
            },
        },
    },

    filtersData: {
        priceRange: {
            min: 0,
            max: 100,
        },
        durationRange: {
            min: null,
            max: null,
        },
        routesData: [],
    },
};

export default initialState;
