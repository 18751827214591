import React from 'react';
import { Container } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';

import {
    Title,
    TableBlock,
    Table,
    Tr,
    Th,
    Td,
    NoResultsBlock,
    PaginationBlock,
} from '../../components/design_v2/transactions/components';
import { setTransactionsFilters } from '../../store/transactions/actions';
import Loading from '../../components/design_v2/ui/loading';
import useLocalization from '../../contexts/localization/hook';
import { normalizePrice } from '../../lib/normalize';

const Transactions = ({ getTransactions }) => {
    const { transactionsItems, transactionsFilters, isLoading } = useSelector(state => state.transactions);
    const { t } = useLocalization();
    const dispatch = useDispatch();

    const handlePageChange = (event, page) => {
        dispatch(setTransactionsFilters({ currentPage: page }));
        getTransactions(page);
    };

    return (
        <Container fixed>
            <Title>{t.transactions}</Title>

            {isLoading ? (
                <NoResultsBlock>
                    <Loading />
                </NoResultsBlock>
            ) : (
                <TableBlock>
                    {transactionsItems.length > 0 ? (
                        <Table>
                            <thead>
                                <Tr background="#404B5A">
                                    <Th>{t.date}</Th>
                                    <Th>{t.income}</Th>
                                    <Th>{t.spending}</Th>
                                    <Th>{t.remainder}</Th>
                                    <Th>{t.description}</Th>
                                </Tr>
                            </thead>

                            <tbody>
                                {transactionsItems.map(item => {
                                    return (
                                        <Tr key={`transaction-item-${item.id}`}>
                                            <Td>
                                                {item.created_at
                                                    .split('T')
                                                    .splice(0, 1)
                                                    .join('')
                                                    .split('-')
                                                    .reverse()
                                                    .join('.')}
                                            </Td>
                                            <Td textColor="green">
                                                {item.amount >= 0
                                                    ? `${normalizePrice(item.amount)} ${item.currency}`
                                                    : ''}
                                            </Td>
                                            <Td textColor="red">
                                                {item.amount < 0
                                                    ? `${normalizePrice(item.amount)} ${item.currency}`
                                                    : ''}
                                            </Td>
                                            <Td textColor="#00ddff">
                                                {normalizePrice(item.balance)} {item.currency}
                                            </Td>
                                            <Td>{item.comment}</Td>
                                        </Tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : (
                        <NoResultsBlock>{t.no_result}!</NoResultsBlock>
                    )}
                </TableBlock>
            )}

            {transactionsItems.length > 0 && (
                <PaginationBlock>
                    <Pagination
                        onChange={handlePageChange}
                        count={transactionsFilters.lastPage}
                        shape="rounded"
                        variant="outlined"
                    />
                </PaginationBlock>
            )}
        </Container>
    );
};

export default Transactions;
