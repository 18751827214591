import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setPassengers } from '../../../../../../../store/searchParams/actions';
import useLocalization from '../../../../../../../contexts/localization/hook';
import {
    Block,
    Row,
    TextBlock,
    TextTitle,
    TextDescription,
    ButtonsBlock,
    Button,
    Count,
    TextPlain,
} from './components';
import { ReactComponent as MinusIcon } from '../../../../../../../assets/icons/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/plusIcon.svg';

const PassengersBlock = () => {
    const { t } = useLocalization();
    const { passengers } = useSelector(state => state.searchParams);
    const dispatch = useDispatch();

    const handleMinusClick = type => {
        const currentPassengers = { ...passengers };

        if (type === 'adt' && currentPassengers.adt > 1 && currentPassengers.adt > currentPassengers.inf) {
            currentPassengers.adt = currentPassengers.adt - 1;
            dispatch(setPassengers(currentPassengers));
        }

        if (type === 'chd' && currentPassengers.chd > 0) {
            currentPassengers.chd = currentPassengers.chd - 1;
            dispatch(setPassengers(currentPassengers));
        }

        if (type === 'ins' && currentPassengers.ins > 0) {
            currentPassengers.ins = currentPassengers.ins - 1;
            dispatch(setPassengers(currentPassengers));
        }

        if (type === 'inf' && currentPassengers.inf > 0) {
            currentPassengers.inf = currentPassengers.inf - 1;
            dispatch(setPassengers(currentPassengers));
        }
    };

    const handlePlusClick = type => {
        const currentPassengers = { ...passengers };
        const passengersSum =
            currentPassengers.adt + currentPassengers.chd + currentPassengers.ins + currentPassengers.inf;

        if (passengersSum < 9) {
            if (type === 'adt') {
                currentPassengers.adt = currentPassengers.adt + 1;
                dispatch(setPassengers(currentPassengers));
            }

            if (type === 'chd') {
                currentPassengers.chd = currentPassengers.chd + 1;
                dispatch(setPassengers(currentPassengers));
            }

            if (type === 'ins') {
                currentPassengers.ins = currentPassengers.ins + 1;
                dispatch(setPassengers(currentPassengers));
            }

            if (type === 'inf' && currentPassengers.inf < currentPassengers.adt) {
                currentPassengers.inf = currentPassengers.inf + 1;
                dispatch(setPassengers(currentPassengers));
            }
        }
    };

    const getIsDisabledMinus = type => {
        const currentPassengers = { ...passengers };

        if (type === 'adt') {
            if (currentPassengers.adt === 1 || currentPassengers.adt <= currentPassengers.inf) {
                return true;
            }
        }

        if (type === 'chd') {
            if (currentPassengers.chd === 0) {
                return true;
            }
        }

        if (type === 'ins') {
            if (currentPassengers.ins === 0) {
                return true;
            }
        }

        if (type === 'inf') {
            if (currentPassengers.inf === 0) {
                return true;
            }
        }
    };

    const getIsDisabledPlus = type => {
        const currentPassengers = { ...passengers };
        const passengersSum =
            currentPassengers.adt + currentPassengers.chd + currentPassengers.ins + currentPassengers.inf;

        if (passengersSum >= 9) {
            return true;
        }

        if (type === 'inf') {
            if (currentPassengers.inf >= currentPassengers.adt) {
                return true;
            }
        }
    };

    return (
        <Block>
            <TextPlain>{t.passengers}</TextPlain>
            <Row>
                <TextBlock>
                    <TextTitle>{t.adt_main_label}</TextTitle>
                    <TextDescription>{t.adt_second_label}</TextDescription>
                </TextBlock>
                <ButtonsBlock>
                    <Button onClick={() => handleMinusClick('adt')} disabled={getIsDisabledMinus('adt')}>
                        <MinusIcon />
                    </Button>
                    <Count>{passengers.adt}</Count>
                    <Button onClick={() => handlePlusClick('adt')} disabled={getIsDisabledPlus('adt')}>
                        <PlusIcon />
                    </Button>
                </ButtonsBlock>
            </Row>

            <Row>
                <TextBlock>
                    <TextTitle>{t.chd_main_label}</TextTitle>
                    <TextDescription>{t.chd_second_label}</TextDescription>
                </TextBlock>
                <ButtonsBlock>
                    <Button onClick={() => handleMinusClick('chd')} disabled={getIsDisabledMinus('chd')}>
                        <MinusIcon />
                    </Button>
                    <Count>{passengers.chd}</Count>
                    <Button onClick={() => handlePlusClick('chd')} disabled={getIsDisabledPlus('chd')}>
                        <PlusIcon />
                    </Button>
                </ButtonsBlock>
            </Row>

            <Row>
                <TextBlock>
                    <TextTitle>{t.inf_main_label}</TextTitle>
                    <TextDescription>{t.ins_second_label}</TextDescription>
                </TextBlock>
                <ButtonsBlock>
                    <Button onClick={() => handleMinusClick('ins')} disabled={getIsDisabledMinus('ins')}>
                        <MinusIcon />
                    </Button>
                    <Count>{passengers.ins}</Count>
                    <Button onClick={() => handlePlusClick('ins')} disabled={getIsDisabledPlus('ins')}>
                        <PlusIcon />
                    </Button>
                </ButtonsBlock>
            </Row>

            <Row>
                <TextBlock>
                    <TextTitle>{t.inf_main_label}</TextTitle>
                    <TextDescription>{t.inf_second_label}</TextDescription>
                </TextBlock>
                <ButtonsBlock>
                    <Button onClick={() => handleMinusClick('inf')} disabled={getIsDisabledMinus('inf')}>
                        <MinusIcon />
                    </Button>
                    <Count>{passengers.inf}</Count>
                    <Button onClick={() => handlePlusClick('inf')} disabled={getIsDisabledPlus('inf')}>
                        <PlusIcon />
                    </Button>
                </ButtonsBlock>
            </Row>
        </Block>
    );
};

export default PassengersBlock;
