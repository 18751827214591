import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Container, Grid, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router';

import {
    CityBlock,
    CityName,
    CityTable,
    CityTableRow,
    CityTableRowLoading,
    CityTableColumnTo,
    CityTableColumnWeekDays,
    CityTableColumnAirline,
} from './components';

import useLocalization from '../../../../../contexts/localization/hook';
import API from '../../../../../lib/api';
import { setRoutes } from '../../../../../store/searchParams/actions';

import { useStyles } from '../../styles';
import LineDivider from '../lineDivider';

const PopularDestinationTable = ({ popularDestinations }) => {
    const { airLines, airports, cities, destinations } = popularDestinations;
    const { t, language } = useLocalization();
    const { routes } = useSelector(state => state.searchParams);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [tableDestinations, setTableDestinations] = useState([]);
    const [clickLoading, setClickLoading] = useState({ departureAirport: null, arrivalAirport: null, supplier: null });

    useEffect(() => {
        parseData();
    }, []);

    const parseData = () => {
        let finalArray = [];

        destinations.forEach(destination => {
            finalArray.push({
                airport: destination.from,
                flights: destination.flights,
            });
        });

        setTableDestinations(finalArray);
    };

    const getDaysIndex = days => {
        const daysIndexes = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

        return days.map(day => {
            return daysIndexes.findIndex(dayIndex => dayIndex === day);
        });
    };

    const getDayLabelsByIndexes = days => {
        return days.map(day =>
            moment()
                .day(day + 1)
                .format('dd')
        );
    };

    const goToAllFlights = () => {
        history.push('/popularDestinations');
    };

    const handleClickFlight = async flight => {
        if (clickLoading.departureAirport) {
            return null;
        }

        setClickLoading({
            departureAirport: flight.dep_airport,
            arrivalAirport: flight.arr_airport,
            supplier: flight.air_company_code,
        });

        const departure = await API.getCities(airports[flight.dep_airport].code_iata).then(result => result[0]);
        const arrival = await API.getCities(airports[flight.arr_airport].code_iata).then(result => result[0]);

        const flightDays = getDaysIndex(JSON.parse(flight.days_of_week));

        const date = parseInt(flightDays.find(day => day + 1 > moment().isoWeekday()) + 1 || flightDays[0] + 1);
        const flightDate =
            date <= moment().isoWeekday() ? moment().add(1, 'weeks').isoWeekday(date) : moment().isoWeekday(date);

        const currentRoutes = [...routes];
        currentRoutes[0] = {
            from: departure,
            to: arrival,
            date_from: flightDate.format('YYYY-MM-DD'),
        };
        currentRoutes[1].from = arrival;
        currentRoutes[1].to = departure;

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        dispatch(setRoutes(currentRoutes));
        setClickLoading({ departureAirport: null, arrivalAirport: null, supplier: null });
    };

    const renderDestinations = () => {
        return tableDestinations.map((value, index) => {
            return (
                <Grid
                    item
                    xs={12}
                    lg={6}
                    key={`departure-airport-${value.airport}`}
                    className={index > 0 ? classes.destinationGrid : ''}
                >
                    <CityBlock>
                        <CityName>
                            {t.from_airport} {airports[value.airport][`name_${language}`]}
                        </CityName>

                        <CityTable>
                            <CityTableRow className="header">
                                <CityTableColumnTo>{t.to_city}</CityTableColumnTo>
                                <CityTableColumnWeekDays>{t.weekdays}</CityTableColumnWeekDays>
                                <CityTableColumnAirline>{t.airline}</CityTableColumnAirline>
                            </CityTableRow>

                            {value.flights.map(flight => {
                                const flightDays = getDaysIndex(JSON.parse(flight.days_of_week));

                                return (
                                    <CityTableRow
                                        key={`destination-${flight.dep_airport}-${flight.arr_airport}-${flight.air_company_code}`}
                                        onClick={() => handleClickFlight(flight)}
                                    >
                                        {flight.dep_airport === clickLoading.departureAirport &&
                                        flight.arr_airport === clickLoading.arrivalAirport &&
                                        flight.air_company_code === clickLoading.supplier ? (
                                            <CityTableRowLoading>
                                                <CircularProgress size={14} color="#fff" />
                                            </CityTableRowLoading>
                                        ) : null}

                                        <CityTableColumnTo>
                                            {cities[airports[flight.arr_airport].city_code][`name_${language}`]} (
                                            {flight.arr_airport})
                                        </CityTableColumnTo>

                                        <CityTableColumnWeekDays className={flightDays.length !== 7 ? 'uppercase' : ''}>
                                            {flightDays.length === 7
                                                ? t.daily
                                                : getDayLabelsByIndexes(flightDays).join(', ')}
                                        </CityTableColumnWeekDays>

                                        <CityTableColumnAirline>
                                            {airLines[flight.air_company_code].name}
                                        </CityTableColumnAirline>
                                    </CityTableRow>
                                );
                            })}
                        </CityTable>
                    </CityBlock>
                </Grid>
            );
        });
    };

    if (!tableDestinations.length) return null;

    return (
        <>
            <Container fixed maxWidth="lg">
                <div className={classes.destinationsContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} className={classes.destinationsTitle}>
                            {t.popular_destinations}
                        </Grid>

                        {renderDestinations()}

                        <div className={classes.buttonBlock}>
                            <button className={classes.showMoreButton} onClick={goToAllFlights}>
                                {t.all_flights}
                            </button>
                        </div>
                    </Grid>
                </div>
            </Container>

            <LineDivider />
        </>
    );
};

export default PopularDestinationTable;
