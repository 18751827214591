import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

import { setAppConfigs } from '../../store/appConfigs/actions';
import { setChildPartners, setOrdersData, setOrdersFilters, setShowActionsForOrder } from '../../store/orders/actions';
import { setShowLoading } from '../../store/loading/actions';
import OrdersPage from '../../pages/orders/index';
import EmptyOrdersForm from '../../pages/orders/emptyOrdersForm/emptyOrdersForm';
import API from '../../lib/api';
import _ from 'lodash';
import envConfigs from '../../config/envConfigs';
import useLocalization from '../../contexts/localization/hook';

function OrdersContainer(props) {
    const { isAuth } = useSelector(state => state.appConfigs);

    const {
        ordersData,
        isLoad,
        totalOrdersCount,
        setOrdersData,
        setOrdersFilters,
        filtersData,
        childPartners,
        partnerCode,
        setShowActionsForOrder,
    } = props;
    const [page, setPage] = useState(1);
    const [withoutFilters, setWithoutFilters] = useState(true);
    const dispatch = useDispatch();
    const { language, t } = useLocalization();

    useEffect(() => {
        if (isLoad) {
            dispatch(setShowLoading(false));
        } else {
            dispatch(setShowLoading(true));
        }
    }, [isLoad]);

    useEffect(() => {
        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: false,
            })
        );

        getOrdersList(page);

        return () => {
            setOrdersData({
                ordersData: [],
                loadStatus: false,
            });

            setWithoutFilters(true);
            setOrdersFilters({});
        };
    }, []);

    useEffect(() => {
        document.title = `${t.page_orders_list} | ${envConfigs.appTitle}`;
    }, [language]);

    const orderCancel = (order_id, session_id, key, setSuccess) => {
        const params = {
            order_id,
            session_id,
            key,
        };

        API.apiOrderCancel(params)
            .then(() => {
                getOrdersList(page, filtersData, partnerCode, setSuccess);

                setSuccess(true);
            })
            .catch(() => {
                setSuccess(false);
            });
    };

    const allowBalancePayment = (order_id, setSuccess) => {
        const params = {
            data: { order_id },
        };

        // API.apiBalancePaymentApproved(params).then(() => {
        //     getOrdersList(page, filtersData, partnerCode, setSuccess);

        //     setSuccess(true);
        // })
        // .catch(() => {
        //     setSuccess(false);
        // });
    };

    const orderRefund = (order_id, setSuccess) => {
        const params = {
            value: [order_id],
        };

        // API.apiRefund(params).then((response) => {
        //     const handelSetSuccess = () => {
        //         if (_.first(response)[order_id] === 'error') {
        //             setSuccess(false);
        //         }
        //         else {
        //             setSuccess(true);
        //         }
        //     }

        //     getOrdersList(page, filtersData, partnerCode, handelSetSuccess);
        // })
        // .catch(() => {
        //     setSuccess(false);
        // });
    };

    const handelSetFilers = data => {
        setOrdersFilters(data);

        getOrdersList(1, data);
    };

    const getOrdersList = (pageNumber, filters = filtersData, setSuccess = () => {}) => {
        API.apiGetOrders({
            page: pageNumber,
            ...filters,
        }).then(response => {
            setWithoutFilters(_.isEmpty(filters));

            setOrdersData({
                ordersData: response,
                loadStatus: true,
            });

            setPage(pageNumber);

            setSuccess();
        });
    };

    const handelSetShowActionButtons = (orderId, actionButtonStatus) => {
        setShowActionsForOrder({
            orderId,
            actionButtonStatus,
        });
    };

    const handelChangePage = pageNumber => {
        getOrdersList(pageNumber);
    };

    const isEmptyOrders = () => {
        return totalOrdersCount === 0 && withoutFilters;
    };

    if (!isAuth) return <Redirect to="/" />;

    if (!isLoad) return null;

    if (isEmptyOrders()) {
        return <EmptyOrdersForm />;
    }

    return (
        <OrdersPage
            ordersData={ordersData}
            totalOrdersCount={totalOrdersCount}
            changePage={handelChangePage}
            currentPage={page}
            setFilters={handelSetFilers}
            orderCancel={orderCancel}
            orderRefund={orderRefund}
            childPartners={childPartners}
            partnerCode={partnerCode}
            setShowActionButtons={handelSetShowActionButtons}
            allowBalancePayment={allowBalancePayment}
        />
    );
}

const mapStateToProps = state => ({
    totalOrdersCount: state.setOrdersData.totalOrdersCount,
    isLoad: state.setOrdersData.isLoad,
    ordersData: state.setOrdersData.ordersData,
    filtersData: state.setOrdersData.filters,
    childPartners: state.setOrdersData.childPartners,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setOrdersData,
            setOrdersFilters,
            setChildPartners,
            setShowActionsForOrder,
        },
        dispatch
    );

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrdersContainer);
