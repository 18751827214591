import React from 'react';
import { Container } from '@material-ui/core';

const LineDivider = () => {
    return (
        <Container fixed>
            <hr
                style={{
                    borderTop: '2px solid grey',
                    margin: '3.5rem 0',
                    color: '#D2D2D2',
                    opacity: 0.2,
                }}
            />
        </Container>
    );
};

export default LineDivider;
