import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 0;
    width: 100%;
`;

export const Checkbox = styled.div`
    background-color: #fff;
    width: 15px;
    height: 15px;
    position: relative;
    border: 1px solid #a0b0b9;
    border-radius: 50%;
    transition: border-color 0.26s ease;
    margin-right: 8px;

    &:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: transparent;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: background-color 0.26s ease;
    }

    &.active {
        border: 3px solid #02c5e3;
    }
`;

export const Text = styled.div`
    color: inherit;
    line-height: 1.5;
    user-select: none;
`;
