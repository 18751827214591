import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 16px;

    @media (max-width: 959px) {
        top: unset;
        bottom: 100px;
        margin-bottom: 0;
    }
`;

export const DesktopBlock = styled.div`
    display: block;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
    background-color: #fff;

    @media (max-width: 959px) {
        display: none;
    }
`;

export const MobileBlock = styled.div`
    display: none;

    @media (max-width: 959px) {
        display: block;
    }
`;
