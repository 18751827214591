import React from 'react';

import { Wrapper, DesktopBlock, MobileBlock } from './components';
import FiltersDesktop from './desktop';
import FiltersMobile from './mobile';

const Filters = props => {
    return (
        <Wrapper>
            <DesktopBlock>
                <FiltersDesktop {...props} />
            </DesktopBlock>

            <MobileBlock>
                <FiltersMobile {...props} />
            </MobileBlock>
        </Wrapper>
    );
};

export default Filters;
