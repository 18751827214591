import { Button, Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';

export const DialogContent = styled.div`
    padding: 0;
`;

export const TabsComponent = styled(Tabs)`
    & .MuiTabs-indicator {
        display: none;
    }
`;

export const TabContent = styled.div`
    padding: 8px 16px;
`;

export const BuyButton = styled(Button)`
    padding: 12px !important;
    background-color: #00ddff !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #fff !important;
    border-radius: 12px !important;

    &:hover {
        background-color: #0779e1 !important;
    }
`;

export const ActionsBlock = styled.div`
    padding: 0 16px 16px;
`;

export const TransfersBetweenAirportsWarning = styled.div`
    padding-top: 8px;
    color: #ff531d;
`;

export const TariffsTab = styled(Tab)`
    color: #404b5a;
    font-size: 14px;

    &.Mui-selected {
        color: #00ddff;
    }
`;

export const RouteBlock = styled.div`
    margin: 16px 0 32px;
`;

export const DepartureBlock = styled.div`
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
`;

export const RouteDuration = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #9eb3c7;
`;

export const SegmentBlock = styled.div`
    border-radius: 12px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    margin: 15px 0;
`;

export const SegmentHeader = styled.div`
    padding: 8px 16px;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    border: 1px solid #00ddff;
`;

export const SegmentHeaderTitle = styled.span`
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
`;

export const SegmentHeaderDuration = styled.span`
    font-size: 14px;
`;

export const SegmentBody = styled.div`
    overflow: hidden;
    position: relative;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #00ddff;
    border-right: 1px solid #00ddff;
`;

export const OperatingCompanyBlock = styled.div`
    font-size: 12px;
    color: #9eb3c7;
    padding-bottom: 3px;
`;

export const SegmentSupplierIconBlock = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const SegmentFooter = styled.div`
    padding: 8px 16px;
    border-radius: 0 0 12px 12px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #00ddff;
    border-right: 1px solid #00ddff;
    border-bottom: 1px solid #00ddff;
`;

export const SegmentFooterFlightClass = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #404b5a;
    font-weight: bold;
`;

export const SegmentFooterFlightAdditionalInfo = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1;
    align-items: flex-end;
`;

export const SegmentFooterFlightAdditionalInfoItems = styled.div`
    display: flex;
    align-items: center;
`;

export const SegmentFooterFreeSits = styled.span`
    font-size: 10px;
    padding-left: 8px;
    color: #404b5a;
`;

export const SegmentFooterFlightAdditionalInfoItem = styled.div`
    font-size: 10px;
    padding-top: 4px;
    color: #404b5a;
`;

export const PriceBlock = styled.div`
    margin: 16px 16px 32px;
`;

export const ValidatingSupplier = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
`;

export const Price = styled.div`
    font-size: 32px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PriceForTicket = styled.span`
    color: #00ddff;
    margin-right: 12px;
`;

export const LowcostBlock = styled.div`
    display: flex;
    justify-content: center;
`;

export const Lowcost = styled.div`
    display: inline-block;
    padding: 2px 15px;
    color: #fff;
    border-radius: 4px;
    background-color: #4caf50;
    text-align: center;
`;

export const Charter = styled(Lowcost)`
    margin-top: 5px;
`;

export const SegmentFlightInfoBlock = styled.div`
    margin: 16px 0 0;
    width: 100%;
`;

export const SegmentFlightDateTime = styled.div`
    padding-right: 32px;
`;

export const SegmentFlightDate = styled.div`
    font-size: 12px;
    color: #404b5a;
`;

export const SegmentFlightTime = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 26px;
    color: #404b5a;
`;

export const SegmentFlightAirport = styled.div`
    display: flex;
    align-items: center;
`;

export const SegmentFlightInfoSubTitle = styled.div`
    font-size: 12px;
    color: #9eb3c7;
    margin-right: 30px;
`;

export const SegmentFlightInfoTitle = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    color: #9eb3c7;
`;
