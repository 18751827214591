import styled from 'styled-components'
// import active from '../../../../images/checkbox/checkbox-active.png';
import active from '../../../../assets/icons/newDesign/checkboxCheckedIcon.svg';
// import inactive from '../../../../images/checkbox/checkbox-inactive.png';
import inactive from '../../../../assets/icons/newDesign/checkboxBackground.svg';

export const CheckboxItem = styled.button`
    height: 24px;
    width: 24px;
    background-color: ${props => props.backgroundColor || '#f2f4f5'};
    background-image: url(${props => props.isActive ? active : inactive});
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center center;
    border: none;
    cursor: pointer;
    
    :focus { 
        outline: none; 
    }
`;
