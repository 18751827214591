import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    searchFieldBlock: {
        borderRadius: '8px',
        boxShadow: '0 4px 40px 0 rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff',
        order: 2,
        position: 'sticky',
        top: '116px',

        '@media (max-width:960px)': {
            order: 1,
        },
    },
    searchFields: {
        padding: '16px',
    },
    searchSingleItem: {
        width: '100%',
    },
    itemBlock: {
        minHeight: '68px',
    },
    orderStatusSearchBlock: {
        minHeight: '76px',
    },
    test1: {
        width: '100%',
    },
    searchDoubleItem: {
        width: '50%',
    },
    dateFromBlock: {
        minHeight: '76px',
    },
    searchFieldInput: {
        height: '48px',
    },
    searchIcon: {
        paddingLeft: '8px',
        color: '#b9c1c5',
    },
    dateToBlock: {
        minHeight: '76px',
    },
});
