import React, { useRef, useEffect, useCallback } from 'react';
import { Wrapper } from './components';

const Dropdown = ({
    children,
    isActive,
    onClickOutside,
    position = 'right',
    topMargin = '10px',
    width = '200px',
    boxShadow = '0 0 5px 1px rgba(0, 59, 110, 0.1)',
    padding = '8px'
}) => {
    const node = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleClick = useCallback(e => {
        if (node.current.contains(e.target)) {
            return;
        }

        onClickOutside();
    }, []);

    return (
        <Wrapper
            ref={node}
            active={isActive}
            position={position}
            topMargin={topMargin}
            width={width}
            boxShadow={boxShadow}
            padding={padding}
        >
            {children}
        </Wrapper>
    );
};

export default Dropdown;
