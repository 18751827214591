import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    selectedPage: {
        width: '36px',
        height: '36px',
        borderRadius: '4px',
        backgroundColor: '#ff7043',
        display: 'inline-table',
        marginRight: '8px',
        fontSize: '20px',
        color: '#ffffff',
        textAlign: 'center',
    },
    notSelectedPage: {
        width: '36px',
        height: '36px',
        display: 'inline-table',
        marginRight: '8px',
        fontSize: '20px',
        textAlign: 'center',
    },
    pageItem: {
        display: 'inline-block',
        paddingTop: '4px',
    },
});
