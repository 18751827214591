import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(props => {
    const { theme } = props;

    return {
        contentContainer: {
            order: 1,

            '@media (max-width: 960px)': {
                order: 2,
            },
        },
        sidebarContainer: {
            order: 2,

            '@media (max-width: 960px)': {
                order: 1,
            },
        },
        routesTitle: {
            padding: '24px 0 10px 0',
            fontSize: '24px',
            fontWeight: '600',
            color: '#404B5A',
        },
        upButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '16px',
            paddingBottom: '64px',
        },
        voidButtonBlock: {
            textAlign: 'center',
        },
        brokenOrderContainer: {
            paddingBottom: '64px',
        },
        bookDataBlock: {
            backgroundColor: '#ffffff',
            borderRadius: '22px',
            padding: '16px',
            boxShadow: '0 10px 10px 1px rgba(0, 59, 110, 0.1)',
        },
        bookLabel: {
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#404B5A',
            lineHeight: 1.2,
        },
        lineBlock: {
            padding: '16px 0',
        },
        line: {
            height: '2px',
            backgroundColor: '#f2f4f5',
        },
        bookDataTitle: {
            fontSize: '16px',
            color: '#404B5A',
            display: 'flex',
            flexWrap: 'wrap',

            '& div:nth-child(1)': {
                marginRight: '20px',
            },
        },
        routeInformation: {
            display: 'flex',
            alignItems: 'center',
            color: '#ff7043',
            cursor: 'pointer',
            fontSize: '16px',
        },
        routeInformationText: {
            position: 'relative',
            paddingLeft: '8px',
        },
        partnerFeesBlock: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        partnerFeesItem: {
            fontSize: '14px',
            color: '#212121',
        },
        locatorItem: {
            fontSize: '16px',
            color: '#404B5A',
        },
        passengerBlock: {
            paddingBottom: '16px',
        },
        passengersNamesBlock: {
            display: 'flex',
            paddingBottom: '8px',
        },
        passengerName: {
            color: '#212121',
            fontSize: '18px',
            paddingBottom: '4px',
        },
        ticketsBlock: {
            display: 'flex',
            lineHeight: 1,
            alignItems: 'center',
            marginLeft: '16px',
            cursor: 'pointer',
            paddingBottom: '4px',
        },
        ticketsIcon: {
            display: 'flex',
        },
        ticketsText: {
            fontWeight: '500',
        },
        passengerParam: {
            fontSize: '14px',
            color: '#212121',
        },
        passengerParamTitle: {
            fontSize: '14px',
            color: '#7e878b',
        },
        orderNumberContainer: {
            fontSize: '34px',
            fontWeight: '500',
            color: '#212121',
        },
        statusBlock: {
            fontSize: '16px',
            display: 'inline-block',
        },
        statusContainer: {
            position: 'relative',
            paddingTop: '8px',
            paddingBottom: '12px',
            paddingLeft: '12px',
            lineHeight: 1,
        },
        circle: {
            position: 'absolute',
            top: '12px',
            left: 0,
            width: '8px',
            height: '8px',
            borderRadius: '8px',
        },
        createdAtBlock: {
            color: '#7e878b',
            fontSize: '16px',
            paddingLeft: '8px',
            display: 'inline-block',
        },
        voidBlock: {
            fontSize: '14px',
            padding: '8px 0',
            textAlign: 'center',
        },
        voidText: {
            color: '#404B5A',
            textAlign: 'center',
        },
        paymentRoot: {
            paddingBottom: '24px',
        },
        paymentContainer: {
            borderRadius: '22px',
            backgroundColor: '#fff',
            boxShadow: '0 10px 10px 1px rgba(0, 59, 110, 0.1)',
        },
        paymentBlock: {
            padding: '16px',
        },
        paymentTitle: {
            width: '70%',
            fontSize: '24px',
            fontWeight: '500',
            color: '#404B5A',
        },
        infoAccountBlock: {
            color: '#ff7043',
            fontWeight: '500',
            fontSize: '16px',
            width: '30%',
            textAlign: 'right',
            cursor: 'pointer',
        },
        paymentMethodItem: {
            width: '100%',
            color: '#212121',
            fontSize: '18px',
            paddingTop: '16px',
        },
        radioButtonBlock: {
            color: '#00ddff',
            cursor: 'pointer',
            lineHeight: 1,
        },
        methodTitle: {
            position: 'relative',
            paddingLeft: '8px',
        },
        paymentMethodLogo: {
            display: 'block',
        },
        notEnoughBalanceText: {
            fontSize: '14px',
            color: 'red',
        },
        cardPaymentContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        methodBlock: {
            display: 'flex',
            alignItems: 'center',
        },
        paymentTimelimitBlock: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',

            '@media (max-width: 600px)': {
                justifyContent: 'center',
            },
        },
        paymentButtonBlock: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            marginLeft: '16px',

            '@media (max-width: 1279px)': {
                justifyContent: 'center',
            },
        },
        helpContainer: {
            paddingTop: '8px',
            paddingBottom: '16px',
        },
        ticketsOptionsButtons: {
            paddingBottom: '16px',
        },
        priceIcon: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '8px',
            color: '#cfd8dc',
            cursor: 'pointer',
            lineHeight: 1,
        },
        totalPriceText: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: 1.2,
            color: '#404B5A',

            '@media (max-width: 960px)': {
                justifyContent: 'flex-end',
            },
        },
        invoiceLinkContainer: {
            color: '#ff7043',
            cursor: 'pointer',
            fontSize: '16px',
            paddingTop: '8px',
        },
        voidInProcessBlock: {
            padding: '24px 0 24px 32px',
            color: '#7e878b',
            fontSize: '16px',
        },
        loadingSniperBlock: {
            color: '#ff7043',
            paddingRight: '8px',
            position: 'relative',
            top: '2px',
        },
        cardForm: {
            paddingTop: '16px',
        },
        cardBlock: {
            paddingTop: '16px',
        },
        priceAndPaymentblock: {
            borderRadius: '22px',
            backgroundColor: '#fff',
            boxShadow: '0 10px 10px 1px rgba(0, 59, 110, 0.1)',
        },
        voidbuttonBlock: {
            marginTop: '20px',
        },
        copyLinkBlock: {
            marginTop: '20px',
            textAlign: 'center',
        },
    };
});
