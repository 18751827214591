import { SET_ORDER_DATA, SET_PAYMENT_METHOD, SET_AVAILABLE_PAYMENT_METHODS } from './constants';

export const setOrderData = data => dispatch =>
    dispatch({
        type: SET_ORDER_DATA,
        payload: data,
    });

export const setPaymentMethod = data => dispatch =>
    dispatch({
        type: SET_PAYMENT_METHOD,
        payload: data,
    });

export const setAvailablePaymentMethods = data => dispatch =>
    dispatch({
        type: SET_AVAILABLE_PAYMENT_METHODS,
        payload: data,
    });
