import { FlightTypes } from './constants';

const initialState = {
    isLoading: true,
    selectedType: FlightTypes.Arrival,
    airports: [
        {
            name: {
                ru: 'Москва',
                en: 'Moscow',
                tj: 'Маскав',
            },
            timezone: 'Europe/Moscow',
            iata: 'DME',
            icao: 'UUDD',
        },
        {
            name: {
                ru: 'Душанбе',
                en: 'Dushanbe',
                tj: 'Душанбе',
            },
            timezone: 'Asia/Dushanbe',
            iata: 'DYU',
            icao: 'UTDD',
        },
        {
            name: {
                ru: 'Лондон',
                en: 'London',
                tj: 'Лондон',
            },
            timezone: 'Europe/London',
            iata: 'LHR',
            icao: 'EGLL',
        },
    ],
    selectedAirport: {
        name: {
            ru: 'Москва',
            en: 'Moscow',
            tj: 'Маскав',
        },
        timezone: 'Europe/Moscow',
        iata: 'DME',
        icao: 'UUDD',
    },
    arrivals: [],
    departures: [],
};

export default initialState;
