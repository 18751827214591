import moment from 'moment';

export const getWeeks = (month, selectedDates, lastSelectedOrHoveredDate, type) => {
    let weeks = [];
    const startDate = moment();
    const lastDate = moment().add(9, 'M');
    const monthLastDate = month.startDate.clone().endOf('month');
    const firstWeekDay = month.startDate.weekday();
    const lastDay = monthLastDate.date();
    const lastWeekDay = monthLastDate.weekday();
    const weeksCount = Math.ceil((lastDay + firstWeekDay) / 7);

    let dateIndex = 1;
    for (let i = 0; i < weeksCount; i++) {
        const currentWeek = [];
        for (let j = 0; j < 7; j++) {
            if ((i === 0 && j < firstWeekDay) || (i === weeksCount - 1 && j > lastWeekDay)) {
                currentWeek.push(null);
            } else {
                let date = month.startDate.clone().date(dateIndex);
                if (type === 'oneSelect') {
                    currentWeek.push({
                        date,
                        disabled:
                            (!moment(date).isSame(startDate, 'day') && moment(date).isBefore(startDate)) ||
                            moment(date).isAfter(lastDate) ||
                            moment(date).isBefore(lastSelectedOrHoveredDate),
                        active: selectedDates && moment(date).isSame(selectedDates),
                    });
                } else {
                    currentWeek.push({
                        date,
                        disabled:
                            (!moment(date).isSame(startDate, 'day') && moment(date).isBefore(startDate)) ||
                            moment(date).isAfter(lastDate),
                        active:
                            (selectedDates[0] && moment(date).isSame(selectedDates[0])) ||
                            (selectedDates[1] && moment(date).isSame(selectedDates[1])),
                        between:
                            (moment(date).isAfter(selectedDates[0]) && moment(date).isBefore(selectedDates[1])) ||
                            (moment(date).isAfter(selectedDates[0]) &&
                                moment(date).isBefore(lastSelectedOrHoveredDate)),
                    });
                }
                dateIndex++;
            }
        }
        weeks.push(currentWeek);
    }

    return weeks;
};

export const getMonths = dateNow => {
    let months = [];

    for (let i = 0; i < 10; i++) {
        months.push({
            startDate: dateNow.clone().add(i, 'months'),
        });
    }

    return months;
};
