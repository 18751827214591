import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { useStyles } from '../../containers/booking/styles';
import PassengersAmountBlock from './passengersAmountBlock';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { normalizePrice } from '../../lib/normalize';
import useLocalization from '../../contexts/localization/hook';

export default function PriceForm(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const { totalPrice, forOrder, currency, passengers, partnerFees, clientFees } = props;
    const flightTotal = forOrder ? totalPrice : totalPrice[currency];
    const [finalTotalPrice, setFinalTotalPrice] = useState(_.toFinite(flightTotal));
    const [showFees, setShowFees] = useState(false);
    const [showingPartnerFees, setShowingPartnerFees] = useState(null);

    useEffect(() => {
        const value = (_.toFinite(flightTotal) + _.toFinite(partnerFees)).toFixed(2);
        setFinalTotalPrice(value);
        if (partnerFees) {
            if (!_.isNull(partnerFees[currency]) && !_.isUndefined(partnerFees[currency])) {
                setShowingPartnerFees(partnerFees[currency].toFixed(2));
            } else {
                setShowingPartnerFees(partnerFees);
            }
        } else {
            setShowingPartnerFees('0.00');
        }
    }, [partnerFees, currency]);

    const renderFeesBlock = () => {
        return (
            <Grid item xs={12} className={classes.priceFeesBlock}>
                <div className={classes.feesBlockButton} onClick={() => setShowFees(!showFees)}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item className={classes.feesBlockTitle}>
                            {t.fees}
                        </Grid>
                        <Grid item>
                            <ChevronRightIcon className={showFees ? classes.chevronUp : classes.chevronDown} />
                        </Grid>
                    </Grid>
                </div>
                <div className={showFees ? classes.showFeesBlock : classes.hideFeesBlock}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item md={12} lg={'auto'} className={classes.partnerFeesTitleBook}>
                            - {t.your_fees}
                        </Grid>
                        <Grid item md={12} lg={'auto'} className={classes.partnerFeesValueBook}>
                            {normalizePrice(showingPartnerFees)} {currency}
                        </Grid>
                    </Grid>
                    {clientFees && (
                        <Grid container justifyContent={'space-between'}>
                            <Grid item md={12} lg={'auto'} className={classes.partnerFeesTitleBook}>
                                - {t.your_comission}
                            </Grid>
                            <Grid item md={12} lg={'auto'} className={classes.partnerFeesValueBook}>
                                {normalizePrice(clientFees[currency].toFixed(2))} {currency}
                            </Grid>
                        </Grid>
                    )}
                </div>
            </Grid>
        );
    };

    if (forOrder) {
        return (
            <div className={classes.priceContainer}>
                <Grid container className={classes.priceBlock}>
                    <Grid item xs={12}>
                        <Grid container className={classes.flightTicketRow}>
                            <Grid item lg={'auto'} className={classes.flightTicket}>
                                {t.flights_tickets}:
                            </Grid>
                            <Grid item lg={'auto'} className={classes.flightTicketAmount}>
                                {normalizePrice(flightTotal)} {currency}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.amountPassengersBlock}>
                        <PassengersAmountBlock passengers={passengers} currency={currency} />
                    </Grid>

                    <Grid item xs={12} className={classes.priceTotalBlock}>
                        <Grid container className={classes.flightTicketRow}>
                            <Grid item md={12} lg={'auto'} className={classes.totalTitle}>
                                {t.total}
                            </Grid>
                            <Grid item md={12} lg={'auto'} className={classes.totalPrice}>
                                <span className={classes.totalPriceText}>
                                    {normalizePrice(totalPrice)} {currency}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <div className={classes.priceContainer}>
            <Grid container className={classes.priceBlock}>
                <Grid item xs={12}>
                    <Grid container className={classes.flightTicketRow}>
                        <Grid item md={12} lg={'auto'} className={classes.flightTicket}>
                            {t.flights_tickets}:
                        </Grid>
                        <Grid item md={12} lg={'auto'} className={classes.flightTicketAmount}>
                            {normalizePrice(flightTotal)} {currency}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.priceTotalBlock}>
                    <Grid container className={classes.flightTicketRow}>
                        <Grid item md={12} lg={'auto'} className={classes.totalTitle}>
                            {t.total}:
                        </Grid>
                        <Grid item md={12} lg={'auto'} className={classes.totalPrice}>
                            {normalizePrice(finalTotalPrice)} {currency}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
