import mainConfigs from './mainConfigs';
import envConfigs from './envConfigs';
import { authRoutes, nonAuthRoutes } from './routes';
import defaultCities from './defaultCities';
import defaultCountries from './defaultCounties';

export default {
    mainConfigs,
    envConfigs,
    authRoutes,
    nonAuthRoutes,
    defaultCities,
    defaultCountries,
};
