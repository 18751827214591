import styled from 'styled-components';
import { Accordion, Paper } from '@material-ui/core';

export const RulesBlockAccordion = styled(Accordion)`
    color: #00ddff;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 16px;
`;

export const SelectBlock = styled.div`
    margin-bottom: 16px;
`;

export const StyledPaper = styled(Paper)`
    padding: 0 16px;
    max-height: 600px;
    overflow: auto;
    box-shadow: none;
`;
