import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    ordersMainContainer: {
        position: 'relative',
    },
    ordersTitle: {
        color: '#212121',
        fontSize: '34px',
        fontWeight: '500',
        paddingTop: '16px',
    },
    emptyOrdersContainer: {
        height: '270px',
    },
    emptyOrdersBlock: {
        paddingTop: '100px',
        textAlign: 'center',
    },
    emptyOrdersTitle: {
        color: '#7e878b',
        fontWeight: '500',
        fontSize: '24px',
    },
    notFoundButtonBlock: {
        height: '40px',
        textAlign: 'center',
        paddingTop: '18px',
    },
    toMainButton: {
        width: '125px',
        height: '40px',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: '#fbb600',
        fontSize: '16px',
        color: '#ffffff',
        cursor: 'pointer',
    },
});
