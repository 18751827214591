import React from 'react';
import Slider from 'react-slick';
import { NavLink } from 'react-router-dom';

import useLocalization from '../../../../../contexts/localization/hook';
import config from '../../../../../config';

import { CarouselBlock, Header, Icon, SliderImage, SliderItem } from './components';
import partnerIcon from '../../../../../assets/icons/partners.svg';

const OurPartners = ({ partners }) => {
    const { t } = useLocalization();
    const { envConfigs } = config;

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: partners.length > 6,
        pauseOnHover: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        draggable: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 4,
                    infinite: partners.length > 4,
                },
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 3,
                    infinite: partners.length > 3,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 2,
                    infinite: partners.length > 2,
                },
            },
        ],
    };

    if (partners.length === 0) return null;

    return (
        <CarouselBlock fixed maxWidth="lg">
            <Header>
                <Icon src={partnerIcon} />
                {t.our_partners}
            </Header>

            <div>
                <Slider {...settings}>
                    {partners.map((item, index) => {
                        return (
                            <SliderItem key={index}>
                                <NavLink to={`/ourPartners/${item.slug}`}>
                                    <SliderImage
                                        loading="lazy"
                                        src={item.logo ? `${envConfigs.uploadHost}${item.logo}` : null}
                                    />
                                </NavLink>
                            </SliderItem>
                        );
                    })}
                </Slider>
            </div>
        </CarouselBlock>
    );
};

export default OurPartners;
