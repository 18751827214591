import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
`;

export const Block = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    width: 100%;
`;

export const IconBlock = styled.div`
    animation: ${rotate} 1.6s linear infinite;
`;
