import React, { useCallback, useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { FormGroup, FormSubmitButton } from '../../../components/design_v2/authPages/components';
import TextInput from '../../../components/design_v2/ui/textInput';

import API from '../../../lib/api';
import { setRegistrationData } from '../../../store/registration/actions';
import useLocalization from '../../../contexts/localization/hook';
import MaskedTextInput from '../../../components/design_v2/ui/maskedTextInput';
import config from '../../../config';

const RegistrationForm = () => {
    const dispatch = useDispatch();
    const { t } = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        login: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
        phone: Yup.string()
            .required(t.required)
            .test('validate-phone', t.invalid_phone, value => {
                const trimmedValue = value?.replace(/[^0-9\.]+/g, '');
                return trimmedValue?.length > 10;
            }),
        first_name: Yup.string().trim().min(2, t.min_2_symbol).required(t.required),
        last_name: Yup.string().trim().min(2, t.min_2_symbol).required(t.required),
        father_name: Yup.string().trim().min(2, t.min_2_symbol),
    });

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);
        getMyLocation();

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    const handleKeyPress = useCallback(e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleSubmit();
        }
    }, []);

    const handleFormSubmit = useCallback(
        values => {
            if (isLoading) {
                return;
            }

            setIsLoading(true);

            API.register(values)
                .then(response => {
                    dispatch(
                        setRegistrationData({
                            activeForm: 'activation',
                            clientId: response.data.id,
                        })
                    );
                })
                .catch(error => {
                    setFieldError('login', error.response.data.message);
                    setIsLoading(false);
                });
        },
        [isLoading]
    );

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldError } = useFormik(
        {
            initialValues: {
                login: '',
                phone: '',
                first_name: '',
                last_name: '',
                father_name: '',
            },
            validationSchema,
            onSubmit: handleFormSubmit,
            validateOnBlur: true,
        }
    );

    const getMyLocation = async () => {
        const myGeo = await API.getMyLocation();

        setFieldValue('phone', myGeo?.country_phone);
    };

    const handlePhoneChange = value => {
        const trimmedValue = value.replace(/[^0-9\.]+/g, '');

        setFieldValue('phone', trimmedValue);
    };

    return (
        <>
            <FormGroup error={errors.login}>
                <TextInput
                    value={values.login}
                    onChangeText={handleChange('login')}
                    type="email"
                    required
                    placeholder={t.enter_email}
                    label={t.email}
                    error={errors.login}
                    onBlur={handleBlur('login')}
                    touched={touched.login}
                />
            </FormGroup>

            <FormGroup error={errors.phone}>
                <MaskedTextInput
                    value={values.phone}
                    onChangeText={handlePhoneChange}
                    type="text"
                    required
                    placeholder={t.phone_number}
                    label={t.phone}
                    error={errors.phone}
                    onBlur={handleBlur('phone')}
                    touched={touched.phone}
                    mask={config.envConfigs.phoneMask}
                />
            </FormGroup>

            <FormGroup error={errors.first_name}>
                <TextInput
                    value={values.first_name}
                    onChangeText={handleChange('first_name')}
                    type="text"
                    required
                    placeholder={t.enter_name}
                    label={t.fist_name}
                    error={errors.first_name}
                    onBlur={handleBlur('first_name')}
                    touched={touched.first_name}
                />
            </FormGroup>

            <FormGroup error={errors.last_name}>
                <TextInput
                    value={values.last_name}
                    onChangeText={handleChange('last_name')}
                    type="text"
                    required
                    placeholder={t.enter_surname}
                    label={t.last_name}
                    error={errors.last_name}
                    onBlur={handleBlur('last_name')}
                    touched={touched.last_name}
                />
            </FormGroup>

            <FormGroup error={errors.father_name}>
                <TextInput
                    value={values.father_name}
                    onChangeText={handleChange('father_name')}
                    type="text"
                    placeholder={t.enter_fathname}
                    label={t.father_name}
                    error={errors.father_name}
                    onBlur={handleBlur('father_name')}
                    touched={touched.father_name}
                />
            </FormGroup>
            <FormSubmitButton onClick={handleSubmit}>
                {isLoading ? <CircularProgress color="inherit" size={26} /> : `${t.continue}`}
            </FormSubmitButton>
        </>
    );
};

export default RegistrationForm;
