import React from 'react';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export default function RadioButton(props) {
    const { checked, onChange } = props;

    const handelChange = () => {
        onChange(!checked);
    };

    if (checked) {
        return <RadioButtonCheckedIcon onClick={handelChange} />;
    }

    return <RadioButtonUncheckedIcon onClick={handelChange} />;
}

RadioButton.defaultProps = {
    checked: false,
    onChange: () => {},
};
