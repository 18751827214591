import {
    SET_RECOMMENDATIONS,
    SET_FILTERS,
    SET_BRAND_FARE_REC_ID,
    SET_ADDITIONALLY_OPTINS,
    SET_BRAND_FARES,
    SET_BRAND_RULES,
    RESET_ALL_FILTERS,
    SET_SEARCH_DATA,
    SET_ALL_FILTERS,
} from './constants';

export const setRecommendations = data => dispatch =>
    dispatch({
        type: SET_RECOMMENDATIONS,
        payload: data,
    });

export const setBrandFares = data => dispatch =>
    dispatch({
        type: SET_BRAND_FARES,
        payload: data,
    });

export const setBrandRules = data => dispatch =>
    dispatch({
        type: SET_BRAND_RULES,
        payload: data,
    });

export const resetAllFilters = data => dispatch =>
    dispatch({
        type: RESET_ALL_FILTERS,
        payload: data,
    });

export const setBrandFareRecId = data => dispatch =>
    dispatch({
        type: SET_BRAND_FARE_REC_ID,
        payload: data,
    });

export const setFilters = data => dispatch =>
    dispatch({
        type: SET_FILTERS,
        payload: data,
    });

export const setAllFilters = data => dispatch =>
    dispatch({
        type: SET_ALL_FILTERS,
        payload: data,
    });

export const setAdditionallyOptions = data => dispatch =>
    dispatch({
        type: SET_ADDITIONALLY_OPTINS,
        payload: data,
    });

export const setSearchData = data => dispatch =>
    dispatch({
        type: SET_SEARCH_DATA,
        payload: data,
    });
