import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import loader from '../../../../images/loader.gif';

export const StyledCircularProgress = styled(CircularProgress)`
    position: relative;
`;

export const LoadingContainer = styled.div`
    border-top: 1px solid #cfd8dc;
`;

export const LoaderBlock = styled.div`
    padding: 16px 0 16px 0;
    width: 40px;
    height: 40px;
    background-image: url(${loader});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px;
    margin: auto;
`;
