import React from 'react';
import { Warning, Success, Info, Error, Title, Icons, Body } from './components';
import { CheckCircleOutlineOutlined, WarningOutlined, InfoOutlined, ErrorOutline } from '@material-ui/icons';

const Alert = props => {
    const { type, title, children } = props;

    switch (type) {
        case 'warning':
            return (
                <Warning>
                    <Icons>
                        <WarningOutlined />
                    </Icons>
                    <Body>
                        <Title>{title}</Title>
                        {children}
                    </Body>
                </Warning>
            );
        case 'success':
            return (
                <Success>
                    <Icons>
                        <CheckCircleOutlineOutlined />
                    </Icons>
                    <Body>
                        <Title>{title}</Title>
                        {children}
                    </Body>
                </Success>
            );
        case 'info':
            return (
                <Info>
                    <Icons>
                        <InfoOutlined />
                    </Icons>
                    <Body>
                        <Title>{title}</Title>
                        {children}
                    </Body>
                </Info>
            );
        case 'error':
            return (
                <Error>
                    <Icons>
                        <ErrorOutline />
                    </Icons>
                    <Body>
                        <Title>{title}</Title>
                        {children}
                    </Body>
                </Error>
            );
    }
};

export default Alert;
