import React from 'react';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorsFieldBlock } from '../../../../styles/common/index';
import InputMessage from '../InputMessage/InputMessage';
import useLocalization from '../../../../contexts/localization/hook';
import config from '../../../../config/envConfigs';
import { divide } from 'lodash';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={config.phoneMask}
            placeholderChar={'\u2000'}
        />
    );
}

export default function PhoneField(props) {
    const {
        label,
        fieldError,
        width,
        height,
        meta: { touched, error },
    } = props;

    const useStyles = makeStyles({
        container: {
            flexWrap: 'wrap',
        },
        formControl: {
            position: 'relative',
            display: 'flex',
            height: '100%',
        },
        input: {
            width: width ? width : '256px',
            height: height ? height : '55px',
            borderRadius: '12px',
            border: 'solid 1px #C3D6E8',
            paddingLeft: '16px',
            paddingTop: '26px',
            paddingBottom: '1px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
        },
        inputFocused: {
            outline: 'none',
            border: 'solid 1px #00ddff',
        },
        label: {
            fontSize: '12px',
            color: '#9da8ae',
            position: 'absolute',
            top: '8px',
            left: '16px',
            zIndex: 1,
        },
    });

    const classes = useStyles();
    const { t } = useLocalization();

    let errorStyle = {};

    if (fieldError || (touched && error)) {
        errorStyle = {
            border: 'solid 2px #c62828',
        };
    }

    return (
        <FormControl className={classes.formControl}>
            <div className={classes.label}>
                <span>{label}</span>
            </div>

            <Input
                {...props.input}
                className={classes.input}
                disableUnderline={true}
                inputComponent={TextMaskCustom}
                autoComplete="off"
                classes={{ focused: classes.inputFocused }}
                style={errorStyle}
            />

            <ErrorsFieldBlock>
                {touched && error && <InputMessage text={t[error]} />}
                {fieldError && <InputMessage text={fieldError} />}
            </ErrorsFieldBlock>
        </FormControl>
    );
}

PhoneField.defaultProps = {
    label: null,
};
