import { combineReducers } from 'redux';
import { setPreBookData } from '../containers/booking/reducers';
import { setOrderData } from '../containers/order/reducers';
import { setOrdersData } from './orders/reducers';
import appConfigs from './appConfigs/reducers';
import registration from './registration/reducers';
import clientData from './clientData/reducers';
import searchData from './searchData/reducers';
import resultData from './resultData/reducers';
import searchParams from './searchParams/reducers';
import airportDepartureAndArrival from './airportDepartureAndArrival/reducers';
import loading from './loading/reducers';
import transactions from './transactions/reducers';
import filters from './filters/reducers';
import { reducer as form } from 'redux-form';

export default combineReducers({
    form,
    setPreBookData,
    setOrderData,
    setOrdersData,
    appConfigs,
    registration,
    clientData,
    searchData,
    resultData,
    searchParams,
    airportDepartureAndArrival,
    loading,
    transactions,
    filters,
});
