import React from 'react';

import { Wrapper } from './components';
import SettingsMenu from './parts/settings';
import MainMenu from './parts/menu';

const MobileNav = () => {
    return (
        <Wrapper>
            <SettingsMenu />
            <MainMenu />
        </Wrapper>
    );
};

export default MobileNav;
