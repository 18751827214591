import React from 'react';
import { setRoutes, setHasData, setPassengers, setCabin } from '../../../../../../store/searchParams/actions';
import { useDispatch, useSelector } from 'react-redux';

import { ClearRoutesButtonContainer, ClearRoutesButton, CloseIconImg } from './components';
import useLocalization from '../../../../../../contexts/localization/hook';

const ClearRouteButton = props => {
    const { routes } = useSelector(state => state.searchParams);
    const dispatch = useDispatch();
    const { t } = useLocalization();

    const clearRoutes = () => {
        let newRoutes = routes.map(route => {
            return {
                from: null,
                to: null,
                date_from: null,
            };
        });

        dispatch(setRoutes(newRoutes.slice(0, 2)));
        dispatch(setPassengers({ adt: 1, chd: 0, inf: 0, ins: 0 }));
        dispatch(setCabin('all'));
        dispatch(setHasData(false));
    };

    return (
        <ClearRoutesButtonContainer>
            <ClearRoutesButton disabled={!props.disabled} onClick={clearRoutes}>
                <CloseIconImg />
                {t.clear}
            </ClearRoutesButton>
        </ClearRoutesButtonContainer>
    );
};

export default ClearRouteButton;
