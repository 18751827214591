import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router-dom';
import { useStyles } from './styles';
import Button from '../../../components/ui/components/Button/Button';
import useLocalization from '../../../contexts/localization/hook';

export default function EmptyOrdersForm(props) {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(false);
    const { t } = useLocalization();

    return (
        <Container className={classes.ordersMainContainer}>
            <RenderRedirect redirect={redirect} />
            <div className={classes.ordersTitle}>{t.my_orders}</div>
            <Container className={classes.emptyOrdersContainer}>
                <div className={classes.emptyOrdersBlock}>
                    <div className={classes.emptyOrdersTitle}>{t.not_book_tickets}</div>
                </div>
                <div className={classes.notFoundButtonBlock}>
                    <Button
                        className={classes.toMainButton}
                        type="submit"
                        onClick={() => {
                            setRedirect(true);
                        }}
                        text={t.to_main}
                    />
                </div>
            </Container>
        </Container>
    );
}

const RenderRedirect = data => {
    if (data.redirect) {
        return <Redirect to="/" />;
    }

    return null;
};
