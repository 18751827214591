import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Container } from '@material-ui/core';
import { useStyles } from './styles';
import configs from '../../../config';
import SearchFieldsForm from '../searchFieldsForm/searchFieldsForm';
import SnackbarAlert from '../../../components/ui/components/SnackbarAlert/SnackbarAlert';
import EmptyOrdersBox from '../parts/emptyOrderBox/index';
import PaginationBlock from '../parts/PaginationBlock/index';
import OrdersContainer from '../parts/ordersContainer/index';
import useLocalization from '../../../contexts/localization/hook';

export default function OrdersForm(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const {
        ordersData,
        totalOrdersCount,
        changePage,
        setFilters,
        allowBalancePayment,
        currentPage,
        orderCancel,
        childPartners,
        orderRefund,
        partnerCode,
        setShowActionButtons,
    } = props;
    const [orderDate, setOrderDate] = useState({});
    const [redirect, setRedirect] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        severity: null,
        message: null,
    });

    const handelSetOpenAlert = () => {
        setOpenAlert(true);
    };

    if (redirect) {
        const { orderId } = orderDate;

        return (
            <Redirect
                push
                to={{
                    pathname: `/order/${orderId}`,
                }}
            />
        );
    }

    return (
        <Container fixed className={classes.ordersMainContainer}>
            <Grid className={classes.ordersTitle}>{t.orders}</Grid>
            <Grid container direction={'row'} spacing={2}>
                <Grid item xs={12} md={8} className={classes.ordersItemsContainer}>
                    {totalOrdersCount > 0 ? (
                        ordersData.map((item, index) => (
                            <OrdersContainer
                                item={item}
                                key={index}
                                setOrderDate={setOrderDate}
                                setRedirect={setRedirect}
                                orderCancel={orderCancel}
                                orderRefund={orderRefund}
                                setAlert={setAlertData}
                                partnerCode={partnerCode}
                                setOpenAlert={handelSetOpenAlert}
                                setShowActionButtons={setShowActionButtons}
                                allowBalancePayment={allowBalancePayment}
                            />
                        ))
                    ) : (
                        <EmptyOrdersBox />
                    )}
                </Grid>
                <Grid item xs={12} md={4} className={classes.searchFieldsContainer}>
                    <SearchFieldsForm setFilters={setFilters} partnersList={childPartners} />
                </Grid>
                <Grid item xs={12} md={8} className={classes.paginationContainer}>
                    <PaginationBlock
                        changePage={changePage}
                        currentPage={currentPage}
                        countOrdersLimit={configs.mainConfigs.countOrdersLimit}
                        totalRecords={totalOrdersCount}
                    />
                </Grid>
                <SnackbarAlert
                    severity={alertData.severity}
                    message={alertData.message}
                    isOpen={openAlert}
                    setIsOpen={setOpenAlert}
                />
            </Grid>
        </Container>
    );
}
