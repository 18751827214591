import React from 'react';
import { Container } from '@material-ui/core';
import OrdersForm from './ordersForm/ordersForm';

const OrdersPage = props => {
    const {
        ordersData,
        totalOrdersCount,
        handelChangePage,
        page,
        setFilters,
        orderCancel,
        orderRefund,
        childPartners,
        partnerCode,
        allowBalancePayment,
        setShowActionButtons,
    } = props;

    return (
        <Container fixed>
            <OrdersForm
                ordersData={ordersData}
                totalOrdersCount={totalOrdersCount}
                changePage={handelChangePage}
                currentPage={page}
                setFilters={setFilters}
                orderCancel={orderCancel}
                orderRefund={orderRefund}
                childPartners={childPartners}
                partnerCode={partnerCode}
                setShowActionButtons={setShowActionButtons}
                allowBalancePayment={allowBalancePayment}
            />
        </Container>
    );
};

export default OrdersPage;
