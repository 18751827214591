import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    newsCards: {
        padding: '16px',

        '@media(max-width: 1279px)': {
            padding: '24px',
        },
        '@media(max-width: 959px)': {
            padding: 0,
        },
    },
    newsBlock: {
        display: 'flex',
    },
    openMore: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 15px',
    },
    paginationBlock: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
        marginBottom: '32px',
    },
});
