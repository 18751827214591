import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocalization from '../../../../../contexts/localization/hook';
import { setAllFilterParams } from '../../../../../store/filters/actions';
import { resetFilters } from '../../../../../lib/filters';

import {
    Wrapper,
    ButtonBlock,
    FilterButton,
    FilterIcon,
    ActiveIndicator,
    ModalBlock,
    ButtonsBlock,
    Button,
    Icon,
} from './components';
import SortingFilter from '../parts/sorting';
import PriceFilter from '../parts/price';
import BaggageFilter from '../parts/baggage';
import TransfersFilter from '../parts/transfers';
import FlightNumbersFilter from '../parts/flightNumbers';
import AirlinesFilter from '../parts/airlines';
import AirportsFilter from '../parts/airports';
import DurationFilter from '../parts/duration';
import DepartureTimeFilter from '../parts/departureTime';
import ArrivalTimeFilter from '../parts/arrivalTime';
import ModalComponent from '../../../ui/modal';

const FiltersMobile = () => {
    const { t } = useLocalization();
    const dispatch = useDispatch();
    const { filterParams, filtersData } = useSelector(state => state.filters);
    const [modalIsActive, setModalIsActive] = useState(false);

    const checkIsActive = () => {
        const {
            baggage,
            transfers,
            sorting,
            airlines,
            flightNumbers,
            price,
            airports,
            duration,
            departureTime,
            arrivalTime,
        } = filterParams;

        return (
            baggage.isActive ||
            transfers.isActive ||
            sorting.isActive ||
            airlines.isActive ||
            flightNumbers.isActive ||
            price.isActive ||
            airports.isActive ||
            duration.isActive ||
            departureTime.isActive ||
            arrivalTime.isActive
        );
    };

    const handleReset = () => {
        const reset = resetFilters(filtersData);

        dispatch(setAllFilterParams(reset));
        window.history.pushState(null, null, window.location.pathname);
    };

    return (
        <Wrapper>
            <ButtonBlock>
                <FilterButton variant="contained" color="primary" onClick={() => setModalIsActive(true)}>
                    <FilterIcon />
                    {checkIsActive() && <ActiveIndicator />}
                    {t.filters || 'Фильтры'}
                </FilterButton>
            </ButtonBlock>

            <ModalComponent
                active={modalIsActive}
                onClose={() => setModalIsActive(false)}
                title={t.filters || 'Фильтры'}
                leftButtonText={checkIsActive() ? t.reset : null}
                leftButtonOnClick={handleReset}
            >
                <ModalBlock>
                    <SortingFilter />
                    <PriceFilter />
                    <BaggageFilter />
                    <TransfersFilter />
                    <FlightNumbersFilter />
                    <AirlinesFilter />
                    <AirportsFilter />
                    <DurationFilter />
                    <DepartureTimeFilter />
                    <ArrivalTimeFilter />

                    <ButtonsBlock>
                        <Button onClick={() => setModalIsActive(false)}>{t.find}</Button>
                    </ButtonsBlock>
                </ModalBlock>
            </ModalComponent>
        </Wrapper>
    );
};

export default FiltersMobile;
