import styled from 'styled-components';

export const FromDate = styled.div`
    height: 100%;
    background-color: #00ddff;
    width: 200px;
    position: relative;
    border-radius: 12px 0 0 12px;

    @media (max-width: 1279px) {
        width: 226px;
        border-radius: 12px 0 0 12px;
    }

    @media (max-width: 959px) {
        border-radius: 12px 0 0 12px;
        width: 100%;
    }
`;

export const ToDate = styled.div`
    height: 60px;
    background-color: #00ddff;
    width: 200px;
    border-radius: 0 12px 12px 0;

    @media (max-width: 1279px) {
        width: 226px;
    }

    @media (max-width: 959px) {
        width: 100%;
        border-radius: 0 12px 12px 0;
    }

    @media (max-width: 599px) {
        width: 100%;
        border-radius: 0 12px 12px 0;
    }
`;

export const Input = styled.input`
    background-color: transparent;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 8px 40px 8px 16px;
    border: none;
    border-radius: 0;
    font-size: 1rem;
    position: relative;
    color: #fff;
    cursor: pointer;

    z-index: ${props => (props.isActive ? 10 : 5)};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 1279px) {
        border-radius: ${props => (props.direction === 'from' ? '0 0 0 10px' : '0')};
    }

    @media (max-width: 959px) {
        border-radius: 12px;
        width: 100%;
    }

    ::placeholder {
        color: #fff;
    }
`;

export const IconButton = styled.div`
    position: absolute;
    right: 8px;
    top: calc(50% - 12px);
    color: #fff;
    z-index: ${props => (props.isActive ? 10 : 5)};

    &:hover {
        cursor: pointer;
    }
`;

export const Icon = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    right: 8px;
    top: calc(50% - 12px);
    color: #02c5e3;
`;
