import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';

import useLocalization from '../../../../../contexts/localization/hook';

import {
    MobileHistorySearch,
    Routes,
    LoadingBlock,
    Footer,
    ClearAllHistoryButton,
    NoResultText,
    SearchBlock,
    RoutesBlock,
} from './components';
import ModalComponent from '../../../ui/modal';

const MobileSearchHistory = props => {
    const { getHistory, loading, items, cities, flightClassItems, clearHistory } = props;

    const [openModal, setOpenModal] = useState(false);
    const { t, language } = useLocalization();
    const history = useHistory();

    useEffect(() => {
        if (openModal) {
            getHistory();
        }
    }, [openModal]);

    const goToTheHistoryPage = searchId => {
        history.push(`/result/${searchId}`);
        window.location.reload();
    };

    return (
        <div>
            <MobileHistorySearch onClick={() => setOpenModal(true)}>{t.search_history}</MobileHistorySearch>

            <ModalComponent active={openModal} onClose={() => setOpenModal(false)} title={t.search_history}>
                {loading ? (
                    <LoadingBlock>
                        <CircularProgress size={40} style={{ color: '#00ddff' }} />
                    </LoadingBlock>
                ) : (
                    <>
                        {items.map((item, itemIndex) => (
                            <SearchBlock onClick={() => goToTheHistoryPage(item.searchId)} key={`item-${item.searchTime}`}>
                                <RoutesBlock>
                                    {item.routes.map((route, routeIndex) => (
                                        <Routes key={`route-${itemIndex}-${routeIndex}`}>
                                            {cities.find(city => city.code === route.from)?.value[language]} (
                                            {route.from}) -{' '}
                                            {cities.find(city => city.code === route.to)?.value[language]} ({route.to})
                                            - {moment(route.date).format('DD.MM.YYYY')}
                                        </Routes>
                                    ))}

                                    <div style={{ paddingRight: 8, fontSize: 13 }}>
                                        {t.passengers}: {item.passengers.adt}
                                    </div>
                                    <div style={{ fontSize: 13 }}>
                                        {flightClassItems.find(x => x.value === item.cabin)?.label}
                                    </div>
                                </RoutesBlock>

                                <ArrowRight />
                            </SearchBlock>
                        ))}
                    </>
                )}

                {items.length > 0 && !loading && (
                    <Footer>
                        <ClearAllHistoryButton onClick={() => clearHistory()}>{t.clear}</ClearAllHistoryButton>
                    </Footer>
                )}

                {items.length === 0 && !loading && <NoResultText> {t.no_result}</NoResultText>}
            </ModalComponent>
        </div>
    );
};

export default MobileSearchHistory;
