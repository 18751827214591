import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveInput } from '../../../../styles/common/index';

function Input(props) {
    const { id, type, uppercaseType, textTransform, name, disabled, placeholder, value, defaultValue, ...input } =
        props;

    return (
        <ResponsiveInput
            {...input}
            id={id}
            type={type}
            uppercase={uppercaseType}
            textTransform={textTransform}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            value={value || defaultValue}
        />
    );
}

Input.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    textTransform: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
    name: null,
    id: null,
    textTransform: 'none',
    disabled: null,
    type: 'text',
    placeholder: null,
    defaultValue: '',
};

export default Input;
