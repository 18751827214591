import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    orderStatusBlock: {
        display: 'flex',
        alignItems: 'center',
    },
    circle: {
        width: '8px',
        height: '8px',
        borderRadius: '8px',
    },
    statusString: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    statusStringBlock: {
        fontSize: '16px',
        paddingLeft: '12px',
        display: 'inline-block',
    },
    span: {
        paddingRight: '8px',
    },
});
