const envConfigs = {
    environment: process.env.NODE_ENV,

    apiHost: process.env.REACT_APP_API_HOST,
    billingHost: process.env.REACT_APP_BILLING_URL,
    uploadHost: process.env.REACT_APP_UPLOAD_URL,
    hostURL: process.env.REACT_APP_HOST_URL,
    phoneMask: eval(process.env.REACT_APP_PHONE_MASK),

    footerUrl: process.env.REACT_APP_FOOTER_URL,
    mainUrl: process.env.REACT_APP_MAIN_URL,
    appTitle: process.env.REACT_APP_TITLE,
    supportPhone: process.env.REACT_APP_SUPPORT_PHONE,
    supportPhoneSecond: process.env.REACT_APP_SUPPORT_PHONE_SECOND,
    supportMail: process.env.REACT_APP_SUPPORT_MAIL,
    saleMail: process.env.REACT_APP_SALE_MAIL,
    infoMail: process.env.REACT_APP_INFO_MAIL,
    techSupportMail: process.env.REACT_APP_TECH_SUPPORT_MAIL,
    facebookLink: process.env.REACT_APP_FACEBOOK_URL,
    agentsLink: process.env.REACT_APP_AGENTS_URL,

    googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    companyRequisitesId: process.env.REACT_APP_COMPANY_REQUISITES_ID,
    getIpLocationService: process.env.REACT_APP_GET_IP_LOCATION_SERVICE,
    getIpLocationServiceToken: process.env.REACT_APP_GET_IP_LOCATION_TOKEN,
};

export default envConfigs;
