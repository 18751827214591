import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 236px;
    background-color: #fff;
    padding: 16px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin-top: 8px;

    &:hover {
        cursor: default;
    }

    & > * {
        user-select: none;
    }

    @media (max-width: 1279px) {
        width: ${props => (props.dWidth ? '440px' : '220px')};
    }

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const Line = styled.div`
    margin: 16px 0 12px;
    height: 1px;
    width: 100%;
    background-color: #dfe5ec;
`;
