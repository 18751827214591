import bgImage from '../../images/bg-image.png';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import DehazeIcon from '@material-ui/icons/Dehaze';

export const GlobalStyle = createGlobalStyle`
    body {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.41;
        letter-spacing: normal;
        font-family: Roboto, sans-serif;
        caret-color: #ff7043;
        background-color: #fbfcff;
        height: 100%;

        &.hidden {
            overflow-y: hidden;
        }

        @media (min-width:1280px) and (max-width:1300px) {
            overflow-y: overlay;
        }​
    }
`;

export const AppContainer = styled.div({
    position: 'relative',
    flex: '1 0 auto',
    zIndex: 1,
    flexDirection: 'column',
    backgroundColor: '#F6F9FF',
});

export const MainLogoBlock = styled.div`
    cursor: pointer;

    & > img {
        display: block;
    }
`;

export const StyledButton = styled.button`
    width: ${props => props.widthSize || '174px'};
    height: ${props => props.heightSize || '77px'};
    border-radius: 12px;
    background-color: #fbb600;
    font-weight: bold;
    font-size: 25px;
    font-size: ${props => props.fontSize || '20px'};
    color: ${props => props.colorType || '#ffffff'};
    cursor: pointer;
    border: none;
    max-width: 600px;
    margin: ${props => props.marginAdd || null};

    &:active {
        background-color: #fbb600;
    }

    &:focus {
        outline: none;
    }

    :disabled {
        background-color: grey;
        color: #fff;
        cursor: default;
    }

    @media (max-width: 599px) {
        width: 335px;
        font-size: 18px;
    }
`;

export const StyledHeaderMenuButton = styled(DehazeIcon)`
    cursor: pointer;
    padding: 17px 0;
    display: block !important;
`;

export const StyledSearchButton = styled(StyledButton)`
    width: 100%;
    height: 100%;
    display: none;

    @media (min-width: 600px) {
        display: inline-block;
    }
`;

export const StyledMobileSearchButton = styled(StyledButton)`
    width: 100%;
    min-height: 52px;
    display: inline-block;

    @media (min-width: 600px) {
        display: none;
    }
`;

export const StyledPaymentButton = styled(StyledButton)`
    font-size: 18px;
`;

export const StyledVoidButton = styled(StyledButton)`
    font-size: 16px;
    width: 206px;
`;

export const StyledSupportButton = styled(StyledButton)`
    background-color: #4caf50;
    font-size: 16px;
    width: 206px;

    :hover {
        background-color: #3fb544;
    }

    :active {
        background-color: #388c3c;
    }

    :focus {
        outline: none;
    }
`;

export const StyledTicketsOptionsButton = styled(StyledButton)`
    background-color: #ffffff;
    border-color: #ffffff;
    font-size: 16px;
    width: 206px;
    color: #212121;

    :hover {
        background-color: #fafbfc;
    }

    :active {
        background-color: #fafbfc;
    }

    :focus {
        outline: none;
    }
`;

export const SearchBackground = styled.div`
    background-image: url(${bgImage});
    margin-bottom: 30px;
    background-size: cover;
    position: ${props => (props.isStickySearchBlock ? 'sticky' : 'relative')};
    top: ${props => (props.isStickySearchBlock ? '56px' : '0')};
    z-index: 90;
`;

export const SearchResultBackground = styled.div`
    background-image: url(${bgImage});
    width: 100%;
    background-size: cover;
    position: ${props => (props.minimizeSearch ? 'sticky' : 'relative')};
    top: ${props => (props.minimizeSearch ? '58px' : '0')};
    left: 0;
    z-index: 40;
    transition: transform 0.3s ease;

    &.hideHeader {
        transform: translateY(${props => (props.minimizeSearch ? '-58px' : '0')});
        transition: transform 0.3s ease;
    }

    @media screen and (min-width: 1279px) {
        position: sticky;
        top: 65px;
    }
`;

export const StyledInput = styled.input`
    ${props =>
        props.isPadding &&
        `
        padding-left: 16px;
        padding-top: 30px;
        padding-bottom: 8px;
    `}

    height: ${props => props.heightSize || '52px'};
    width: ${props => props.widthSize || '297px'};

    border-radius: 4px;
    border: solid ${props => (props.borderColor ? '2px' : '1px')} ${props => props.borderColor || '#cfd8dc'};
    background-color: ${props => (props.disabled ? '#f2f4f5' : '#ffffff')};
    font-size: 18px;
    color: ${props => (props.disabled ? '#b9c1c5' : '#212121')};
    text-transform: ${props => props.textTransform || 'none'};

    :focus {
        outline: none;
        border: solid 1px #ff7043;
    }
`;

export const StyledLink = styled.a`
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
`;

export const InvalidMessage = styled.div`
    font-size: 14px;
    color: #c62828;
`;

export const WarningMessage = styled.div`
    font-size: 14px;
    color: #ff7043;
`;

export const ErrorsFieldBlock = styled.div`
    text-align: left;
`;

export const FormFieldLabel = styled.label`
    font-size: 12px;
    color: #9da8ae;
    position: absolute;
    top: 10px;
    left: 16px;
`;

export const FormGroup = styled.div`
    position: relative;
    width: 100%;
`;

export const Arrow = styled.div`
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
`;

export const ArrowDown = styled(Arrow)`
    border-top: 5px solid ${props => (props.isInvert ? '#212121' : '#ffffff')};
`;

export const ArrowUp = styled(Arrow)`
    border-bottom: 5px solid ${props => (props.isInvert ? '#212121' : '#ffffff')};
`;

export const SelectArrowDown = styled(ArrowDown)`
    margin-left: 0;
`;

export const InvertSelectArrowDown = styled(SelectArrowDown)`
    border-top: 8px solid #212121;
`;

export const LinkText = styled.div`
    display: inline-block;
`;

export const ResponsiveInput = styled.input`
    ${props =>
        props.isPadding &&
        `
        padding-left: 16px;
        padding-top: 30px;
        padding-bottom: 8px;
    `}

    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    border: solid ${props => (props.borderColor ? '2px' : '1px')} ${props => props.borderColor || '#C3D6E8'};
    background-color: ${props => (props.disabled ? '#f2f4f5' : '#ffffff')};
    font-size: 18px;
    color: ${props => (props.disabled ? '#b9c1c5' : '#212121')};
    text-transform: ${props => props.textTransform || 'none'};
    box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);

    :focus {
        outline: none;
        border: solid 1px #00ddff;
    }

    ::placeholder {
        color: #c4c4c4;
        font-size: 17px;
    }
`;
