import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    emptyOrdersBox: {
        height: '360px',
    },
    emptyOrdersBoxTitle: {
        paddingTop: '140px',
        color: '#7e878b',
        fontWeight: '500',
        fontSize: '24px',
        textAlign: 'center',
    },
});
