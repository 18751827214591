import React, { useState } from 'react';
import { useStyles } from '../../containers/booking/styles';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import ResponsiveFormField from '../../components/ui/responsive/FormField';
import { phone, minLength2, passwordConfirm } from '../../lib/validate';
import PhoneField from '../../components/ui/components/PhoneField/PhoneField';
import { CircularProgress } from '@material-ui/core';
import useLocalization from '../../contexts/localization/hook';
import { Grid } from '@material-ui/core';
import { FormSubmitButton } from '../../components/design_v2/authPages/components';
import API from '../../lib/api';
import { useDispatch } from 'react-redux';
import { setClientData } from '../../store/clientData/actions';
import { ButtonContainer } from '../newUserProfile/components';

function ProfileInfoForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { handleSubmit, toggleEdit } = props;
    const [isLoading, setIsLoading] = useState(false);

    const SubmitUpdates = values => {
        if (isLoading) {
            return;
        }

        let newValues = { ...values };
        if (newValues.phone) {
            newValues.phone = `+${values.phone.replace(/[^\d]/g, '')}`;
        }

        setIsLoading(true);

        API.postProfileEdit(newValues)
            .then(response => {
                dispatch(
                    setClientData({
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        fatherName: response.data.father_name,
                        email: response.data.email,
                        phone: response.data.phone,
                    })
                );
                setIsLoading(false);
                toggleEdit();
            })
            .catch(err => {
                console.log(err.response.data);
            });
    };

    return (
        <form onSubmit={handleSubmit(SubmitUpdates)}>
            <Grid container spacing={0} className={classes.passengersContainer}>
                <div className={classes.passengersBlock}>
                    <RenderProfile balance={props.balance} isLoading={isLoading} />
                </div>
            </Grid>
        </form>
    );
}

const RenderProfile = props => {
    const { isLoading } = props;
    const { t } = useLocalization();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={ResponsiveFormField}
                            name={`last_name`}
                            label={t.last_name}
                            widthSize="100%"
                            isPadding={true}
                            validate={[minLength2]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={ResponsiveFormField}
                            name={`first_name`}
                            label={t.fist_name}
                            widthSize="100%"
                            isPadding={true}
                            validate={[minLength2]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={ResponsiveFormField}
                            name={`father_name`}
                            label={t.father_name}
                            widthSize="100%"
                            isPadding={true}
                            validate={[minLength2]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={ResponsiveFormField}
                            name={`login`}
                            label={t.login_text}
                            widthSize="100%"
                            isPadding={true}
                            validate={[minLength2]}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={ResponsiveFormField}
                            name={`email`}
                            label={t.email}
                            widthSize="100%"
                            isPadding={true}
                            validate={[minLength2]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={PhoneField}
                            name={`phone`}
                            label={t.phone_number}
                            width="100%"
                            height="auto"
                            isPadding={true}
                            validate={[phone]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field
                            component={ResponsiveFormField}
                            name={`balance`}
                            label={t.balance}
                            width="100%"
                            height="auto"
                            isPadding={true}
                            format={value => props.balance}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={ResponsiveFormField}
                            name={`password`}
                            type="password"
                            label={t.enter_new_password}
                            widthSize="100%"
                            isPadding={true}
                            validate={[minLength2, passwordConfirm]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ height: '84px' }}>
                        <Field
                            component={ResponsiveFormField}
                            name={`password_confirm`}
                            type="password"
                            label={t.password_confirm}
                            widthSize="100%"
                            isPadding={true}
                            validate={[minLength2, passwordConfirm]}
                        />
                    </Grid>
                    <ButtonContainer>
                        {isLoading ? (
                            <FormSubmitButton heightAdd="52" disabled>
                                {isLoading ? <CircularProgress color="#fff" size={26} /> : 'Сохранить'}
                            </FormSubmitButton>
                        ) : (
                            <FormSubmitButton heightAdd="52">{t.save}</FormSubmitButton>
                        )}
                    </ButtonContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

let former = reduxForm({
    form: 'user-edit',
})(ProfileInfoForm);

former = connect(state => ({
    initialValues: {
        first_name: state.clientData.firstName,
        last_name: state.clientData.lastName,
        father_name: state.clientData.fatherName,
        login: state.clientData.login,
        email: state.clientData.email,
        phone: state.clientData.phone,
    },
}))(former);

export default former;
