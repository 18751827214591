import styled from 'styled-components';

export const Title = styled.div`
    font-size: 30px;
    color: #404b5a;
    font-weight: bold;
    margin: 30px 0;
    text-align: center;

    @media (max-width: 1279px) {
        font-size: 36px;
    }

    @media (max-width: 959px) {
        font-size: 24px;
    }
`;

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    width: 32%;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 1px rgba(0, 59, 110, 0.1);

    @media (max-width: 1279px) {
        width: 48%;
    }

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const Label = styled.div`
    color: #c4c4c4;
`;

export const Value = styled.div`
    padding: 8px 8px 0;
    font-weight: bold;
    color: #404b5a;
`;
