import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    block: {
        marginBottom: '20px',
    },
    loading: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    currencySpan: {
        color: '#00ddff',
    },
    currentBalanceSpan: {
        fontSize: '18px',
        color: '#404B5A',
    },
});
