import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    orderContainer: {
        marginTop: '16px',
        borderRadius: '8px',
        backgroundColor: '#ffffff',
    },
    orderBlock: {
        padding: '16px',
    },
    orderMainBlock: {
        width: '100%',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    oderIdBlock: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#212121',
    },
    routesBlock: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#212121',
    },
    amountBlock: {
        fontSize: '24px',
        fontWeight: '500',
        color: '#212121',
        textAlign: 'right',
    },
    timestampBlock: {
        fontSize: '16px',
        color: '#7e878b',
    },
    departuresBlock: {
        fontSize: '16px',
        color: '#7e878b',
    },
    statusBlock: {
        fontSize: '16px',
        color: '#7e878b',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    orderSubmitBlock: {
        float: 'right',
    },
    actionButtonContainer: {
        width: '100%',
        borderTop: '2px #f2f4f5 solid',
        marginTop: '24px',
    },
    actionButtonBlock: {
        paddingTop: '24px',
        flexWrap: 'wrap',
        display: 'flex',
    },
    actionSubmitBlock: {
        paddingBottom: '8px',
        paddingRight: '8px',

        '& button': {
            padding: '6px 12px',
            fontSize: '12px',
            height: 'auto',
        },
    },
});
