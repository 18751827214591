import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: auto;
    max-width: 500px;
    padding: 50px 15px;
    text-align: center;
`;

export const NotFoundRecommMargin = styled.div`
    position: relative;
`;

export const NotFoundRecommFirstBlock = styled.div`
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
`;

export const NotFoundRecommSecondBlock = styled.div`
    font-size: 18px;
`;
