import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import iconsClose from '../../../../../images/krest.png';
import { StyledButton } from '../../../../../styles/common/index';

export const Wrapper = styled.div`
    background-color: #fff;
    padding: 8px 16px;
    margin-bottom: 16px;
    border-radius: 22px;
    box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
    position: relative;

    @media screen and (max-width: 959px) {
        display: none;
    }
`;

export const SpecialText = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 8px;
    top: 8px;
    height: 30px;
    border-radius: 30px;
    padding: 0 15px;
    text-align: center;
    z-index: 50;
    background-color: #5ba554;
    color: #fff;
`;

export const FlightInfo = styled.div`
    text-align: right;
    position: relative;
`;

export const ArrivalInfo = styled.div`
    text-align: left;
    position: relative;
`;

export const DurationBlock = styled.div`
    text-align: center;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #9da8ae;
`;

export const RouteBlock = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #9da8ae;
`;

export const TimeRouteBlock = styled.div`
    color: #212121;
    font-size: 24px;
    font-weight: 500;
`;

export const RouteTitleContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 7px 0;
    background-size: 24px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: left center;
`;

export const RouteTitle = styled.div`
    color: ${props => props.themeColor};
    margin-left: 32px;
    font-size: 18px;
    font-weight: 500;
`;

export const RouteMainInfo = styled.div`
    box-sizing: border-box;
    height: 150px;
    padding: 10px 10px 10px 0;
    position: relative;

    @media screen and (max-width: 1279px) {
        height: 260px;
    }

    & > div {
        height: 100%;
    }
`;

export const PentagonShape = styled.div`
    width: 38px;
    background: ${props => props.themeColor};
    position: absolute;
    left: -39px;
    top: 0;
    border-radius: 50%;
`;

export const AirlineLogoBlock = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const AirlineLogo = styled.div`
    height: 30px;
    width: 100%;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
`;

export const FlightInfoGrid = styled(Grid)`
    order: 2;
    display: flex;
    align-items: center;
    border-radius: 14px;
    border: 1px solid #00ddff;

    @media (max-width: 1279px) {
        border: none;
        order: 3;
        padding: 12px;
    }
`;

export const TransfersArrowImg = styled.div`
    height: 10px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
`;

export const StringSpan = styled.span`
    margin-left: 3px;
`;

export const NightTransfer = styled.div`
    color: #0085ff;
    font-weight: 400;
    font-size: 12px;
`;

export const OrderInfoGrid = styled(Grid)`
    order: 3;
    display: flex;
    align-items: center;

    @media (max-width: 1279px) {
        order: 2;
    }
`;

export const HideRouteTariffContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledHideTariffsButton = styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${iconsClose});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center left;
    position: relative;
`;

export const TariffChangeBlock = styled.div`
    margin: 0 0 16px;
    font-size: 14px;
    font-weight: 500;
    color: #00ddff;
    text-align: right;
    cursor: pointer;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const RightTariffChangeBlock = styled(TariffChangeBlock)`
    border-left: solid 1px #cfd8dc;
    padding-left: 10px;
    margin-left: 10px;
`;

export const TariffCloseBlock = styled(TariffChangeBlock)`
    font-size: 16px;
`;

export const ShortTariffInfo = styled.div`
    text-align: right;
    position: relative;
`;

export const ChangeTariffContainer = styled(ShortTariffInfo)`
    cursor: pointer;
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const LowcostCharterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Charter = styled.div`
    color: #ffffff;
    width: 100px;
    border-radius: 4px;
    background-color: #67d457;
    text-align: center;
    margin-right: 5px;
`;

export const Lowcost = styled.div`
    display: inline-table;
    color: #ffffff;
    width: 100px;
    border-radius: 4px;
    background-color: #4caf50;
    text-align: center;
`;

export const ClassBlock = styled.div`
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: #212121;
`;

export const AdditionalInformationShort = styled.div`
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: #9da8ae;
    margin-top: 4px;
`;

export const InlineBlock = styled.div`
    display: inline-block;
`;

export const FreeSeatsLabelBlock = styled(InlineBlock)`
    vertical-align: 1px;
    padding-left: 12px;
`;

export const RouteAdditionallyInfo = styled.div`
    border-top: none;
`;

export const ButtonContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
`;

export const SupplierBlock = styled.div`
    color: #9da8ae;
    font-size: 14px;
`;

export const AmountSubmitBlock = styled.div`
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1279px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
`;

export const AmountBlock = styled.div`
    display: flex;
    align-items: center;
    color: #404b5a;
    font-size: 34px;
    font-weight: bold;

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }

    & > span {
        color: #00ddff;
        margin-right: 8px;
    }
`;

export const ChoosedButtonBlock = styled(InlineBlock)`
    width: 126px;
    height: 48px;
    margin-left: 24px;
`;

export const ChoosedButton = styled(StyledButton)`
    width: 126px;
    height: 100%;
    border-radius: 8px;
    border: none;
    background-color: #00ddff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;

    &:hover {
        background-color: #00ddff;
    }
`;
