import React from 'react';
import { useSelector } from 'react-redux';

import { SearchContainer, SearchTitle, SearchSubtitle } from './components';
import SearchForm from './parts/form';
import bgImage from '../../../images/back-image.png';
import useLocalization from '../../../contexts/localization/hook';

const SearchComponent = () => {
    const { showSearchBlock, isStickySearchBlock, isSmallSearchBlock, isFullScreenSearchBlock } = useSelector(
        state => state.appConfigs
    );
    const { flightType } = useSelector(state => state.searchParams);
    const { t } = useLocalization();

    return (
        <SearchContainer
            showSearchBlock={showSearchBlock}
            isStickySearchBlock={isStickySearchBlock}
            isSmallSearchBlock={isSmallSearchBlock}
            isFullScreenSearchBlock={isFullScreenSearchBlock}
            flightType={flightType}
            backgroundImage={bgImage}
        >
            {!isSmallSearchBlock && (
                <>
                    <SearchTitle>{t.fly_ru_tagline}</SearchTitle>
                    <SearchSubtitle>{t.fly_ru_subtagline}</SearchSubtitle>
                </>
            )}

            <SearchForm />
        </SearchContainer>
    );
};

export default SearchComponent;
