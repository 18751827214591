import React from 'react';
import { useSelector } from 'react-redux';
import MainLoading from '../components/design_v2/mainLoading';

const LoadingProvider = ({ children }) => {
    const { showLoading } = useSelector(state => state.loading);

    return (
        <>
            {children}
            {showLoading && <MainLoading />}
        </>
    );
};

export default LoadingProvider;
