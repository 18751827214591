import { SET_TRANSACTIONS_ITEMS, SET_TRANSACTIONS_FILTERS, SET_IS_LOADING } from './constants';

export const setTransactionsItems = data => dispatch =>
    dispatch({
        type: SET_TRANSACTIONS_ITEMS,
        payload: data,
    });

export const setTransactionsFilters = data => dispatch =>
    dispatch({
        type: SET_TRANSACTIONS_FILTERS,
        payload: data,
    });

export const setIsLoading = data => dispatch =>
    dispatch({
        type: SET_IS_LOADING,
        payload: data,
    });
