import { SET_FILTER_PARAMS, SET_ALL_FILTER_PARAMS, RESET_ALL_FILTER_PARAMS, SET_FILTERS_DATA } from './constants';

import initialState from './initialState';
import { handleFilterParamsChange } from '../../lib/filters';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTER_PARAMS:
            handleFilterParamsChange(action.payload);
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    [action.payload.type]: action.payload.values,
                },
            };

        case SET_ALL_FILTER_PARAMS:
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    ...action.payload,
                },
            };

        case RESET_ALL_FILTER_PARAMS:
            return {
                ...state,
                filterParams: initialState.filterParams,
            };

        case SET_FILTERS_DATA:
            return {
                ...state,
                filtersData: {
                    ...state.filtersData,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};
