import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    link: {
        color: '#00ddff',
        cursor: 'pointer',
        marginLeft: '8px',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: 1.6,
        marginTop: '8px',
        marginBottom: '8px',
        display: 'block',
    },
    divider: {
        margin: '0 8px',
    },
    navBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    userBlock: {
        display: 'flex',
        alignItems: 'center',
    },
    accountIcon: {
        width: '45px',
        height: '45px',
        color: '#00ddff',
    },
    enterIcon: {
        width: '28px',
        height: '28px',
        color: '#00ddff',
    },
    caretIcon: {
        transition: 'all .2s ease',
        transform: 'rotate(180deg)',
    },
    textCurrency: {
        marginRight: '8px',
        textTransform: 'uppercase',
    },
    enterBlock: {
        border: '1px solid #00ddff',
        borderRadius: '50%',
        marginRight: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '42px',
        width: '42px',
        cursor: 'pointer',
    },
});

export default useStyles;
