import React, { useEffect, useState, useRef } from 'react';
import { Container } from '@material-ui/core';
import API from '../../lib/api';
import { useDispatch } from 'react-redux';
import { setAppConfigs } from '../../store/appConfigs/actions';
import { makeStyles } from '@material-ui/styles';
import useLocalization from '../../contexts/localization/hook';
import Loading from '../../components/design_v2/ui/loading/index';
import { useLocation } from 'react-router';
import NotFoundContent from '../../components/design_v2/ui/notFoundContent';
import envConfigs from '../../config/envConfigs';

const useStyles = makeStyles({
    container: {
        margin: '64px 0',

        '@media (max-width: 959px)': {
            margin: '32px 0 64px',
        },
    },
    informBlock: {
        width: '100%',
    },
    staticHeader: {
        textAlign: 'center',
        color: '#404B5A',
    },
    staticBody: {
        fontSize: '16px',

        '& img': {
            maxWidth: '100% !important',
            height: 'auto !important',
        },
    },
});

const StaticPages = props => {
    const {
        match: { params },
    } = props;
    const { slug } = params;
    const { t, language } = useLocalization();

    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const containerRef = useRef(null);

    const [getInfo, setGetInfo] = useState([]);
    const [notFound, setNotFound] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(location.pathname);

    useEffect(() => {
        document.title = `${t.page_booking} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                showSearchBlock: true,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    useEffect(() => {
        getStaticPage();
    }, [language]);

    useEffect(() => {
        if (location.pathname !== currentLocation) {
            getStaticPage();
            setCurrentLocation(location.pathname);
        }
    }, [location, currentLocation]);

    const executeScroll = () => {
        const y = containerRef?.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const getStaticPage = async () => {
        setIsLoading(true);
        setNotFound(false);
        await API.getStaticPageBySlug({ slug, language })
            .then(res => {
                setGetInfo(res.data.footer_content[0]);
                document.title = `${res.data.footer_content[0].page_name} | ${envConfigs.appTitle}`;
            })
            .catch(error => {
                setNotFound(true);
            });
        setIsLoading(false);
        executeScroll();
    };

    if (isLoading)
        return (
            <div className={classes.container}>
                <Loading />
            </div>
        );

    return (
        <Container fixed ref={containerRef}>
            <div className={classes.container}>
                {notFound ? (
                    <NotFoundContent />
                ) : (
                    <div className={classes.informBlock}>
                        <h1 className={classes.staticHeader}>{getInfo.page_name}</h1>
                        <p className={classes.staticBody} dangerouslySetInnerHTML={{ __html: getInfo.body }} />
                    </div>
                )}
            </div>
        </Container>
    );
};

export default StaticPages;
