import styled from 'styled-components';

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: ${props => (props.backgroundImage ? `url(${props.backgroundImage})` : 'none')};
    background-position: center center;
    background-color: #f6f9ff;

    min-height: ${props => (props.isFullScreenSearchBlock ? `calc(100vh - 100px)` : 'auto')};
    position: ${props => (props.isStickySearchBlock ? 'sticky' : 'relative')};
    top: ${props => (props.isStickySearchBlock ? '100px' : '0')};
    z-index: ${props => (props.isStickySearchBlock ? 250 : 1)};

    padding: ${props => (props.isSmallSearchBlock ? '8px 0' : props.isFullScreenSearchBlock ? '0 0 15vh' : '32px 0')};

    @media screen and (max-width: 959px) {
        padding: ${props => (props.isSmallSearchBlock ? '8px 0' : '32px 0')};
        top: ${props => (props.isStickySearchBlock ? '80px' : '0')};
        min-height: ${props => (props.isFullScreenSearchBlock ? `calc(100vh - 80px)` : 'auto')};
    }
`;

export const SearchTitle = styled.div`
    font-size: 2.3rem;
    color: ${props => props.color || '#404B5A'};
    text-align: center;
    margin: 8px 0;
    padding: 0 16px;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        font-size: 1.8rem;
        margin-bottom: 48px;
    }
`;

export const SearchSubtitle = styled.div`
    font-size: 1rem;
    color: ${props => props.color || '#404B5A'};
    text-align: center;
    margin-bottom: 32px;
    font-weight: normal;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
