import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 320px;
`;

export const RoutesTextBlock = styled.div`
    margin-bottom: 16px;
    text-align: center;
`;

export const RouteText = styled.div`
    display: inline;
    font-size: 24px;
    font-weight: bold;
    color: #404b5a;
`;

export const RouteIcon = styled.div`
    display: inline-block;
    margin: 0 12px;
    vertical-align: ${props => props.verticalAlign};
    line-height: 1;
`;

export const Text = styled.div`
    font-size: 16px;
    margin-bottom: 4px;
    text-align: center;
    color: #404b5a;
    font-weight: bold;
`;
