import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import _ from 'lodash';
import API from '../../lib/api';
import { useStyles } from '../../containers/order/styles';
import { checkForMIMEType } from '../../lib/pdfConvert';
import {
    checkAllowedMK,
    checkInProgressStatus,
    getAge,
    getAgeMonths,
    getAgeString,
    getDocumentType,
    getMonthsString,
} from '../result/common';
import { CERTIFICATE_ISSUED_CODE } from '../../containers/order/constants';
import { normalizePrice } from '../../lib/normalize';
import useLocalization from '../../contexts/localization/hook';
import { INS } from '../../containers/search/constants';

export default function BookData(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const {
        bookingNumber,
        payerEmail,
        payerPhone,
        passengers,
        bookStatus,
        orderId,
        sessionId,
        currency,
        totalPrice,
        airlineBookingNumber,
    } = props;

    const isInProgress = checkInProgressStatus(bookStatus);

    const clearedAirlineBookingNumber = clearAirlineBookingNumber(airlineBookingNumber);

    return (
        <div className={classes.bookDataContainer}>
            <Grid container className={classes.bookDataBlock}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={3} className={classes.bookLabel}>
                            {t.book}
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9} sm={8}>
                                            <div className={classes.bookDataTitle}>{t.pnr}</div>
                                        </Grid>
                                        <Grid item xs={3} sm={4}>
                                            <div className={classes.locatorItem}>{bookingNumber}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9} sm={8}>
                                            <div className={classes.bookDataTitle}>{t.online_registration_locator}</div>
                                        </Grid>
                                        <Grid item xs={3} sm={4}>
                                            <div className={classes.locatorItem}>
                                                {clearedAirlineBookingNumber || bookingNumber}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <BookingMkLink bookStatus={bookStatus} orderId={orderId} sessionId={sessionId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.lineBlock}>
                    <Divider className={classes.line} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3} className={classes.bookLabel}>
                            {t.passengers}
                        </Grid>
                        <Grid item xs={12} md={8} lg={9}>
                            <Passengers passengers={passengers} orderId={orderId} sessionId={sessionId} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.lineBlock}>
                    <Divider className={classes.line} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3} className={classes.bookLabel}>
                            {t.buyer}
                        </Grid>
                        <Grid item xs={12} md={8} lg={9} className={classes.bookDataTitle}>
                            <div>{payerEmail}</div>
                            <div>{payerPhone}</div>
                        </Grid>
                    </Grid>
                </Grid>
                {!isInProgress && (
                    <Fragment>
                        <Grid item xs={12} className={classes.lineBlock}>
                            <Divider className={classes.line} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} lg={3} className={classes.bookLabel}>
                                    {t.order_cost}
                                </Grid>
                                <Grid item xs={12} sm={8} lg={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className={classes.totalPriceText}>
                                                {normalizePrice(totalPrice)} {currency}
                                                <div className={classes.priceIcon}>
                                                    <ErrorOutlineIcon fontSize="small" />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </div>
    );
}

const PartnerFees = props => {
    const { partnerFees, currency } = props;
    const classes = useStyles();
    const { t } = useLocalization();
    const [showingPartnerFees, setShowingPartnerFees] = useState(null);

    useEffect(() => {
        if (partnerFees) {
            if (!_.isNull(partnerFees[currency]) && !_.isUndefined(partnerFees[currency])) {
                setShowingPartnerFees(partnerFees[currency].toFixed(2));
            } else {
                setShowingPartnerFees(partnerFees);
            }
        } else {
            setShowingPartnerFees('0.00');
        }
    }, [partnerFees, currency]);

    return (
        <div className={classes.partnerFeesBlock}>
            <span>{t.include_your_fees}</span>
            <span>
                {showingPartnerFees} {currency}
            </span>
        </div>
    );
};

const clearAirlineBookingNumber = number => {
    if (number?.length > 2) {
        return number.split(',').join(', ');
    }

    return null;
};

const BookingMkLink = props => {
    const classes = useStyles();
    const { bookStatus, orderId, sessionId } = props;

    const isAllowedMK = checkAllowedMK(bookStatus);

    if (isAllowedMK) {
        return (
            <Grid item xs={12}>
                <div className={classes.routeInformation}>
                    <MkLink bookStatus={bookStatus} orderId={orderId} sessionId={sessionId} />
                </div>
            </Grid>
        );
    }

    return null;
};

const MkLink = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { bookStatus, orderId, sessionId } = props;

    const isInProgress = checkInProgressStatus(bookStatus);

    return (
        <Fragment>
            <GetAppIcon />
            <div className={classes.routeInformationText} onClick={() => downloadMk(orderId, sessionId)}>
                {bookStatus === CERTIFICATE_ISSUED_CODE ? t.download_charter_certificate : t.download_itinerary_receipt}
            </div>
        </Fragment>
    );
};

export const downloadMk = (orderId, sessionId, setSuccess = () => {}) => {
    const params = {
        type: 'mk',
        order_id: orderId,
        session_id: sessionId,
    };

    API.apiGetDocument(params).then(response => {
        const { pdf } = response;

        setSuccess();
        checkForMIMEType(pdf, 'pdf');
    });
};

const PassengersNamesTickets = props => {
    const classes = useStyles();
    const { passenger } = props;
    const { name, middle_name, surname } = passenger;

    return (
        <div className={classes.passengerName}>
            {name} {middle_name} {surname}
        </div>
    );
};

const Passengers = props => {
    const classes = useStyles();
    const { t } = useLocalization();

    const { passengers, orderId, sessionId } = props;

    return _.map(passengers, (passenger, index) => {
        const age = getAge(passenger.date_of_birth);

        let ageString;

        if (age > 0) {
            ageString = getAgeString(age, t);
        } else {
            const age = getAgeMonths(passenger.date_of_birth);

            ageString = getMonthsString(age, t);
        }

        return (
            <div key={index} className={classes.passengerBlock}>
                <PassengersNamesTickets passenger={passenger} orderId={orderId} sessionId={sessionId} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sm={4} lg={3} className={classes.passengerParamTitle}>
                                {t.document}
                            </Grid>
                            <Grid item xs={6} sm={8} lg={9} className={classes.passengerParam}>
                                {getDocumentType(passenger.document_type, t)}, {passenger.document_number}, {t.before}{' '}
                                {formatDates(passenger.expiration_date)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sm={4} lg={3} className={classes.passengerParamTitle}>
                                {t.citizenship}
                            </Grid>
                            <Grid item xs={6} sm={8} lg={9} className={classes.passengerParam}>
                                {passenger.citizenship}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sm={4} lg={3} className={classes.passengerParamTitle}>
                                {t.age}
                            </Grid>
                            <Grid item xs={6} sm={8} lg={9} className={classes.passengerParam}>
                                {ageString}, {getAgeType(passenger.type, t)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sm={4} lg={3} className={classes.passengerParamTitle}>
                                {t.date_of_birth}
                            </Grid>
                            <Grid item xs={6} sm={8} lg={9} className={classes.passengerParam}>
                                {formatDates(passenger.date_of_birth)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sm={4} lg={3} className={classes.passengerParamTitle}>
                                {t.gender}
                            </Grid>
                            <Grid item xs={6} sm={8} lg={9} className={classes.passengerParam}>
                                {getGender(passenger.gender, t)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    });
};

const formatDates = data => {
    const dataArray = data.split('-');

    return dataArray[2] + '-' + dataArray[1] + '-' + dataArray[0];
};

const getGender = (type, t) => {
    const GENDER_ITEMS = [
        { value: 'male', label: t.male },
        { value: 'female', label: t.female },
    ];

    const genderString = _.find(GENDER_ITEMS, { value: type });

    return genderString.label;
};

const getAgeType = (type, t) => {
    return type === INS ? `${t[`${type}_main_label`]} (${t.inf_with_seat_second_label})` : t[`${type}_main_label`];
};
