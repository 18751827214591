import React, { useEffect, useRef, useState } from 'react';
import { Container, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import useLocalization from '../../contexts/localization/hook';
import config from '../../config';

import { setAppConfigs } from '../../store/appConfigs/actions';
import { setShowLoading } from '../../store/loading/actions';
import {
    FormGroup,
    PassengerAddButton,
    PassengerAddButtonBlock,
    PassengerFormInput,
    PassengerItem,
    PassengerRemoveBlock,
    PassengerRemoveButton,
    PassengersTitle,
    SubmitButton,
    Title,
} from './components';
import TextInput from '../../components/design_v2/ui/textInput';
import MaskedTextInput from '../../components/design_v2/ui/maskedTextInput';
import FileInput from '../../components/design_v2/ui/fileInput';
import API from '../../lib/api';
import Select from '../../components/design_v2/ui/select';

const RefundOrderPage = () => {
    const dispatch = useDispatch();
    const { t } = useLocalization();
    const containerRef = useRef();

    const { isFullScreenSearchBlock } = useSelector(state => state.appConfigs);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = `${t.refund_order} | ${config.envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );
        dispatch(setShowLoading(false));
    }, []);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, [isFullScreenSearchBlock]);

    const validationSchema = Yup.object().shape({
        order_id: Yup.string().trim().required(t.required),
        refund_reason: Yup.string().trim().required(t.required),
        payment_code: Yup.string().trim().required(t.required),
        payer_email: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
        payer_phone: Yup.string().trim().required(t.required),
        passengers: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().trim().required(t.required),
                passport_number: Yup.string().trim().required(t.required),
                passport_copy: Yup.mixed().required(t.required),
            })
        ),
        refund_payment_code: Yup.string()
            .trim()
            .when('payment_type', payment_type => {
                if (payment_type === 'card') return Yup.string().required(t.required);
            }),
    });

    const onSubmitFunction = values => {
        const formData = new FormData();
        formData.append('order_id', values.order_id);
        formData.append('refund_reason', values.refund_reason);
        formData.append('payment_type', values.payment_type);
        formData.append('payment_code', values.payment_code);
        formData.append('payer_email', values.payer_email);
        formData.append('payer_phone', values.payer_phone);
        values.passengers.forEach((val, ind) => {
            formData.append(`passengers[${ind}][name]`, val.name);
            formData.append(`passengers[${ind}][passport_number]`, val.passport_number);
            formData.append(`passengers[${ind}][passport_copy]`, val.passport_copy);
        });
        formData.append('refund_payment_code', values.refund_payment_code || values.payment_code);
        formData.append('company_req_id', config.envConfigs.companyRequisitesId);
        setIsLoading(true);

        API.refundOrder(formData).then(response => {
            setIsLoading(false);
        });
    };

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            order_id: '',
            refund_reason: '',
            payment_type: 'balance',
            payment_code: '', // User ID or 4 last digits of card - depends on payment_type
            payer_email: '',
            payer_phone: '',
            passengers: [
                {
                    name: '',
                    passport_number: '',
                    passport_copy: '',
                },
            ],
            refund_payment_code: '',
        },
        validationSchema,
        onSubmit: onSubmitFunction,
    });

    const handlePhoneChange = value => {
        const trimmedValue = value.replace(/[^0-9\.]+/g, '');

        setFieldValue('payer_phone', trimmedValue);
    };

    const handleRemovePassenger = index => {
        setFieldValue(
            'passengers',
            values.passengers.filter((_, ind) => ind !== index)
        );
    };

    const handleAddPassenger = () => {
        setFieldValue('passengers', [
            ...values.passengers,
            {
                name: '',
                passport_number: '',
                passport_copy: '',
            },
        ]);
    };

    const paymentTypes = [
        { value: 'balance', text: t.payment_from_client_balance },
        { value: 'card', text: t.payment_from_card },
    ];

    return (
        <Container fixed ref={containerRef}>
            <Title>{t.refund_order}</Title>

            <FormGroup>
                <TextInput
                    value={values.order_id}
                    onChangeText={handleChange('order_id')}
                    placeholder={t.order_num}
                    label={t.order_num}
                    error={errors.order_id}
                    onBlur={handleBlur('order_id')}
                    touched={touched.order_id}
                    topLabel
                    required
                />
            </FormGroup>
            <FormGroup>
                <TextInput
                    value={values.refund_reason}
                    onChangeText={handleChange('refund_reason')}
                    placeholder={t.refund_reason}
                    label={t.refund_reason}
                    error={errors.refund_reason}
                    onBlur={handleBlur('refund_reason')}
                    touched={touched.refund_reason}
                    topLabel
                    required
                />
            </FormGroup>
            <FormGroup>
                <Select
                    value={paymentTypes.find(x => x.value === values.payment_type)}
                    items={paymentTypes}
                    onSelect={item => setFieldValue('payment_type', item.value)}
                    label={t.order_payment_method}
                />
            </FormGroup>
            <FormGroup>
                <TextInput
                    value={values.payment_code}
                    onChangeText={handleChange('payment_code')}
                    placeholder={
                        values.payment_type === 'balance'
                            ? t.payer_user_id
                            : t.four_last_digits_of_card
                    }
                    label={values.payment_type === 'balance' ? t.user_id : t.four_last_digits_of_card}
                    error={errors.payment_code}
                    onBlur={handleBlur('payment_code')}
                    touched={touched.payment_code}
                    topLabel
                    required
                />
            </FormGroup>
            <FormGroup>
                <TextInput
                    value={values.payer_email}
                    onChangeText={handleChange('payer_email')}
                    type="email"
                    placeholder={t.buyer_email}
                    label={t.buyer_email}
                    error={errors.payer_email}
                    onBlur={handleBlur('payer_email')}
                    touched={touched.payer_email}
                    topLabel
                    required
                />
            </FormGroup>
            <FormGroup>
                <MaskedTextInput
                    value={values.payer_phone}
                    onChangeText={handlePhoneChange}
                    type="text"
                    placeholder={t.buyer_phone}
                    label={t.buyer_phone}
                    error={errors.payer_phone}
                    onBlur={handleBlur('payer_phone')}
                    touched={touched.payer_phone}
                    mask={config.envConfigs.phoneMask}
                    required
                    topLabel
                />
            </FormGroup>

            {values.payment_type === 'card' && (
                <FormGroup>
                    <TextInput
                        value={values.refund_payment_code}
                        onChangeText={handleChange('refund_payment_code')}
                        placeholder={t.requisite_of_account_to_return}
                        label={t.requisite_of_account}
                        error={errors.refund_payment_code}
                        onBlur={handleBlur('refund_payment_code')}
                        touched={touched.refund_payment_code}
                        topLabel
                        required
                    />
                </FormGroup>
            )}

            <PassengersTitle>{t.passengers}</PassengersTitle>

            {values.passengers.map((value, index) => {
                return (
                    <PassengerItem key={index}>
                        {index > 0 && (
                            <PassengerRemoveBlock>
                                <PassengerRemoveButton onClick={() => handleRemovePassenger(index)}>
                                    {t.remove}
                                </PassengerRemoveButton>
                            </PassengerRemoveBlock>
                        )}

                        <PassengerFormInput>
                            <TextInput
                                value={value.name}
                                onChangeText={handleChange(`passengers[${index}].name`)}
                                placeholder={t.passenger_name_and_surname}
                                label={t.passenger_name_and_surname}
                                error={errors?.passengers?.length > 0 && errors.passengers[index]?.name}
                                onBlur={handleBlur(`passengers[${index}].name`)}
                                touched={touched?.passengers?.length > 0 && touched.passengers[index]?.name}
                                topLabel
                                required
                            />
                        </PassengerFormInput>

                        <PassengerFormInput>
                            <TextInput
                                value={value.passport_number}
                                onChangeText={handleChange(`passengers[${index}].passport_number`)}
                                placeholder={t.passport_serial_and_number}
                                label={t.passport_serial_and_number}
                                error={errors?.passengers?.length > 0 && errors.passengers[index]?.passport_number}
                                onBlur={handleBlur(`passengers[${index}].passport_number`)}
                                touched={touched?.passengers?.length > 0 && touched.passengers[index]?.passport_number}
                                topLabel
                                required
                            />
                        </PassengerFormInput>

                        <PassengerFormInput>
                            <FileInput
                                onChange={e =>
                                    setFieldValue(`passengers[${index}].passport_copy`, e.currentTarget.files[0])
                                }
                                label={t.passport_copy}
                                error={errors?.passengers?.length > 0 && errors.passengers[index]?.passport_copy}
                                onBlur={handleBlur(`passengers[${index}].passport_copy`)}
                                touched={touched?.passengers?.length > 0 && touched.passengers[index]?.passport_copy}
                                type="file"
                                topLabel
                                required
                            />
                        </PassengerFormInput>

                        {index === values.passengers.length - 1 && index < 8 && (
                            <PassengerAddButtonBlock>
                                <PassengerAddButton onClick={handleAddPassenger}>+ {t.add}</PassengerAddButton>
                            </PassengerAddButtonBlock>
                        )}
                    </PassengerItem>
                );
            })}

            <SubmitButton type="submit" onClick={handleSubmit}>
                {isLoading ? <CircularProgress color="inherit" size={26} /> : t.send}
            </SubmitButton>
        </Container>
    );
};

export default RefundOrderPage;
