import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setCurrency } from '../../../../../../../../store/appConfigs/actions';
import useLocalization from '../../../../../../../../contexts/localization/hook';
import defaultCurrencies from '../../../../../../../../config/defaultCurrencies';

import { Component, Button, Items, Item, Text, TextLanguage, CheckboxBlock, Checkbox } from './components';
import Dropdown from '../../../../../../ui/dropdown';

import { ReactComponent as CaretIcon } from '../../../../../../../../assets/icons/newDesign/caretIcon.svg';
import { useStyles } from '../../../styles';

const SelectCurrencies = () => {
    const [isActive, setIsActive] = useState(false);
    const styles = useStyles();
    const dispatch = useDispatch();
    const { availableCurrencies, currency } = useSelector(state => state.appConfigs);
    const { language } = useLocalization();
    const handleSelectCurrency = useCallback(currency => {
        localStorage.setItem('currency', currency);
        dispatch(setCurrency(currency));
        setIsActive(false);
    }, []);

    const currencies = useMemo(() => {
        return availableCurrencies.map((value, index) => {
            return (
                <Item key={`currency-${value}`} onClick={() => handleSelectCurrency(value)}>
                    <Text>{value}</Text>
                    <TextLanguage>{defaultCurrencies[language][value]}</TextLanguage>

                    <CheckboxBlock>
                        <Checkbox className={value === currency && 'active'} />
                    </CheckboxBlock>
                </Item>
            );
        });
    }, [availableCurrencies, currency, language]);
    return (
        <Component>
            <Button onClick={() => setIsActive(true)}>
                <span className={styles.textCurrency}>{currency}</span>
                <span className={isActive ? styles.caretIcon : null}>
                    <CaretIcon />
                </span>
            </Button>
            <Dropdown isActive={isActive} onClickOutside={() => setIsActive(false)}>
                <Items>{currencies}</Items>
            </Dropdown>
        </Component>
    );
};

export default SelectCurrencies;
