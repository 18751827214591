import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Input from '@material-ui/core/Input';

const BootstrapInput = withStyles(theme => ({
    root: {
        color: '#212121',
        height: '52px',
        borderRadius: 4,
        border: '1px solid #cfd8dc',
        backgroundColor: '#ffffff',
    },
    input: {
        padding: '12px 0 12px 14px',

        '&:focus': {
            backgroundColor: '#ffffff',
        },
        '&:hover': {
            backgroundColor: '#ffffff',
        },
    },
}))(Input);

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: '#ffccbc',
        },
        '&:hover': {
            backgroundColor: '#fbe9e7',
        },
        '&:active': {
            backgroundColor: '#fbe9e7',
        },
    },
}))(MenuItem);

const Arrow = () => {
    return <ArrowDropDownIcon />;
};

export default function SelectUI(props) {
    const { onChange, items, selectedValue, label, widthSelect, widthDropdown } = props;
    const [value, setValue] = useState(selectedValue.value);

    useEffect(() => {
        setValue(selectedValue?.value);
    }, [selectedValue]);

    const handelOnChange = e => {
        const newValue = e.target.value;

        if (newValue !== value) {
            onChange(newValue);
            setValue(newValue);
        }
    };

    return (
        <div>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                value={value}
                IconComponent={Arrow}
                style={{
                    width: widthSelect || '275px',
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                onChange={e => handelOnChange(e)}
                input={<BootstrapInput disableUnderline />}
            >
                {items.map(option => (
                    <StyledMenuItem
                        style={{
                            width: widthDropdown || widthSelect || '275px',
                        }}
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </StyledMenuItem>
                ))}
            </Select>
        </div>
    );
}
