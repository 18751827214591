import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 64px 0;

    @media (max-width: 959px) {
        margin: 32px 0 64px;
    }
`;

export const Title = styled.h1`
    text-align: center;
    color: #404b5a;
`;

export const Description = styled.h4`
    text-align: center;
    color: #404b5a;
`;
