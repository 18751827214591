import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import useLocalization from '../../../../contexts/localization/hook';

const useStyles = makeStyles({
    helpTitle: {
        fontSize: '24px',
        fontWeight: '500',
        color: '#000000',
    },
    helpItem: {
        color: '#212121',
        paddingTop: '8px',
        fontSize: '16px',
        cursor: 'pointer',
    },
    helpLink: {
        color: '#ff7043',
        fontSize: '16px',
        cursor: 'pointer',
    },
    helpArrow: {
        paddingLeft: '2px',
        position: 'relative',
        top: '8px',
        cursor: 'pointer',
    },
});

export default function HelpBlock() {
    const classes = useStyles();
    const { t } = useLocalization();

    if (true) {
        // Отключаем отображение до соответвующих доработок на беке
        return null;
    }

    return (
        <div className={classes.helpBlock}>
            <div className={classes.helpTitle}>{t.help}</div>
            <div className={classes.helpItem}>{t.exchange_and_return_rules}</div>
            <div className={classes.helpItem}>{t.after_flight}</div>
            <div className={classes.helpLink}>
                <span>{t.go_to_section}</span>
                <span className={classes.helpArrow}>
                    <ArrowRightAltIcon />
                </span>
            </div>
        </div>
    );
}
