import React from 'react';
import { useStyles } from './styles';
import { checkInProgressStatus, getColorStatus, getBookStatus } from '../../../../components/result/common';
import useLocalization from '../../../../contexts/localization/hook';

export const BookingStatus = props => {
    const classes = useStyles();
    const { bookingStatus, orderStatus, timelimit } = props;

    const isInProgress = checkInProgressStatus(bookingStatus, orderStatus);
    const statusColor = getColorStatus(bookingStatus);

    return (
        <div className={classes.orderStatusBlock}>
            <div className={classes.circle} style={{ backgroundColor: statusColor }} />
            {isInProgress ? (
                <InProgressTimelimit timelimit={timelimit} statusColor={statusColor} />
            ) : (
                <SomeStatusBlock bookingStatus={bookingStatus} orderStatus={orderStatus} statusColor={statusColor} />
            )}
        </div>
    );
};

const InProgressTimelimit = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { statusColor, timelimit } = props;

    return (
        <div className={classes.statusString}>
            <span className={classes.statusStringBlock} style={{ color: statusColor }}>
                {timelimit < 0 ? t.time_expired : <LeftTimelimitTime timelimitSeconds={timelimit} />}
            </span>
        </div>
    );
};

const SomeStatusBlock = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { bookingStatus, orderStatus, statusColor } = props;

    return (
        <div className={classes.statusString}>
            <span className={classes.statusStringBlock} style={{ color: statusColor }}>
                {getBookStatus(bookingStatus, orderStatus, t)}
            </span>
        </div>
    );
};

const LeftTimelimitTime = props => {
    const { t } = useLocalization();
    const { timelimitSeconds } = props;

    return (
        <span>
            {t.left} {GetTimeLimitString(timelimitSeconds)}
        </span>
    );
};

const GetTimeLimitString = timelimitSecond => {
    const classes = useStyles();
    const { t } = useLocalization();

    if (timelimitSecond < 60) {
        return <span>{t.less_than_minute}</span>;
    }

    const minutes = Math.floor((timelimitSecond / 60) % 60);
    const hours = Math.floor((timelimitSecond / (60 * 60)) % 24);
    const days = Math.floor(timelimitSecond / (60 * 60 * 24));

    return (
        <span>
            {days > 0 && (
                <span className={classes.span}>
                    {days} {t.day_short}
                </span>
            )}
            {hours > 0 && (
                <span className={classes.span}>
                    {hours} {t.hour_short}
                </span>
            )}
            {minutes > 0 && (
                <span className={classes.span}>
                    {minutes} {t.minutes_short_3}
                </span>
            )}
        </span>
    );
};
