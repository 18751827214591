import styled from 'styled-components';

export const Container = styled.div`
    display: ${props => (props.active ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    margin-top: 8px;
    left: 0;
    width: 286px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    z-index: 9;
    border-radius: 12px;

    @media (max-width: 1279px) {
        width: 440px;
    }

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const Item = styled.div`
    padding: ${props => (props.isMain ? '12px 16px 12px 12px' : '12px 16px 12px 32px')};
    cursor: pointer;
    font-size: 0.875rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-last-of-type(1) {
        border-bottom: none;
        border-radius: 0 0 10px 10px;
    }

    &:hover {
        background-color: #eaf5ff;
    }
`;

export const Name = styled.div`
    font-weight: bold;
`;

export const Country = styled.span`
    color: #90a4ae;
`;

export const Code = styled.div`
    text-transform: uppercase;
    line-height: 1;
    color: #90a4ae;
`;
