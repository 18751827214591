import React from 'react';
import { ADT, CHD, INS, INF } from '../../containers/search/constants';
import { getPassTypeCountString } from '../result/common';
import _ from 'lodash';
import { useStyles } from '../../containers/booking/styles';
import { normalizePrice } from '../../lib/normalize';
import useLocalization from '../../contexts/localization/hook';

export default function PassengersAmountBlock(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const { passengers, currency } = props;

    const adtCount = _.filter(passengers, function (o) {
        return o.type === ADT;
    }).length;
    const chdCount = _.filter(passengers, function (o) {
        return o.type === CHD;
    }).length;
    const insCount = _.filter(passengers, function (o) {
        return o.type === INS;
    }).length;
    const infCount = _.filter(passengers, function (o) {
        return o.type === INF;
    }).length;

    let adtAmount = 0;
    let chdAmount = 0;
    let insAmount = 0;
    let infAmount = 0;

    if (adtCount > 0) {
        adtAmount = _.find(passengers, function (o) {
            return o.type === ADT;
        }).total_price;
    }

    if (chdCount > 0) {
        chdAmount = _.find(passengers, function (o) {
            return o.type === CHD;
        }).total_price;
    }

    if (insCount > 0) {
        insAmount = _.find(passengers, function (o) {
            return o.type === INS;
        }).total_price;
    }

    if (infCount > 0) {
        infAmount = _.find(passengers, function (o) {
            return o.type === INF;
        }).total_price;
    }

    if (_.isNull(adtAmount)) {
        return null;
    }

    return (
        <div className={classes.passBlock}>
            <div className={classes.passItem}>
                <span component="span" className={classes.passAmountTitle}>
                    {adtCount > 1 && adtCount} {getPassTypeCountString(adtCount, ADT, t)}
                </span>
                <span component="span" className={classes.passAmountValue}>
                    {normalizePrice(adtAmount)} {currency} {adtCount > 1 && 'х' + adtCount}
                </span>
            </div>

            {chdCount > 0 && (
                <div className={classes.passItem}>
                    <span component="span" className={classes.passAmountTitle}>
                        {chdCount > 1 && chdCount} {getPassTypeCountString(chdCount, CHD, t)}
                    </span>
                    <span component="span" className={classes.passAmountValue}>
                        {normalizePrice(chdAmount)} {currency} {chdCount > 1 && 'х' + chdCount}
                    </span>
                </div>
            )}

            {insCount > 0 && (
                <div className={classes.passItem}>
                    <span component="span" className={classes.passAmountTitle}>
                        {insCount > 1 && insCount} {getPassTypeCountString(insCount, INS, t)} (
                        {t.inf_with_seat_second_label})
                    </span>
                    <span component="span" className={classes.passAmountValue}>
                        {normalizePrice(insAmount)} {currency} {insCount > 1 && 'х' + insCount}
                    </span>
                </div>
            )}

            {infCount > 0 && (
                <div className={classes.passItem}>
                    <span component="span" className={classes.passAmountTitle}>
                        {infCount > 1 && infCount} {getPassTypeCountString(infCount, INF, t)}
                    </span>
                    <span component="span" className={classes.passAmountValue}>
                        {normalizePrice(infAmount)} {currency} {infCount > 1 && 'х' + infCount}
                    </span>
                </div>
            )}
        </div>
    );
}
