import _ from 'lodash';

import {
    byTransfers,
    byAirlines,
    byFlightNumbers,
    byAirports,
    byDepartureTime,
    byArrivalTime,
    byDuration,
    byPrice,
    byBaggage,
    byPricesTable,
} from './filters';
import { BY_MIN_PRICE, BY_MAX_PRICE } from '../../store/resultData/constants';

export const getFilterRecommendations = (filterParams, recommendations, currency) => {
    const {
        transfers,
        baggage,
        airlines,
        duration,
        airports,
        departureTime,
        arrivalTime,
        flightNumbers,
        price,
        pricesTable,
    } = filterParams;

    let recList = recommendations;

    if (transfers.isActive) {
        recList = byTransfers(recommendations, transfers.values);
    }

    if (airlines.isActive) {
        recList = byAirlines(recList, airlines.values);
    }

    if (flightNumbers.isActive) {
        recList = byFlightNumbers(recList, flightNumbers.values);
    }

    if (airports.isActive) {
        recList = byAirports(recList, airports.values);
    }

    if (departureTime.isActive) {
        recList = byDepartureTime(recList, departureTime.values);
    }

    if (arrivalTime.isActive) {
        recList = byArrivalTime(recList, arrivalTime.values);
    }

    if (duration.isActive) {
        recList = byDuration(recList, duration.values);
    }

    if (price.isActive) {
        recList = byPrice(recList, price.values, currency);
    }

    if (baggage.isActive) {
        recList = byBaggage(recList, baggage.values);
    }

    if (pricesTable.isActive) {
        recList = byPricesTable(recList, pricesTable.values, currency);
    }

    return recList;
};

export const getSortingRecommendations = (filterParams, recommendations, currency) => {
    const { sorting } = filterParams;

    switch (sorting.value) {
        case BY_MIN_PRICE:
            return sortByMinPrice(recommendations, currency);
        case BY_MAX_PRICE:
            return sortByMaxPrice(recommendations, currency);
        default:
            return recommendations;
    }
};

export const sortByMinPrice = (recommendations, currency) => {
    return _.sortBy(recommendations, currency, [
        function (rec) {
            return rec['total_price'][currency];
        },
    ]);
};

export const sortByMaxPrice = (recommendations, currency) => {
    return _.sortBy(recommendations, currency, [
        function (rec) {
            return rec['total_price'][currency];
        },
    ]).reverse();
};

export const getPriceRange = (recommendations, currency) => {
    const sortingRecommendations = sortByMinPrice(recommendations, currency);

    return {
        min: Math.floor(_.first(sortingRecommendations).total_price[currency]),
        max: Math.ceil(_.last(sortingRecommendations).total_price[currency]),
    };
};
