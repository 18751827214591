import React from 'react';
import _ from 'lodash';
import { NP, PS, PSP, SR } from '../../containers/booking/constants';
import SelectFormUI from '../ui/components/SelectFormUI/SelectFormUI';
import useLocalization from '../../contexts/localization/hook';

export default function DocumentTypeField(props) {
    const { t } = useLocalization();
    const { onChangeValue, availableDocumentTypes, input } = props;

    const allDocuments = [
        { value: PS, label: t.russian_passport },
        { value: SR, label: t.birth_certificate },
        { value: PSP, label: t.international_passport },
        { value: NP, label: t.foreign_document },
    ];

    const items = _.map(availableDocumentTypes, function (key) {
        return _.find(allDocuments, function (o) {
            return o.value === key;
        });
    });

    return (
        <SelectFormUI
            input={input}
            items={items}
            width="100%"
            height="auto"
            label={t.document}
            onClick={value => onChangeValue(value.target, props)}
        />
    );
}
