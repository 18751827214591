export default [
    {
        code: 'DYU',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Душанбе',
            en: 'Dushanbe',
            tj: 'Душанбе',
        },
        isMain: 1,
    },
    {
        code: 'LBD',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Худжанд',
            en: 'Khudzhand',
            tj: 'Хуҷанд',
        },
        isMain: 1,
    },
    {
        code: 'KQT',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Бохтар',
            en: 'Bokhtar',
            tj: 'Бохтар',
        },
        isMain: 1,
    },
    {
        code: 'TJU',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Куляб',
            en: 'Kulyab',
            tj: 'Кӯлоб',
        },
        isMain: 1,
    },
    {
        code: 'MOW',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Москва',
            en: 'Moscow',
            tj: 'Маскав',
        },
        isMain: 1,
    },
    {
        code: 'SVO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Шереметьево',
            en: 'Sheremetyevo International',
            tj: 'Sheremetyevo International',
        },
        isMain: 0,
    },
    {
        code: 'VKO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Внуково',
            en: 'Vnukovo International',
            tj: 'Vnukovo International',
        },
        isMain: 0,
    },
    {
        code: 'DME',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Домодедово',
            en: 'Domodedovo',
            tj: 'Domodedovo',
        },
        isMain: 0,
    },
    {
        code: 'ALA',
        countryCode: 'KZ',
        countryName: {
            ru: 'Казахстан',
            en: 'Kazakhstan',
            tj: 'Қазоқистон',
        },
        value: {
            ru: 'Алматы',
            en: 'Almaty',
            tj: 'Almaty',
        },
        isMain: 1,
    },
    {
        code: 'IST',
        countryCode: 'TR',
        countryName: {
            ru: 'Турция',
            en: 'Turkey',
            tj: 'Туркия',
        },
        value: {
            ru: 'Стамбул',
            en: 'Istanbul',
            tj: 'Istanbul',
        },
        isMain: 1,
    },
];

export const defaultFromCities = [
    {
        code: 'MOW',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Москва',
            en: 'Moscow',
            tj: 'Маскав',
        },
        isMain: 1,
    },
    {
        code: 'SVO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Шереметьево',
            en: 'Sheremetyevo International',
            tj: 'Sheremetyevo International',
        },
        isMain: 0,
    },
    {
        code: 'VKO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Внуково',
            en: 'Vnukovo International',
            tj: 'Vnukovo International',
        },
        isMain: 0,
    },
    {
        code: 'DME',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Домодедово',
            en: 'Domodedovo',
            tj: 'Domodedovo',
        },
        isMain: 0,
    },
    {
        code: 'LED',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Санкт-Петербург',
            en: 'St Petersburg',
            tj: 'St Petersburg',
        },
        isMain: 1,
    },
    {
        code: 'KZN',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Казань',
            en: 'Kazan',
            tj: 'Kazan',
        },
        isMain: 1,
    },
    {
        code: 'OVB',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Новосибирск',
            en: 'Novosibirsk',
            tj: 'Novosibirsk',
        },
        isMain: 1,
    },
    {
        code: 'VOZ',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Воронеж',
            en: 'Voronezh',
            tj: 'Voronezh',
        },
        isMain: 1,
    },
    {
        code: 'KUF',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Самара',
            en: 'Samara',
            tj: 'Samara',
        },
        isMain: 1,
    },
    {
        code: 'KJA',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Красноярск',
            en: 'Krasnojarsk',
            tj: 'Krasnojarsk',
        },
        isMain: 1,
    },
];

export const defaultToCities = [
    {
        code: 'IST',
        countryCode: 'TR',
        countryName: {
            en: 'Turkey',
            ru: 'Турция',
            tj: 'Туркия',
        },
        value: {
            ru: 'Стамбул',
            en: 'Istanbul',
            tj: 'Истанбул',
        },
        isMain: 1,
    },
    {
        code: 'SAW',
        countryCode: 'TR',
        countryName: {
            en: 'Turkey',
            ru: 'Турция',
            tj: 'Туркия',
        },
        value: {
            en: 'Sabiha Gokcen',
            ru: 'Сабиха-Гокен',
            tj: 'Sabiha Gokcen',
        },
        isMain: 0,
    },
    {
        code: 'AYT',
        countryCode: 'TR',
        countryName: {
            en: 'Turkey',
            ru: 'Турция',
            tj: 'Туркия',
        },
        value: {
            en: 'Antalya',
            ru: 'Анталья',
            tj: 'Antalya',
        },
        isMain: 1,
    },
    {
        code: 'DXB',
        countryCode: 'AE',
        countryName: {
            en: 'United Arab Emirates',
            ru: 'Объединенные Арабские Эмираты',
            tj: 'Аморати Муттаҳидаи Араб',
        },
        value: {
            en: 'Dubai',
            ru: 'Дубай',
            tj: 'Dubai',
        },
        isMain: 1,
    },
    {
        code: 'AUH',
        countryCode: 'AE',
        countryName: {
            en: 'United Arab Emirates',
            ru: 'Объединенные Арабские Эмираты',
            tj: 'Аморати Муттаҳидаи Араб',
        },
        value: {
            en: 'Abu Dhabi',
            ru: 'Абу-Даби',
            tj: 'Abu Dhabi',
        },
        isMain: 1,
    },
    {
        code: 'GYD',
        countryCode: 'AZ',
        countryName: {
            en: 'Azerbaijan',
            ru: 'Азербайджанская Республика',
            tj: 'Озарбойҷон',
        },
        value: {
            ru: 'Баку',
            en: 'Baku',
            tj: 'Baku',
        },
        isMain: 1,
    },
    {
        code: 'TAS',
        countryCode: 'UZ',
        countryName: {
            en: 'Uzbekistan',
            ru: 'Узбекистан',
            tj: 'Ӯзбекистон',
        },
        value: {
            ru: 'Ташкент',
            en: 'Tashkent',
            tj: 'Tashkent',
        },
        isMain: 1,
    },
    {
        code: 'ALA',
        countryCode: 'KZ',
        countryName: {
            en: 'Kazakhstan',
            ru: 'Казахстан',
            tj: 'Қазоқистон',
        },
        value: {
            en: 'Almaty',
            ru: 'Алматы',
            tj: 'Almaty',
        },
        isMain: 1,
    },
];
