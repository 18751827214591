import styled from 'styled-components';

export const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 599px) {
        font-size: 18px;
    }
`;
export const ButtonContainer = styled.div`
    width: 100%;
`;
export const ID = styled.h3``;
