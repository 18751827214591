import styled from 'styled-components';

export const BrandFareInfoItem = styled.div`
    padding-top: 6px;
    font-size: 16px;
    color: ${props => (props.color ? '#212121' : '#b9c1c5')};
`;

export const BrandOptionIcon = styled.div`
    display: inline-block;
`;

export const BrandOptionTitle = styled.div`
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin-left: 6px;
`;
