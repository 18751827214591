import styled from 'styled-components';

export const Wrapper = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    top: calc(100% + ${props => props.topMargin});
    right: ${props => props.position === 'right' ? 0 : 'auto'};
    left: ${props => props.position === 'left' ? 0 : 'auto'};
    background-color: #fff;
    padding: ${props => props.padding};
    border-radius: 12px;
    width: ${props => props.width};
    box-shadow: ${props => props.boxShadow};
    z-index: 10;
`;
