import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalization from '../../../../../../contexts/localization/hook';
import { setFilterParams } from '../../../../../../store/filters/actions';
import FormCheckbox from '../../../../../ui/components/FormCheckbox/FormCheckbox';
import FilterBlock from '../filterBlock';
import { CheckboxBlock, Label } from './components';

const TransfersFilter = () => {
    const { t } = useLocalization();
    const dispatch = useDispatch();
    const { filterParams } = useSelector(state => state.filters);
    const { transfers } = filterParams;
    const { values } = transfers;

    const handleChange = (value, type) => {
        const filters = {
            ...values,
            [type]: value,
        };

        dispatch(
            setFilterParams({
                type: 'transfers',
                values: {
                    isActive: !filters.withoutTransfers || !filters.oneTransfer || !filters.manyTransfers,
                    values: filters,
                },
            })
        );
    };

    return (
        <FilterBlock title={t.transfers} isOpen borderBottom>
            <CheckboxBlock>
                <FormCheckbox
                    onSelect={value => handleChange(value, 'withoutTransfers')}
                    isChecked={values.withoutTransfers}
                />
                <Label>{t.without_transfers}</Label>
            </CheckboxBlock>

            <CheckboxBlock>
                <FormCheckbox onSelect={value => handleChange(value, 'oneTransfer')} isChecked={values.oneTransfer} />
                <Label>{t.one_transfer}</Label>
            </CheckboxBlock>

            <CheckboxBlock>
                <FormCheckbox
                    onSelect={value => handleChange(value, 'manyTransfers')}
                    isChecked={values.manyTransfers}
                />
                <Label>{t.many_transfer}</Label>
            </CheckboxBlock>
        </FilterBlock>
    );
};

export default TransfersFilter;
