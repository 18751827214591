import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as IconsTime } from '../../../../assets/icons/clock-circular-outline.svg';
import useLocalization from '../../../../contexts/localization/hook';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
    localTimeTextContainer: {
        marginTop: 8,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,

        '@media(max-width: 599px)': {
            width: '100%',
        },
    },
    localTimeTextBlock: {
        fontSize: '16px',
        color: '#9EB3C7',
        bottom: '17px',

        '@media(max-width: 599px)': {
            fontSize: '12px',
            margin: '0 auto',
        },
    },
    cicularIconTime: {
        marginRight: '4px',
    },
    bottomLine: {
        borderBottom: '1px solid #C9C9C9',
    },
});

export default function LocalTimeText() {
    const classes = useStyles();
    const { t } = useLocalization();

    return (
        <>
            <div className={classes.localTimeTextContainer}>
                <div className={classes.localTimeTextBlock}>
                    <IconsTime className={classes.cicularIconTime} />
                    {t.everywhere_local_time}
                </div>
            </div>
            <Divider />
        </>
    );
}
