import {
    Form as MainForm,
    FormTitle as Title,
    FormGroup as Group,
    FormSubmitButton as SubmitButton,
} from '../../components/design_v2/authPages/components';
import styled from 'styled-components';

export const Form = styled(MainForm)``;

export const FormTitle = styled(Title)`
    font-size: 40px;
    margin: 24px 0;
`;

export const FormGroup = styled(Group)``;

export const FormSubmitButton = styled(SubmitButton)``;
