import React, { useState } from 'react';
import { useStyles } from './styles';
import { downloadMk } from '../../../../components/order/bookData';
import { normalizePrice } from '../../../../lib/normalize';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CancelIcon from '@material-ui/icons/Cancel';
import ReplayIcon from '@material-ui/icons/Replay';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {
    checkAllowedMK,
    checkIsAllowedRefund,
    checkInProgressStatus,
    FormattedDateTimeWithoutYear,
    checkIsAllowedCancel,
    getDayMonthYearFormat,
} from '../../../../components/result/common';
import Grid from '@material-ui/core/Grid';
import ButtonUI from '../../../../components/ui/components/ButtonUI/ButtonUI';
import clsx from 'clsx';
import RoutesInfo from '../routesInfo/index';
import { BookingStatus } from '../smallComponents/index';
import _ from 'lodash';
import useLocalization from '../../../../contexts/localization/hook';

const Order = props => {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const { order, setOrderRedirect, orderCancel, setAlert, setOpenAlert, orderRefund, setShowActionButtons } = props;
    const {
        order_id,
        routes,
        included,
        amount,
        currency,
        timestamp,
        booking_status,
        timelimit,
        order_status,
        session_id,
        showActionButtons,
        client_name,
        email,
        key,
    } = order;
    const [isLoadingMk, setIsLoadingMk] = useState(false);
    const [isLoadingCancel, setIsLoadingCancel] = useState(false);
    const [isLoadingReturn, setIsLoadingReturn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const setShowAction = () => {
        setShowActionButtons(order_id, !showActionButtons);
    };

    const getDepartureTimeArray = (routes, language) => {
        return _.map(routes, function (route) {
            const firstSegment = _.first(route.segments);

            if (firstSegment) {
                return getDayMonthYearFormat(firstSegment.departure.time, language);
            }
        });
    };

    const departureTimeArray = getDepartureTimeArray(routes, language);

    const handelDownloadMk = () => {
        setIsLoading(true);
        setIsLoadingMk(true);
        downloadMk(order_id, session_id, setSuccess);
    };

    const handelOrderCancel = () => {
        setIsLoading(true);
        setIsLoadingCancel(true);
        orderCancel(order_id, session_id, key, setSuccessCancel);
    };

    const handelOrderReturn = () => {
        setIsLoading(true);
        setIsLoadingReturn(true);
        orderRefund(order_id, setSuccessRefund);
    };

    const setSuccess = () => {
        setIsLoading(false);
        setIsLoadingMk(false);
        setIsLoadingCancel(false);
        setIsLoadingReturn(false);
    };

    const isAllowedMK = checkAllowedMK(booking_status);
    const isAllowedRefund = checkIsAllowedRefund(booking_status);
    const isAllowedPay = checkInProgressStatus(booking_status, order_status);
    const isAllowedCancel = checkIsAllowedCancel(booking_status, order_status);

    const setSuccessCancel = (status = true) => {
        setSuccess();

        setAlert({
            severity: status ? 'success' : 'error',
            message: status ? t.order_cancel_successfully_mess : t.order_cancel_error_mess,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setOpenAlert();
    };

    const setSuccessApprovedPaymentWithBalance = (status = true) => {
        setSuccess();

        setAlert({
            severity: status ? 'success' : 'error',
            message: status ? t.approved_balance_request_successfully : t.error_approved_balance_request_successfully,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setOpenAlert();
    };

    const setSuccessRefund = (status = true) => {
        setSuccess();

        setAlert({
            severity: status ? 'success' : 'error',
            message: status ? t.return_request_created : t.error_creating_refund_request,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setOpenAlert();
    };

    const getSeparator = routes => {
        const count = routes.length;

        if (count === 0) return null;

        const departureSegment = _.first(_.first(routes).segments);
        const arrivalSegment = _.last(_.last(routes).segments);

        if (_.isUndefined(departureSegment) || _.isUndefined(arrivalSegment)) {
            return ', ';
        }

        const departureAirport = departureSegment.departure.airport;
        const arrivalAirport = arrivalSegment.arrival.airport;

        if (count === 2 && departureAirport === arrivalAirport) {
            return ' - ';
        }

        return ', ';
    };

    const separator = getSeparator(routes);

    return (
        <div className={classes.orderContainer}>
            <Grid container className={classes.orderBlock}>
                <Grid item className={clsx(classes.orderMainBlock, classes.cursorPointer)}>
                    <Grid container onClick={() => setOrderRedirect(order_id)}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Grid container direction={'column'}>
                                <Grid item className={classes.oderIdBlock}>
                                    {t.order} {order_id}
                                </Grid>
                                <Grid item className={classes.timestampBlock}>
                                    {t.created} {FormattedDateTimeWithoutYear(timestamp, language)}
                                </Grid>
                                <Grid item className={classes.timestampBlock}>
                                    {t.client}: {client_name}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={5}>
                            <Grid container direction={'column'}>
                                <Grid item className={classes.routesBlock}>
                                    <RoutesInfo routes={routes} included={included} />
                                </Grid>
                                <Grid item className={classes.departuresBlock}>
                                    {departureTimeArray.join(separator)}
                                </Grid>
                                <Grid item className={classes.timestampBlock}>
                                    {email}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Grid
                                container
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                                height={'100%'}
                                className={classes.amountContainer}
                            >
                                <Grid item className={classes.amountBlock}>
                                    {amount && `${normalizePrice(amount)} ${currency}`}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.orderMainBlock}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid
                            item
                            className={clsx(classes.statusBlock, classes.cursorPointer)}
                            onClick={() => setOrderRedirect(order_id)}
                            xs={12}
                            sm={8}
                        >
                            <BookingStatus
                                bookingStatus={booking_status}
                                orderStatus={order_status}
                                timelimit={timelimit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.orderSubmitBlock}>
                                <ButtonUI
                                    onClick={setShowAction}
                                    title={t.action}
                                    endIcon={showActionButtons ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    fontSize="12px"
                                    height="32px"
                                />
                            </div>
                        </Grid>
                        {showActionButtons && (
                            <Grid item className={classes.actionButtonContainer}>
                                <div className={classes.actionButtonBlock}>
                                    <div className={classes.actionSubmitBlock}>
                                        <ButtonUI
                                            onClick={() => setOrderRedirect(order_id)}
                                            title={t.to_pay}
                                            endIcon={<AddShoppingCartIcon />}
                                            fontSize="12px"
                                            height="32px"
                                            isGreen={true}
                                            disabled={isLoading || !isAllowedPay || timelimit < 0}
                                        />
                                    </div>
                                    <div className={classes.actionSubmitBlock}>
                                        <ButtonUI
                                            onClick={handelOrderCancel}
                                            title={t.cancel}
                                            endIcon={<CancelIcon />}
                                            fontSize="12px"
                                            height="32px"
                                            disabled={isLoading || !isAllowedCancel}
                                            isLoading={isLoadingCancel}
                                        />
                                    </div>
                                    {/* <div className={ classes.actionSubmitBlock }>
                                        <ButtonUI
                                            onClick={ handelOrderReturn }
                                            title={ t.refund_button_title }
                                            endIcon={ <ReplayIcon/>}
                                            fontSize='12px'
                                            height='32px'
                                            disabled={ isLoading || !isAllowedRefund || !isRoleForRefund()}
                                            isLoading={ isLoadingReturn }
                                        />
                                    </div> */}
                                    <div className={classes.actionSubmitBlock}>
                                        <ButtonUI
                                            onClick={handelDownloadMk}
                                            title={t.itinerary_receipt}
                                            endIcon={<ReceiptIcon />}
                                            fontSize="12px"
                                            height="32px"
                                            disabled={isLoading || !isAllowedMK}
                                            isLoading={isLoadingMk}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Order;
