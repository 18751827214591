import React from 'react';

import { Container, Line } from './components';
import Passengers from './parts/passengers';
import Classes from './parts/classes';

const PassengersAndClassDropdown = props => {
    const { dWidth } = props;
    return (
        <Container dWidth={dWidth}>
            <Passengers />
            <Line />
            <Classes />
        </Container>
    );
};

export default PassengersAndClassDropdown;
