import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useLocalization from '../../../../../contexts/localization/hook';
import { OW, RT, CF, ADT, CHD, INS, INF } from '../../../../../containers/search/constants';
import _ from 'lodash';
import moment from 'moment';
import noun from 'plural-ru';
import { Block, Text, RoutesTextBlock, RouteText, RouteIcon } from './components';
import { ReactComponent as OneWayIcon } from '../../../../../assets/icons/newDesign/oneWayIcon.svg';
import { ReactComponent as RoundTripIcon } from '../../../../../assets/icons/newDesign/roundTripIcon.svg';

const SearchData = () => {
    const { searchData } = useSelector(state => state.loading);
    const { t, language } = useLocalization();

    const directionItems = [
        { value: OW, label: t.one_way },
        { value: RT, label: t.two_way },
        { value: CF, label: t.multi_way },
    ];

    const flightClassItems = [
        { value: 'economy', label: t.economy },
        { value: 'business', label: t.business },
        { value: 'first', label: t.first },
        { value: 'all', label: t.all_class },
    ];

    const getPassTypeCountString = useCallback((count, type) => {
        switch (type) {
            case ADT:
                return noun(count, t.one_adt, t.more_adt, t.many_adt);
            case CHD:
                return noun(count, t.one_chd, t.more_chd, t.many_chd);
            case INS:
            case INF:
                return noun(count, t.one_inf, t.more_inf, t.many_inf);
        }
    }, []);

    const getPassengerInfo = useCallback(() => {
        const { passengers } = searchData;

        let passengersString = `${passengers.adt} ${getPassTypeCountString(passengers.adt, ADT).toLowerCase()}`;

        if (passengers.chd > 0) {
            passengersString = `${passengersString}, ${passengers.chd} ${getPassTypeCountString(
                passengers.chd,
                CHD
            ).toLowerCase()}`;
        }

        if (passengers.ins > 0) {
            passengersString = `${passengersString}, ${passengers.ins} ${getPassTypeCountString(
                passengers.ins,
                INS
            ).toLowerCase()} (${t.inf_with_seat_second_label})`;
        }

        if (passengers.inf > 0) {
            passengersString = `${passengersString}, ${passengers.inf} ${getPassTypeCountString(
                passengers.inf,
                INF
            ).toLowerCase()}`;
        }

        return passengersString;
    }, [searchData]);

    const renderRoutes = useCallback(() => {
        const { flightType, routes } = searchData;
        const firstRoute = _.first(routes);
        const lastRoute = _.last(routes);

        if (flightType === CF) {
            return (
                <RoutesTextBlock>
                    <RouteText>{t.looking_tickets}</RouteText>
                </RoutesTextBlock>
            );
        }

        if (flightType === RT) {
            if (lastRoute.date_from) {
                return (
                    <RoutesTextBlock>
                        <RouteText>{firstRoute.from.value[language]}</RouteText>

                        <RouteIcon verticalAlign="text-bottom">
                            <RoundTripIcon />
                        </RouteIcon>

                        <RouteText>{firstRoute.to.value[language]}</RouteText>
                    </RoutesTextBlock>
                );
            }
        }

        return (
            <RoutesTextBlock>
                <RouteText>{firstRoute.from.value[language]}</RouteText>

                <RouteIcon verticalAlign="text-top">
                    <OneWayIcon />
                </RouteIcon>

                <RouteText>{firstRoute.to.value[language]}</RouteText>
            </RoutesTextBlock>
        );
    }, [searchData]);

    const getFlightTypeData = (flightType, lastRoute) => {
        if (flightType === RT && !lastRoute.date_from) {
            return _.find(directionItems, { value: OW });
        }

        return _.find(directionItems, { value: flightType });
    };

    const renderFlightTypeAndDates = useCallback(() => {
        const { flightType, routes } = searchData;
        const firstRoute = _.first(routes);
        const lastRoute = _.last(routes);
        const flightTypeData = getFlightTypeData(flightType, lastRoute);

        if (flightType === CF) {
            return (
                <Text>
                    {flightTypeData.label}
                    {routes.map(value => value.date_from && `, ${moment(value.date_from).format('DD.MM.YYYY')}`)}
                </Text>
            );
        }

        if (flightType === RT) {
            if (lastRoute.date_from) {
                return (
                    <Text>
                        {flightTypeData.label},{' '}
                        {routes.map((value, index) => {
                            if (index === 1) {
                                return ` — ${moment(value.date_from).format('DD.MM.YYYY')}`;
                            }

                            return `${moment(value.date_from).format('DD.MM.YYYY')}`;
                        })}
                    </Text>
                );
            }
        }

        return (
            <Text>
                {flightTypeData.label}, {moment(firstRoute.date_from).format('DD.MM.YYYY')}
            </Text>
        );
    }, [searchData]);

    const renderPassengers = useCallback(() => {
        return <Text>{getPassengerInfo()}</Text>;
    }, [searchData]);

    const renderCabin = useCallback(() => {
        const cabinTypeData = _.find(flightClassItems, { value: searchData.cabin });

        return <Text>{cabinTypeData.label}</Text>;
    }, [searchData]);

    return (
        <Block>
            {renderRoutes()}
            {renderFlightTypeAndDates()}
            {renderPassengers()}
            {renderCabin()}
        </Block>
    );
};

export default SearchData;
