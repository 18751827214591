import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setCabin } from '../../../../../../../store/searchParams/actions';
import { Block, Row, Checkbox, Text } from './components';
import useLocalization from '../../../../../../../contexts/localization/hook';

const ClassesBlock = () => {
    const { t } = useLocalization();
    const { cabin } = useSelector(state => state.searchParams);
    const dispatch = useDispatch();

    const flightClassItems = [
        { value: 'economy', label: t.economy },
        { value: 'business', label: t.business },
        { value: 'first', label: t.first },
        { value: 'all', label: t.all_class },
    ];

    const handleOnClick = value => {
        dispatch(setCabin(value));
    };

    return (
        <Block>
            {flightClassItems.map(item => {
                return (
                    <Row key={`class-item-${item.value}`} onClick={() => handleOnClick(item.value)}>
                        <Text className={item.value === cabin && 'active'}>{item.label}</Text>
                        <Checkbox className={item.value === cabin && 'active'} />
                    </Row>
                );
            })}
        </Block>
    );
};

export default ClassesBlock;
