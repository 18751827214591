export const REQUIRED = 'required';
export const INVALID_EMAIL = 'invalid_email';
export const INVALID_PASSWORD = 'invalid_password';
export const REQUIRE_PASSWORD_FIELD = 'password_required_enter';
export const PASSWORD_CONFIRM_ERROR = 'password_confirm_error';

export const ONLY_A_Z_SPACE = 'only_a_z_space';
export const ONLY_INTEGER = 'only_integer';
export const ONLY_A_Z = 'only_a_z';
export const INVALID_PHONE = 'invalid_phone';
export const CODES_MATCH = 'codes_match';
export const MIN_2_SYMBOL = 'min_2_symbol';
export const MIN_3_SYMBOL = 'min_3_symbol';
export const SELECT_VALUE_FROM_LIST = 'select_value_from_list';
export const CHECK_DATE = 'check_date';
export const INCORRECT_NUMBER = 'incorrect_number';
export const INCORRECT_CVC = 'incorrect_cvc';
export const INCORRECT_CARD_HOLDER = 'incorrect_card_holder';
export const INCORRECT_CARD_MONTH = 'incorrect_card_month';
export const INCORRECT_CARD_YEAR = 'incorrect_card_year';
export const INCORRECT_DATE_FROM_ADT = 'incorrect_date_from_adt';
export const INCORRECT_DATE_FROM_CHD = 'incorrect_date_from_chd';
export const INCORRECT_DATE_FROM_INF = 'incorrect_date_from_inf';
export const INCORRECT_DATE = 'incorrect_date';
export const EXPIRED_DATE = 'expired_date';
export const CHECK_DATE_AND_CHANGE_DOC = 'check_date_and_change_doc';
export const WARNING_INF_DATE = 'warning_inf_date';
export const PS_SHOULD_BE_10_DIGITS = 'ps_should_be_10_digits';
export const PSP_SHOULD_BE_9_DIGITS = 'psp_should_be_9_digits';
export const INCORRECT_DOC_NUMBER = 'incorrect_doc_number';
export const HINT_FOR_PS_DOC = 'hint_for_ps_doc';
export const HINT_FOR_PSP_DOC = 'hint_for_psp_doc';
export const HINT_FOR_SR_DOC = 'hint_for_sr_doc';
export const HINT_FOR_NP_DOC = 'hint_for_np_doc';
export const INVALID_VALUE = 'invalid_value';
export const IMAGE_TOO_LARGE = 'image_too_large';
export const IMAGE_RESOLUTION_TOO_HIGH = 'image_resolution_too_high';
export const IMAGE_RESOLUTION_TOO_SMALL = 'image_resolution_too_small';
export const RUB = 'RUB';
export const USD = 'USD';
export const TJS = 'TJS';
export const SESSION = 'Session';
