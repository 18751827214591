import React from 'react';
import { useStyles } from './styles';
import ScrollUpButton from 'react-scroll-up-button';
import Grid from '@material-ui/core/Grid';
import KeyboardCapslockIcon from '@material-ui/icons/KeyboardCapslock';
import Pagination from '../../../../components/ui/components/Pagination/Pagination';
import useLocalization from '../../../../contexts/localization/hook';

const PaginationBlock = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { changePage, countOrdersLimit, totalRecords, currentPage } = props;

    return (
        <Grid container justifyContent={'space-between'}>
            <div className={classes.paginationItemsBlock}>
                <div className={classes.paginationInline}>
                    <Pagination
                        totalRecords={totalRecords}
                        pageLimit={countOrdersLimit}
                        pageNeighbours={1}
                        onPageChanged={changePage}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            {totalRecords ? (
                <ScrollUpButton ContainerClassName={classes.paginationButtonBlock}>
                    <span style={{ lineHeight: '1' }}>
                        <KeyboardCapslockIcon />
                    </span>
                    <span>{t.to_top}</span>
                </ScrollUpButton>
            ) : null}
        </Grid>
    );
};

export default PaginationBlock;
