import styled from 'styled-components';
import arrowRight from '../../../../../assets/icons/arrowRight.svg';

export const RewievBlock = styled.div`
    border-radius: 12px;
    width: 100%;
    box-shadow: 0 10px 10px 1px rgba(0, 59, 110, 0.1);
    background-color: #fff;
    padding: 16px 0 32px;
`;
export const HeaderBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Header = styled.div`
    font-size: 18px;
    color: #404b5a;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 15px;
`;

export const Icon = styled.img`
    cursor: pointer;
`;

export const IconLeft = styled(Icon)`
    transform: 'rotateY(180deg)';
`;

export const IconRight = styled(Icon)`
    transform: rotate(180deg);
`;

export const ArrowIcons = styled.div`
    margin-right: 15px;
    margin-top: 15px;
`;
export const BodyBlock = styled.div``;

export const Rewievs = styled.div`
    display: flex;
    margin-top: 2rem;
    padding: 0 20px;
`;

export const Avatar = styled.img`
    width: 94px;
    height: 94px;
    border-radius: 50%;

    @media (max-width: 599px) {
        margin-bottom: 16px;
    }
`;
export const Client = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
`;
export const Name = styled.div`
    color: #33558b;
    font-size: 18px;
    font-weight: bold;
`;
export const Message = styled.div`
    font-size: 14px;
    color: #33558b;
    font-weight: light;
    line-height: 1.4;
`;

export const Block = styled.div`
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;

    @media (max-width: 599px) {
        flex-direction: column;
    }
`;
