import { SET_TRANSACTIONS_ITEMS, SET_TRANSACTIONS_FILTERS, SET_IS_LOADING } from './constants';
import initialState from './initialState';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSACTIONS_ITEMS:
            return {
                ...state,
                transactionsItems: action.payload,
            };
        case SET_TRANSACTIONS_FILTERS:
            return {
                ...state,
                transactionsFilters: { ...state.transactionsFilters, ...action.payload },
            };
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};
