import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LogoImage = styled.img`
    display: block;
    max-height: 72px;
    cursor: pointer;
    margin-right: 125px;

    @media (max-width: 1279px) {
        max-height: 64px;
        margin-right: 32px;
    }

    @media (max-width: 599px) {
        max-height: 48px;
    }
`;

export const IataLogoImage = styled.img`
    display: block;
    max-height: 72px;
    margin-right: 32px;

    @media (max-width: 1279px) {
        max-height: 42px;
        margin-right: 24px;
    }

    @media (max-width: 959px) {
        display: none;
    }
`;
export const TKPLogoImage = styled.img`
    display: block;
    max-height: 52px;
    margin-right: 32px;
    margin-top: 14px;

    @media (max-width: 1279px) {
        max-height: 42px;
        margin-right: 24px;
    }

    @media (max-width: 959px) {
        display: none;
    }
`;
