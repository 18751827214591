import { SET_APP_CONFIGS, SET_CURRENCY } from './constants';
import initialState from './initialState';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_CONFIGS:
            return {
                ...state,
                ...action.payload,
            };
        case SET_CURRENCY:
            return {
                ...state,
                currency: action.payload,
            };
        default:
            return state;
    }
};
