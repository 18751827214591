import React, { useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ModalComponent from '../../design_v2/ui/modal';
import useLocalization from '../../../contexts/localization/hook';
import { ButtonBlock, Paragraph, StyledButton } from './components';

const CovidRules = () => {
    const { language } = useLocalization();
    const [modalShow, setModalShow] = useState(false);
    const titleText =
        language == 'en'
            ? 'Covid-19 and flight rules'
            : language == 'ru'
            ? 'Covid-19 и правила перелетов'
            : 'Covid 19 ва қоидаҳои парвоз';

    const getText = () => {
        return (
            <>
                {language == 'ru' && (
                    <>
                        <Paragraph>
                            Ситуация сложная: из-за пандемии коронавируса отменяют рейсы, вводят ограничения на прилёт и
                            вылет или совсем закрывают границы.
                        </Paragraph>
                        <Paragraph>
                            При использовании нашего сайта мы настоятельно просим вас ознакомиться с требованием по
                            возврату авиабилета на сайтах авиаперевозчика, а также в источниках авиационных властей мест
                            пребывания, а также транзитных пунктов.
                        </Paragraph>
                        <Paragraph>
                            Просим вас четко понимать, что данный сайт не является авиаперевозчиком, а только агентом по
                            реализации авиабилетов. Все манипуляции (возвраты, переоформления) с заказами производятся
                            по четким инструкциям и УПТ авиаперевозчиков. Просим вас ознакомиться с УПТ на выбранном
                            участке перелета, перед покупкой.
                        </Paragraph>
                    </>
                )}
                {language == 'en' && (
                    <>
                        <Paragraph>
                            The situation is complicated: flights are canceled due to the coronavirus pandemic,
                            restrictions on arrivals and departures are introduced or they completely close the borders.
                        </Paragraph>
                        <Paragraph>
                            When using our website, we strongly ask you to familiarize yourself with the requirement for
                            the return of the ticket on the air carrier's websites, as well as in the sources of the
                            aviation authorities of the places of stay, as well as transit points.
                        </Paragraph>
                        <Paragraph>
                            We ask you to clearly understand that this site is not an air carrier, but only an agent for
                            the sale of air tickets. All manipulations (refunds, reissues) with orders are carried out
                            according to clear instructions and UPT of air carriers. We ask you to familiarize yourself
                            with the UPT on the selected section of the flight, before buying.
                        </Paragraph>
                    </>
                )}
                {language == 'tj' && (
                    <>
                        <Paragraph>
                            Вазъият душвор аст: бар асари пандемияи коронавирус парвозҳо лағв карда шуданд, барои омадан
                            ва рафтан маҳдудиятҳо ҷорӣ карда шуданд. ё сархадхоро тамоман баста.
                        </Paragraph>
                        <Paragraph>
                            Ҳангоми истифодаи сайти мо, мо аз шумо хоҳиш мекунем, ки дархости баргардониданро баррасӣ
                            кунед чиптаи ҳавопаймо дар вебсайтҳои интиқолдиҳанда, инчунин дар манбаъҳои мақомоти
                            авиатсионии ҷойҳои будубош, инчунин пунктхои транзитй.
                        </Paragraph>
                        <Paragraph>
                            Мо аз шумо хоҳиш мекунем, ки ба таври возеҳ бифаҳмед, ки ин сайт як интиқолдиҳандаи ҳавоӣ
                            нест, балки танҳо агенти фурӯш аст. чиптаҳои ҳавопаймо. Ҳама амалҳо (баргардонидан, бозпас
                            додан) бо фармоишҳо мувофиқи дастурҳои возеҳ ва анҷом дода мешаванд UPT интиқолдиҳандагони
                            ҳавоӣ. Мо аз шумо хоҳиш мекунем, ки пеш аз харид кардан бо UPT дар қисмати интихобшудаи
                            парвоз шинос шавед.
                        </Paragraph>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <ButtonBlock>
                <StyledButton
                    variant={'outlined'}
                    onClick={() => setModalShow(true)}
                    endIcon={<InfoOutlinedIcon style={{ fontSize: '16px' }} />}
                >
                    {titleText}
                </StyledButton>
            </ButtonBlock>

            <ModalComponent
                active={modalShow}
                onClose={() => setModalShow(false)}
                title={titleText}
                maxWidth={600}
                fixedWidth
            >
                {getText()}
            </ModalComponent>
        </>
    );
};

export default CovidRules;
