import React from 'react';
import PropTypes from 'prop-types';
import UI from '../../ui';
import { ErrorsFieldBlock, FormGroup } from '../../../../styles/common/index';
import TooltipUI from '../TooltipUI/TooltipUI';
import useLocalization from '../../../../contexts/localization/hook';

function FormField(props) {
    const { t } = useLocalization();
    const {
        label,
        name,
        type,
        meta: { touched, error },
        placeholder,
        disabled,
        input,
        fieldError,
        autoFocus,
        isPadding,
        widthSize,
        heightSize,
        onClick,
        tooltipTitle,
        defaultValue,
    } = props;

    return (
        <FormGroup widthSize={widthSize}>
            <UI.Label text={label} />

            <TooltipUI title={tooltipTitle}>
                <UI.Input
                    {...input}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    isPadding={isPadding}
                    widthSize={widthSize}
                    heightSize={heightSize}
                    autoComplete="off"
                    onClick={onClick}
                    defaultValue={defaultValue}
                    borderColor={fieldError || (touched && error) ? '#c62828' : ''}
                />
            </TooltipUI>

            <ErrorsFieldBlock>
                {touched && error && <UI.InputMessage text={t[error]} />}
                {fieldError && <UI.InputMessage text={fieldError} />}
            </ErrorsFieldBlock>
        </FormGroup>
    );
}

FormField.propTypes = {
    input: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    tooltipTitle: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    fieldError: PropTypes.string,
    defaultValue: PropTypes.string,
    meta: PropTypes.object,
    autoFocus: PropTypes.bool,
};

FormField.defaultProps = {
    disabled: null,
    label: null,
    name: null,
    placeholder: null,
    tooltipTitle: null,
    type: 'text',
    fieldError: null,
    defaultValue: '',
    autoFocus: false,
    onClick: () => {},
    meta: {
        touched: null,
        error: null,
        warning: null,
    },
};

export default FormField;
