import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';

import { setBrandFareRecId } from '../../../../../store/resultData/actions';
import useLocalization from '../../../../../contexts/localization/hook';

import LoadingBlock from '../../loadingBlock';
import TariffItem from '../desktop/tariffItem';
import { LoadingWrapper, TariffsBlock, TariffsText, TariffsWrapper } from './components';

const MobileTariffsBlock = props => {
    const { brandRecId, recId, getFareRules, brandName } = props;
    const { brandFares, brandRules } = useSelector(state => state.resultData);
    const { currency } = useSelector(state => state.appConfigs);
    const { t } = useLocalization();
    const dispatch = useDispatch();

    const Tariffs = () => {
        const checkTariff = value => {
            dispatch(setBrandFareRecId({ recId, brandRecId: value }));
        };

        return brandFares[recId].map((brandFare, index) => {
            let isChecked = false;

            if (brandRecId) {
                isChecked = brandFare.rec_id === brandRecId;
            } else {
                isChecked = brandFare.brand_name === brandName;
            }

            return (
                <TariffItem
                    key={index}
                    brandFare={brandFare}
                    isChecked={isChecked}
                    checkTariffs={checkTariff}
                    brandRules={brandRules}
                    handleGetFareRules={getFareRules}
                    currency={currency}
                />
            );
        });
    };

    if (brandFares[recId]) {
        return (
            <TariffsWrapper>
                <TariffsText>
                    <ErrorIcon fontSize="small" style={{ marginRight: '8px' }} />
                    {t.route_tariffs_message}
                </TariffsText>

                <TariffsBlock>
                    <Tariffs />
                </TariffsBlock>
            </TariffsWrapper>
        );
    }

    return (
        <LoadingWrapper>
            <LoadingBlock withoutIcon />
        </LoadingWrapper>
    );
};

export default MobileTariffsBlock;
