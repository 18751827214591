import React, { useRef, useEffect } from 'react';

import useTranslation from '../../../../../contexts/localization/hook';
import { Container, Item, Name, Country, Code } from './components';

const Dropdown = ({ isActive, items, onSelect, handleClose }) => {
    const node = useRef();
    const { language } = useTranslation();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }

        handleClose();
    };

    const renderItems = () => {
        return items.map(item => {
            if (item.isMain) {
                return (
                    <Item key={`item-${item.code}-${item.isMain}`} onClick={() => onSelect(item)} isMain>
                        <Name>
                            {item.value[language]}, <Country>{item.countryName[language]}</Country>
                        </Name>
                        <Code>{item.code}</Code>
                    </Item>
                );
            }

            return (
                <Item key={`item-${item.code}`} onClick={() => onSelect(item)}>
                    <Name>{item.value[language]}</Name>
                    <Code>{item.code}</Code>
                </Item>
            );
        });
    };

    return (
        <Container active={isActive} ref={node}>
            {renderItems()}
        </Container>
    );
};

export default Dropdown;
