import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    paginationItemsBlock: {
        cursor: 'pointer',
        display: 'inline-table',
        fontSize: '20px',
        fontWeight: '500',
        color: '#7e878b',
        paddingTop: '16px',
        paddingBottom: '32px',
    },
    paginationInline: {
        display: 'inline-table',
    },
    paginationButtonBlock: {
        cursor: 'pointer',
        color: '#7e878b',
        fontSize: '18px',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '16px',
        paddingBottom: '32px',

        '&:focus': {
            outline: 'none',
        },
    },
});
