import styled from 'styled-components';

export const Title = styled.div`
    font-size: 30px;
    color: #404b5a;
    font-weight: bold;
    margin: 30px 0;
    text-align: center;

    @media (max-width: 1279px) {
        font-size: 36px;
    }

    @media (max-width: 959px) {
        font-size: 24px;
    }
`;

export const TableBlock = styled.div`
    overflow-y: auto;
    margin-bottom: 24px;
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 16px;
    min-width: 550px;
    overflow-x: scroll;
`;

export const Tr = styled.tr`
    background-color: ${props => props.background || '#fff'};

    &:nth-child(even) {
        background-color: #f6f9ff;
    }
`;

export const Th = styled.th`
    border: 1px solid #707070;
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #404b5a;
    color: #fff;
`;

export const Td = styled.td`
    color: ${props => props.textColor || '#000'};
    border: 1px solid #707070;
    padding: 8px;
`;

export const NoResultsBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 20px;
    font-weight: bold;
    color: #404b5a;
`;

export const PaginationBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
`;
