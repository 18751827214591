import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import Search from '../search';
import useStyles from './styles';
import Logo from './parts/logo';
import Nav from './parts/nav';

const Header = () => {
    const { showHeader, showSearchBlock } = useSelector(store => store.appConfigs);
    const styles = useStyles();

    if (showHeader) {
        return (
            <>
                <header className={styles.component}>
                    <Container fixed className={styles.container}>
                        <Logo />
                        <Nav />
                    </Container>
                </header>

                {showSearchBlock && <Search />}
            </>
        );
    }

    return null;
};

export default Header;
