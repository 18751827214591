import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import useLocalization from '../../../../contexts/localization/hook';

const useStyles = makeStyles({
    linkContainerBackTo: {
        cursor: 'pointer',
        color: '#7e878b',
        fontSize: '16px',
    },
    linkBackButton: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    linkTextBackTo: {
        padding: '16px 0',
    },
    linkBackIcon: {
        width: '16px',
        paddingRight: '8px',
        display: 'flex',
        alignItems: 'center',
    },
});

export default function LinkBackTo(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const { onClick, title } = props;

    const titleLink = title ? title : t.back_to_orders;

    return (
        <div className={classes.linkContainerBackTo}>
            <div className={classes.linkBackButton} onClick={() => onClick()}>
                <div className={classes.linkBackIcon}>
                    <ArrowBackIosIcon fontSize="small" />
                </div>
                <div className={classes.linkTextBackTo}>{titleLink}</div>
            </div>
        </div>
    );
}

LinkBackTo.defaultProps = {
    title: null,
};
