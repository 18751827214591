import _ from 'lodash';
import moment from 'moment-timezone';
import {
    BALANCE_PAYMENT_APPROVED_CODE,
    BALANCE_PAYMENT_REQUEST_CODE,
    ORDER_FAIL_CODE,
} from '../containers/order/constants';
import configs from '../config';

const { webTransferTimeFormat, serverTimeZone, formatForGroupOrders } = configs.mainConfigs;
const formatDateTime = 'YYYY-MM-DD HH:mm:ss';

const formatResult = {
    userData(data) {
        return {
            clientName: data['client_name'],
            phone: data['phone'],
            email: data['email'],
            partnerName: data['partner_name'],
            partnerCurrency: data['partner_currency'],
            clientType: data['client_type'],
            ownFunds: parseFloat(data['own_funds']),
            creditLine: parseFloat(data['credit_line']),
            creditLimit: parseFloat(data['credit_limit']),
            otb: parseFloat(data['otb']),
            availableCurrencies: data['available_currencies'],
            partnerLogo: data['logo'],
        };
    },

    cities(data) {
        let result = [];
        for (let item of data) {
            result.push({
                code: item['item_code'],
                countryCode: item['country_code'],
                countryName: item['country'],
                value: item['item'],
                isMain: 1,
                haveAirports: item['airports'].length,
            });

            if (item['airports'].length > 1) {
                _.each(item['airports'], airport => {
                    result.push({
                        code: airport['airport_code'],
                        countryCode: item['country_code'],
                        countryName: item['country'],
                        value: airport['airport'],
                        isMain: 0,
                        haveAirports: 0,
                    });
                });
            }
        }
        return result;
    },

    searchResult(result) {
        const { supplier } = result.included;

        _.forEach(result['flights'], function (recommendation) {
            const supplierData = _.find(supplier, { iata: recommendation['validating_supplier'] });
            recommendation['lowcost'] = supplierData ? supplierData.lowcost : false;

            formattedRoutesData(recommendation.routes);
        });

        return result;
    },

    searchBrandFares(result) {
        _.forEach(result['flights'], function (recommendation) {
            formattedRoutesData(recommendation.routes);
        });

        return result;
    },

    orderData(order) {
        order['timestamp'] = formattedDateWithTimeZone(order['timestamp']);

        if (_.isNull(order['booking_id'])) {
            order['brokenOrder'] = true;
            order['order_status'] = ORDER_FAIL_CODE;
        } else {
            if (order['timelimit']) {
                const timelimitWithoutTz = order['timelimit'].split('+');
                const convertedUTCTime = moment.utc(timelimitWithoutTz[0]).tz(moment.tz.guess());
                const formatedTimelimitTime = formattedDateWithTimeZone(convertedUTCTime);
                const dateNow = moment();
                const momentTimelimit = moment(formatedTimelimitTime, webTransferTimeFormat).endOf('seconds');
                order['timelimit'] = momentTimelimit.diff(dateNow, 'seconds');
            } else {
                order['timelimit'] = -1;
            }

            order['status_at'] = formattedDateWithTimeZone(order['status_at']);

            if (order['order_status'] === BALANCE_PAYMENT_REQUEST_CODE) {
                order['booking_status'] = BALANCE_PAYMENT_REQUEST_CODE;
            }

            if (order['order_status'] === BALANCE_PAYMENT_APPROVED_CODE) {
                order['booking_status'] = BALANCE_PAYMENT_APPROVED_CODE;
            }

            if (_.isNull(order['airline_booking_number'])) {
                order['airline_booking_number'] = [];
            }

            _.forEach(order['routes'], function (route) {
                _.forEach(route['segments'], function (segment) {
                    segment['departure']['time'] = formattedDate(segment['departure']['time']);
                    segment['arrival']['time'] = formattedDate(segment['arrival']['time']);

                    let techStops = [];

                    _.map(segment['tech_stops'], function (techStop) {
                        const formatTime = 'YYYY-MMM-DD HH:mm:ss';
                        let cityCode = order.included.airport[techStop.airport_code].city;
                        let countryCode = order.included.airport[techStop.airport_code].country;
                        let momentObj = moment(techStop.arrival_at, formatTime);
                        const prevDateUnix = momentObj.unix();
                        const actualDateUnix = moment(techStop.departure_at, formatTime).unix();
                        const unixDifference = actualDateUnix - prevDateUnix;
                        const hours = Math.trunc(unixDifference / 3600);
                        const minutes = Math.floor((unixDifference - hours * 3600) / 60);

                        techStops = [
                            ...techStops,
                            {
                                city: {
                                    code: cityCode,
                                },
                                country: {
                                    code: countryCode,
                                },
                                duration: {
                                    hour: hours,
                                    minute: minutes,
                                },
                                arrival: {
                                    time: momentObj.format('HH:mm'),
                                },
                            },
                        ];
                    });
                    segment['tech_stops'] = techStops;
                });
            });
        }
        this.getChangeBaggage(order);
        return order;
    },

    orders(items) {
        const { data } = items;
        const orders = data.data;

        const groups = data.reduce((groups, orders) => {
            const date = moment(orders.timestamp, formatDateTime).format(formatForGroupOrders);

            orders['timestamp'] = formattedDateWithTimeZone(orders['timestamp']);
            orders['showActionButtons'] = false;

            if (_.isNull(orders['booking_id'])) {
                orders['brokenOrder'] = true;
                orders['order_status'] = ORDER_FAIL_CODE;
            } else {
                if (orders['timelimit']) {
                    const timelimitWithoutTz = orders['timelimit'].split('+');
                    const convertedUTCTime = moment.utc(timelimitWithoutTz[0]).tz(moment.tz.guess());
                    const formatedTimelimitTime = formattedDateWithTimeZone(convertedUTCTime);
                    const dateNow = moment();
                    const momentTimelimit = moment(formatedTimelimitTime, webTransferTimeFormat).endOf('seconds');
                    orders['timelimit'] = momentTimelimit.diff(dateNow, 'seconds');
                } else {
                    orders['timelimit'] = -1;
                }

                if (orders['order_status'] === BALANCE_PAYMENT_REQUEST_CODE) {
                    orders['booking_status'] = BALANCE_PAYMENT_REQUEST_CODE;
                }

                if (orders['order_status'] === BALANCE_PAYMENT_APPROVED_CODE) {
                    orders['booking_status'] = BALANCE_PAYMENT_APPROVED_CODE;
                }

                orders['included'] = items.included;

                _.forEach(orders['routes'], function (route) {
                    _.forEach(route['segments'], function (segment) {
                        segment['departure'] = {};
                        segment['arrival'] = {};
                        segment['departure']['time'] = formattedDate(segment['departure_at']);
                        segment['departure']['airport'] = formattedDate(segment['departure_airport_code']);
                        segment['departure']['city'] =
                            items.included.airport[segment['departure_airport_code']].city_code;

                        segment['arrival']['time'] = formattedDate(segment['arrival_at']);
                        segment['arrival']['airport'] = formattedDate(segment['arrival_airport_code']);
                        segment['arrival']['city'] = items.included.airport[segment['arrival_airport_code']].city_code;
                    });
                });
            }

            if (!groups[date]) {
                groups[date] = [];
            }

            groups[date].push(orders);

            return groups;
        }, {});

        items['data'] = Object.keys(groups).map(date => {
            return {
                sortDate: moment(date, formatForGroupOrders),
                orders: groups[date],
            };
        });

        return items;
    },

    passengersList(result) {
        _.forEach(result, function (passenger) {
            const dateOfBirthArray = passenger.date_of_birth.split('-');
            passenger['date_of_birth'] = dateOfBirthArray[2] + '.' + dateOfBirthArray[1] + '.' + dateOfBirthArray[0];

            _.forEach(passenger.params, function (item) {
                const expirationDate = item.expiration_date.split('-');
                item['expiration_date'] = expirationDate[2] + '.' + expirationDate[1] + '.' + expirationDate[0];

                item['citizenship'] = {
                    code: item['citizenship'],
                    name: item['citizenshipName'],
                };
            });
        });

        return result;
    },

    usersList(result) {
        return _.map(result, function (user) {
            return {
                clientName: user['client_name'],
                clientCode: user['client_code'],
                partnerCode: user['partner_code'],
                clientType: user['client_type'],
                email: user['email'],
                phone: user['phone'],
                isActive: !_.isEmpty(user['last_login']),
            };
        });
    },

    childPartnerList(result) {
        return _.map(result, function (user) {
            return {
                label: user['partner_name'],
                value: _.toString(user['partner_code']),
            };
        });
    },

    companiesList(result) {
        return _.map(result, function (company) {
            return {
                partnerName: company['partner_name'],
                partnerCode: _.toString(company['partner_code']),
                inn: company['inn'],
                kpp: company['kpp'],
                logo: company['logo'],
            };
        });
    },

    partnerData(data) {
        return {
            partnerName: data['partner_name'],
            inn: _.toString(data['inn']),
            kpp: _.toString(data['kpp']),
        };
    },

    paymentSystems(data) {
        return _.map(data, function (item) {
            return {
                id: item['id'],
                code: item['code'],
                logo: item['logo'],
                name: item['name'],
                description: JSON.parse(item['description']),
            };
        });
    },

    getChangeBaggage(data) {
        const currentData = { ...data };
        currentData.routes = formattedRoutesData(currentData.routes);
        return currentData;
    },
};

const formattedRoutesData = routes => {
    return _.forEach(routes, function (route) {
        _.forEach(route.segments, function (segment) {
            switch (segment['baggage']) {
                case _.isNull(segment['baggage']):
                    segment['baggage'] = 0;
                    break;
                case '0':
                case '0N':
                case '0 PC':
                case '0PC':
                case 'N/A':
                case 'NO':
                case '0 N/A':
                    segment['baggage'] = 0;
                    break;
                case '1':
                case '1P':
                case '1 PC':
                case '10K':
                case '10 KG':
                case '20 KG':
                case '23 KG':
                case '30 KG':
                case '35 KG':
                case '40 KG':
                case '1PC':
                    segment['baggage'] = 1;
                    break;
                case '2P':
                case '2 PC':
                    segment['baggage'] = 2;
                    break;
                default:
                    break;
            }
            switch (segment['hand_luggage']) {
                case _.isNull(segment['hand_luggage']):
                    segment['hand_luggage'] = 0;
                    break;
                case '0':
                case '0 PC':
                case '0PC':
                case 'N/A':
                case '0 N/A':
                    segment['hand_luggage'] = 0;
                    break;
                case '1':
                case '1P':
                case '1 PC':
                case '10K':
                case '5 KG':
                case '7 KG':
                case '8 KG':
                case '10 KG':
                case '1PC':
                    segment['hand_luggage'] = 1;
                    break;
                case '2':
                case '2P':
                    segment['hand_luggage'] = 2;
                    break;
                default:
                    break;
            }
            switch (segment['baggage_weight']) {
                case _.isNull(segment['baggage_weight']):
                    segment['baggage_weight'] = 0;
                    break;
                case '0 N/A':
                case 'N/A': segment['baggage_weight'] = 0; break;
                case '10 KG': segment['baggage_weight'] = 10; break;
                case '20 KG': segment['baggage_weight'] = 20; break;
                case '23 KG': segment['baggage_weight'] = 23; break;
                case '25 KG': segment['baggage_weight'] = 25; break;
                case '30 KG': segment['baggage_weight'] = 30; break;
                case '35 KG': segment['baggage_weight'] = 35; break;
                case '40 KG': segment['baggage_weight'] = 40; break;
            }
            switch (segment['hand_luggage_weight']) {
                case _.isNull(segment['hand_luggage_weight']):
                    segment['hand_luggage_weight'] = 0;
                    break;
                case '0 N/A':
                case '1 PC':
                case 'N/A': segment['hand_luggage_weight'] = 0; break;
                case '5 KG': segment['hand_luggage_weight'] = 5; break;
                case '7 KG': segment['hand_luggage_weight'] = 7; break;
                case '8 KG': segment['hand_luggage_weight'] = 8; break;
                case '10 KG': segment['hand_luggage_weight'] = 10; break;
            }
        });
    });
};

const formattedDateWithTimeZone = data => {
    const timeZoneName = moment.tz.guess();
    const serverMomentTime = moment.tz(data, serverTimeZone);

    return serverMomentTime.clone().tz(timeZoneName).format(webTransferTimeFormat);
};

const formattedDate = data => {
    return moment(data, formatDateTime).format(webTransferTimeFormat);
};

export { formatResult };
