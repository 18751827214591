import React, { useRef, useEffect, useState } from 'react';
import { ContactsBlock, Parent, Child, PhoneNumber } from './components';
import PhoneIcon from '@material-ui/icons/Phone';
import { useCallback } from 'react';
import envConfigs from '../../../../../../../../config/envConfigs';

const Contacts = () => {
    const [isActive, setIsActive] = useState(false);
    const contactsBlock = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleClickOnBlock = useCallback(() => {
        setIsActive(true);
    }, []);

    const handleClick = e => {
        if (contactsBlock.current.contains(e.target)) {
            return;
        }

        setIsActive(false);
    };

    return (
        <ContactsBlock className={isActive && 'active'} onClick={handleClickOnBlock} ref={contactsBlock}>
            <Parent>
                <Child>
                    <PhoneNumber href={`tel: ${envConfigs.supportPhone}`}>{envConfigs.supportPhone}</PhoneNumber>
                    <PhoneIcon style={{ marginLeft: '5px', color: '#00ddff', cursor: 'pointer' }} />
                </Child>
            </Parent>
        </ContactsBlock>
    );
};

export default Contacts;
