export const SET_CITIES = 'SET_CITIES';
export const SET_PASSENGERS = 'SET_PASSENGERS';
export const SET_ALL_PASSENGERS = 'SET_ALL_PASSENGERS';
export const SET_PASSENGERS_COUNT = 'SET_PASSENGERS_COUNT';
export const SET_PASSENGERS_MAX = 'SET_PASSENGERS_MAX';
export const SET_INF_MAX = 'SET_INF_MAX';
export const OW = 'OW';
export const RT = 'RT';
export const CF = 'CF';

export const ADT = 'adt';
export const CHD = 'chd';
export const INF = 'inf';
export const INS = 'ins';

export const PASSENGERS_LIST = [ADT, CHD, INS, INF];

export const PASSENGERS_MIN_ITEMS = {
    [ADT]: 1,
    [CHD]: 0,
    [INS]: 0,
    [INF]: 0,
};

export const LIMITS = 9;
