import React from 'react';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { ErrorsFieldBlock } from '../../../../styles/common/index';
import InputMessage from '../InputMessage/InputMessage';
import InputWarning from '../InputWarning/InputWarning';
import useLocalization from '../../../../contexts/localization/hook';

const useStyles = makeStyles({
    container: {
        flexWrap: 'wrap',
    },
    formControl: {
        position: 'relative',
        display: 'flex',
    },
    input: {
        width: '100%',
        height: 'auto',
        borderRadius: '12px',
        border: 'solid 1px #C3D6E8',
        paddingLeft: '16px',
        paddingTop: '24px',
        paddingBottom: '3px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
    },
    inputFocused: {
        outline: 'none',
        border: 'solid 1px #00ddff',
    },
    label: {
        fontSize: '12px',
        position: 'absolute',
        top: '10px',
        left: '16px',
        zIndex: 1,
        color: '#9da8ae',
    },
});

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
        />
    );
}

export default function DatePickerForm(props) {
    const { t } = useLocalization();
    const classes = useStyles();
    const {
        label,
        input,
        fieldError,
        warning,
        meta: { touched, error },
    } = props;

    let errorStyle = {};

    if (fieldError || (touched && error)) {
        errorStyle = {
            border: 'solid 2px #c62828',
        };
    }

    return (
        <FormControl className={classes.formControl}>
            <div className={classes.label}>
                <span>{label}</span>
            </div>

            <Input
                {...input}
                className={classes.input}
                autoComplete="off"
                inputComponent={TextMaskCustom}
                placeholder={t.placeholder_date_picker}
                disableUnderline={true}
                classes={{ focused: classes.inputFocused }}
                style={errorStyle}
            />

            <ErrorsFieldBlock>
                {touched && error && <InputMessage text={t[error]} />}
                {touched && !error && warning && <InputWarning text={t[warning]} />}
                {fieldError && <InputMessage text={fieldError} />}
            </ErrorsFieldBlock>
        </FormControl>
    );
}

DatePickerForm.defaultProps = {
    label: null,
};
