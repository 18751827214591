import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    buttonLoading: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '16%',
        left: '40%',
    },
});

function ButtonUI(props) {
    const classes = useStyles();
    const { title, fontSize, height, isGreen, endIcon, onClick, disabled, isLoading } = props;

    const CustomButton = withStyles({
        root: {
            height: height ? height : '40px',
            borderRadius: '12px',
            color: '#fff',
            cursor: 'pointer',
            fontSize: fontSize ? fontSize : '16px',
            fontWeight: 500,
            textTransform: 'none',
            backgroundColor: isGreen ? '#52BE00' : '#00ddff',
            '&:hover': {
                backgroundColor: isGreen ? '#449d48' : '#0d6dc5',
            },
            '&:active': {
                backgroundColor: isGreen ? '#3d8b40' : '#137dde',
            },
            '&:focus': {
                outline: 'none',
            },
            '&:disabled': {
                backgroundColor: '#cfd8dc',
                color: '#fff',
                cursor: 'default',
                borderColor: '#f2f4f5',
            },
        },
    })(Button);

    return (
        <div className={classes.buttonLoading}>
            <CustomButton variant="contained" disableRipple onClick={onClick} endIcon={endIcon} disabled={disabled}>
                {title}
            </CustomButton>
            {isLoading && (
                <CircularProgress style={{ color: '#ff7043' }} size={24} className={classes.buttonProgress} />
            )}
        </div>
    );
}

export default ButtonUI;
