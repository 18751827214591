import React from 'react';
import { useStyles } from './styles';
import { Container } from '@material-ui/core';
import useLocalization from '../../../../contexts/localization/hook';

const EmptyOrdersBox = () => {
    const classes = useStyles();
    const { t } = useLocalization();

    return (
        <Container className={classes.emptyOrdersBox}>
            <div className={classes.emptyOrdersBoxTitle}>
                <div>{t.no_orders_found}</div>
                <div>{t.not_found_recomm_by_filters}</div>
            </div>
        </Container>
    );
};

export default EmptyOrdersBox;
