import _ from 'lodash';
import {
    ALL_COUNTRY,
    ALL_DOCUMENTS_ITEMS,
    FRIENDLY_COUNTRY,
    NP,
    ONLY_RU_COUNTRY,
    PS,
    PSP,
    RU,
    SR,
} from '../../containers/booking/constants';
import { ADT } from '../../containers/search/constants';
import configs from '../../config';
import { HINT_FOR_NP_DOC, HINT_FOR_PS_DOC, HINT_FOR_PSP_DOC, HINT_FOR_SR_DOC } from '../../containers/common/constants';

const friendlyCountriesCodes = configs.mainConfigs.friendlyCountriesCodes;

export const withExpirationDate = documentType => {
    return documentType !== PS && documentType !== SR;
};

export const documentsForOnlyRu = passengerType => {
    if (passengerType === ADT) {
        return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
            return o !== NP;
        });
    } else {
        return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
            return o !== NP && o !== PS;
        });
    }
};

export const allDocumentsForOnlyRu = () => {
    return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
        return o !== NP;
    });
};

export const documentsForFriendlyCountries = passengerType => {
    if (passengerType === ADT) {
        return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
            return o !== NP && o !== SR;
        });
    } else {
        return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
            return o === PSP;
        });
    }
};

export const documentsForAllCountries = () => {
    return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
        return o === PSP;
    });
};

export const ruDocumentsForAllCitizenship = passengerType => {
    if (passengerType === ADT) {
        return ALL_DOCUMENTS_ITEMS;
    } else {
        return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
            return o !== PS;
        });
    }
};

export const friendlyDocumentsForAllCitizenship = passengerType => {
    if (passengerType === ADT) {
        return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
            return o !== SR;
        });
    } else {
        return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
            return o !== PS && o !== SR;
        });
    }
};

export const allDocumentsForAllCitizenship = () => {
    return _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
        return o !== PS && o !== SR;
    });
};

export const getFlightDocsType = routesCountries => {
    if (routesCountries.length === 1 && _.first(routesCountries) === RU) {
        return ONLY_RU_COUNTRY;
    }

    let isFriendly = true;

    _.forEach(routesCountries, function (item) {
        if (
            _.findIndex(friendlyCountriesCodes, function (o) {
                return o === item;
            }) === -1
        ) {
            isFriendly = false;
        }
    });

    return isFriendly ? FRIENDLY_COUNTRY : ALL_COUNTRY;
};

export const getRoutesCountries = (routes, included) => {
    let airportList = [];

    _.map(routes, function (route) {
        _.map(route.segments, function (segment) {
            airportList = [...airportList, segment.arrival.airport, segment.departure.airport];
        });
    });

    airportList = _.uniq(airportList);

    return _.uniq(
        _.map(airportList, function (item) {
            return included.airport[item].country;
        })
    );
};

export const hintForDocNumber = (documentType, t) => {
    switch (documentType) {
        case PS:
            return t[HINT_FOR_PS_DOC];
        case SR:
            return t[HINT_FOR_SR_DOC];
        case PSP:
            return t[HINT_FOR_PSP_DOC];
        default:
            return t[HINT_FOR_NP_DOC];
    }
};
