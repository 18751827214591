import React, { useCallback, useState, useEffect, useRef } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { Form, FormTitle, FormGroup, FormSubmitButton } from './components';
import TextInput from '../../components/design_v2/ui/textInput';

import API from '../../lib/api';
import useLocalization from '../../contexts/localization/hook';
import Alert from '../../components/design_v2/ui/alert';
import envConfigs from '../../config/envConfigs';

const ResetPassword = props => {
    const { isFullScreenSearchBlock, isAuth } = useSelector(state => state.appConfigs);

    const { language, t } = useLocalization();
    const { match: params } = props;
    const containerRef = useRef();

    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        password: Yup.string().trim().required(t.required),
        password_confirm: Yup.string().when('password', {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], t.password_confirm_error),
        }),
    });

    const handleRedirect = () => {
        history.push('/login');
    };

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, [isFullScreenSearchBlock]);

    useEffect(() => {
        document.title = `${t.page_reset_password} | ${envConfigs.appTitle}`;
    }, [language]);

    const handleKeyPress = useCallback(e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleSubmit();
        }
    }, []);

    const handleFormSubmit = useCallback(
        values => {
            if (isLoading) {
                return;
            }

            const { token } = params.params;
            let newValues = {
                ...values,
                token,
            };
            setIsLoading(true);

            API.postResetPassword(newValues)
                .then(response => {
                    setSuccess(true);
                    setIsLoading(false);
                })
                .catch(error => {
                    setFieldError('password', error.response.data.message);
                    setIsLoading(false);
                });
        },
        [isLoading]
    );

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik({
        initialValues: {
            password: '',
            password_confirm: '',
        },
        validationSchema,
        onSubmit: handleFormSubmit,
        validateOnBlur: true,
    });

    if (isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <Container fixed ref={containerRef}>
            <Form>
                <FormTitle>{t.enter_new_password}</FormTitle>
                {success && (
                    <Alert type="success" title={t.reset_success_title}>
                        {t.reset_success_content}
                    </Alert>
                )}
                {!success && success != null && (
                    <Alert type="error" title={t.reset_error_title}>
                        {t.reset_error_content}
                    </Alert>
                )}

                {!success && (
                    <>
                        <FormGroup error={errors.password}>
                            <TextInput
                                value={values.password}
                                onChangeText={handleChange('password')}
                                type="password"
                                required
                                placeholder={t.enter_password}
                                error={errors.password}
                                onBlur={handleBlur('password')}
                                touched={touched.password}
                            />
                        </FormGroup>

                        <FormGroup error={errors.password_confirm}>
                            <TextInput
                                value={values.password_confirm}
                                onChangeText={handleChange('password_confirm')}
                                type="password"
                                required
                                placeholder={t.enter_password_confirm}
                                error={errors.password_confirm}
                                onBlur={handleBlur('password_confirm')}
                                touched={touched.password_confirm}
                            />
                        </FormGroup>
                        <FormSubmitButton onClick={handleSubmit} type="submit">
                            {isLoading ? <CircularProgress color="inherit" size={26} /> : `${t.continue}`}
                        </FormSubmitButton>
                    </>
                )}
                {success && (
                    <FormSubmitButton onClick={handleRedirect} type="submit">
                        {t.log_in}
                    </FormSubmitButton>
                )}
            </Form>
        </Container>
    );
};

export default withRouter(ResetPassword);
