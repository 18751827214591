import React from 'react';
import useLocalization from '../../../../contexts/localization/hook';
import { NotFoundRecommFirstBlock, NotFoundRecommMargin, NotFoundRecommSecondBlock, Wrapper } from './components';

function NotFoundFlightsTitle({ byFilters = false }) {
    const { t } = useLocalization();

    const secondText = byFilters ? t.not_found_recomm_by_filters : t.not_found_recomm_second_text;

    return (
        <Wrapper>
            <NotFoundRecommMargin>
                <NotFoundRecommFirstBlock>{t.not_found_recomm_first_text}</NotFoundRecommFirstBlock>
                <NotFoundRecommSecondBlock>{secondText}</NotFoundRecommSecondBlock>
            </NotFoundRecommMargin>
        </Wrapper>
    );
}

export default NotFoundFlightsTitle;
