import { SET_FILTER_PARAMS, SET_ALL_FILTER_PARAMS, RESET_ALL_FILTER_PARAMS, SET_FILTERS_DATA } from './constants';

export const setFilterParams = data => dispatch =>
    dispatch({
        type: SET_FILTER_PARAMS,
        payload: data,
    });

export const setAllFilterParams = data => dispatch =>
    dispatch({
        type: SET_ALL_FILTER_PARAMS,
        payload: data,
    });

export const resetAllFilterParams = data => dispatch =>
    dispatch({
        type: RESET_ALL_FILTER_PARAMS,
        payload: data,
    });

export const setFiltersData = data => dispatch =>
    dispatch({
        type: SET_FILTERS_DATA,
        payload: data,
    });
