import React from 'react';

import useLocalization from '../../../../../contexts/localization/hook';

import { Container, Body, Line, Footer, Button } from './components';
import Passengers from './parts/passengers';
import Classes from './parts/classes';

const PassengersAndClassMobile = ({ onClose }) => {
    const { t } = useLocalization();
    return (
        <Container>
            <Body>
                <Passengers />
                <Line />
                <Classes />
            </Body>

            <Footer>
                <Button onClick={onClose}>{t.done}</Button>
            </Footer>
        </Container>
    );
};

export default PassengersAndClassMobile;
