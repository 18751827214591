import React, { useEffect, useState, useCallback } from 'react';

import { Button, ButtonIcon } from './components';

const ScrollUpButton = () => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = useCallback(
        e => {
            setActive(window.scrollY > 1000);
        },
        [setActive]
    );

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Button onClick={handleClick} className={active ? 'active' : null}>
            <ButtonIcon />
        </Button>
    );
};

export default ScrollUpButton;
