import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router';

import { setAppConfigs } from '../../store/appConfigs/actions';
import RegistrationPage from '../../pages/newRegistration';
import useLocalization from '../../contexts/localization/hook';
import envConfigs from '../../config/envConfigs';

const RegistrationContainer = () => {
    const { isAuth } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();
    const { language, t } = useLocalization();

    useEffect(() => {
        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    useEffect(() => {
        document.title = `${t.page_registration} | ${envConfigs.appTitle}`;
    }, [language]);

    if (isAuth) return <Redirect to="/" />;

    return <RegistrationPage />;
};

export default withRouter(RegistrationContainer);
