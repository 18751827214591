import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Main from '../../components/design_v2/main';
import useLocalization from '../../contexts/localization/hook';
import { setAppConfigs } from '../../store/appConfigs/actions';

const HomeContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { language, t } = useLocalization();

    useEffect(() => {
        dispatch(
            setAppConfigs({
                showSearchBlock: true,
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: true,
            })
        );

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location]);

    useEffect(() => {
        document.title = t.page_home;
    }, [language]);

    return <Main />;
};

export default HomeContainer;
