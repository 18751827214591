import React from 'react';

import { Block, IconBlock } from './components';
import { ReactComponent as LoadingCircularSmallIcon } from '../../../../assets/icons/newDesign/loadingCircularSmall.svg';

const Loading = () => {
    return (
        <Block>
            <IconBlock>
                <LoadingCircularSmallIcon />
            </IconBlock>
        </Block>
    );
};

export default Loading;
