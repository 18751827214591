import React from 'react';
import moment from 'moment';

import {
    AirlineLogo,
    SegmentArrivalBlock,
    SegmentArrivalInfo,
    SegmentArrivalTime,
    SegmentBlock,
    SegmentDate,
    SegmentDepartureBlock,
    SegmentDepartureInfo,
    SegmentDepartureTime,
    SegmentDuration,
    SegmentInfo,
    SegmentInfoText,
    SegmentsWrapper,
    SegmentTimes,
    TransferBetweenAirportWarning,
    TransferBlock,
    TransferTime,
} from './components';
import useLocalization from '../../../../contexts/localization/hook';
import {
    checkIsNightFlightAndIsChangeAirport,
    getCityAndAirportNames,
    getDurationTime,
    getServiceClass,
    getTransferTime,
} from '../utils';
import { getSupplierIcon } from '../../../../lib/getImage';
import { ReactComponent as TransferIcon } from '../../../../assets/icons/newDesign/transfer.svg';

const FlightDetails = ({ route, recommendation, included, routeIndex }) => {
    const { segments } = route;
    const { t, language } = useLocalization();

    const checkIfTransferAnotherAirport = () => {
        if (segments.length > 1) {
            for (let i = 1; i < segments.length; i++) {
                if (segments[i].departure.airport === segments[i - 1].arrival.airport) {
                    return (
                        <TransferBetweenAirportWarning>{t.no_transfers_between_airports}</TransferBetweenAirportWarning>
                    );
                }
            }
        }

        return null;
    };

    const parseDateAndTime = dateAndTime => {
        const date = dateAndTime.split(' ')[0];
        const time = dateAndTime.split(' ')[1];
        const formattedDate = `${date.split('.')[2]}-${date.split('.')[1]}-${date.split('.')[0]}`;
        const formattedTime = `${time.split(':')[0]}:${time.split(':')[1]}:00`;

        return `${formattedDate} ${formattedTime}`;
    };

    const renderFlightDetails = (segment, index) => {
        const { departure, service_class, arrival, carrier_number, aircraft, tech_stops, operation_supplier } = segment;
        const isTechStops = segment.tech_stops.length > 0;
        const isTransfer = index > 0;

        let aircraftName = aircraft;

        if (included.aircraft[aircraft]) {
            aircraftName = included.aircraft[aircraft].name.en;
        } else {
            aircraftName = '';
        }

        let transferTime = '';

        const [isChangeAirport, isNightTransfer] = checkIsNightFlightAndIsChangeAirport(
            recommendation.routes[routeIndex],
            index
        );

        if (isTransfer) {
            const actualRoute = recommendation.routes[routeIndex];

            transferTime = getTransferTime(actualRoute, index, t.hour_short, t.minutes_short_3);
        }

        const departureTime = moment(parseDateAndTime(departure.time));
        const arrivalTime = moment(parseDateAndTime(arrival.time));

        return (
            <SegmentBlock key={index}>
                {isTransfer && (
                    <TransferBlock>
                        <TransferIcon />
                        <TransferTime>
                            {t.transfer} {transferTime}
                            {isChangeAirport && `, ${t.change_airport}`}
                            {isNightTransfer && `, ${t.night_flight}`}
                        </TransferTime>
                    </TransferBlock>
                )}

                <SegmentDate>{departureTime.format('DD MMMM YYYY, dddd')}</SegmentDate>

                <SegmentInfo>
                    <AirlineLogo imgUrl={getSupplierIcon(operation_supplier)} />
                    <SegmentInfoText>
                        {included?.supplier[operation_supplier]?.name[language]}, {t.flight} {carrier_number},
                        {aircraftName ? ` ${aircraftName},` : ''} {getServiceClass(service_class, t.class)}
                    </SegmentInfoText>
                </SegmentInfo>

                <SegmentTimes>
                    <SegmentDepartureBlock>
                        <SegmentDepartureTime>
                            <span className="circle" />
                            <span className="line" />
                            <span className="text">{departureTime.format('HH:mm')}</span>
                        </SegmentDepartureTime>
                        <SegmentDepartureInfo>
                            {getCityAndAirportNames(included.city, included.airport, departure, language)}{' '}
                            <span>{departure.airport}</span>
                        </SegmentDepartureInfo>
                    </SegmentDepartureBlock>

                    <SegmentDuration>
                        <span className="line" />
                        <span className="onWay">
                            {t.on_way}{' '}
                            <span className="time">
                                {getDurationTime(segment.duration, t.hour_short, t.minutes_short_3)}
                                {isTechStops && ` (${t.teh_stop})`}
                            </span>
                        </span>
                    </SegmentDuration>

                    <SegmentArrivalBlock>
                        <SegmentArrivalTime>
                            <span className="line" />
                            <span className="circle" />
                            {!departureTime.isSame(arrivalTime, 'day') && (
                                <span className="otherDate">{arrivalTime.format('DD MMM, ddd').replace('.', '')}</span>
                            )}
                            <span className="text">{arrivalTime.format('HH:mm')}</span>
                        </SegmentArrivalTime>
                        <SegmentArrivalInfo hasMarginTop={departureTime.isSame(arrivalTime, 'day') ? 0 : 1}>
                            {getCityAndAirportNames(included.city, included.airport, arrival, language)}{' '}
                            <span>{arrival.airport}</span>
                        </SegmentArrivalInfo>
                    </SegmentArrivalBlock>
                </SegmentTimes>
            </SegmentBlock>
        );
    };

    return (
        <SegmentsWrapper>
            {checkIfTransferAnotherAirport()}
            {segments.map((segment, index) => renderFlightDetails(segment, index))}
        </SegmentsWrapper>
    );
};

export default FlightDetails;
