import { SET_CLIENT_DATA } from './constants';
import initialState from './initialState';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CLIENT_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
