export default [
    {
        code: 'RU',
        country: {
            ru: 'Российская Федерация (Россия)',
            en: 'Russian Federation',
            tj: 'Федератсияи Русия (Русия)',
        },
    },
    {
        code: 'KZ',
        country: {
            ru: 'Казахстан',
            en: 'Kazakhstan',
            tj: 'Қазоқистон',
        },
    },
    {
        code: 'TR',
        country: {
            ru: 'Турция',
            en: 'Turkey',
            tj: 'Туркия',
        },
    },
    {
        code: 'CN',
        country: {
            ru: 'Китай',
            en: 'China',
            tj: 'Хитой',
        },
    },
    {
        code: 'TJ',
        country: {
            ru: 'Республика Таджикистан',
            en: 'Tajikistan',
            tj: 'Ҷумҳурии Тоҷикистон',
        },
    },
    {
        code: 'UZ',
        country: {
            ru: 'Республика Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ҷумҳурии Ӯзбекистон',
        },
    },
    {
        code: 'KG',
        country: {
            ru: 'Кыргызская Республика',
            en: 'Kyrgyzstan',
            tj: 'Қирғизистон',
        },
    },
];
