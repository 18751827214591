import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;
    flex-wrap: wrap;
`;

export const RouteContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;

    @media (max-width: 1279px) {
        flex-wrap: wrap;
    }

    @media (max-width: 959px) {
        justify-content: flex-end;
    }
`;

export const From = styled.div`
    height: 60px;
    background-color: #00ddff;
    width: 286px;
    border-radius: 12px;
    margin-right: 8px;
    margin-bottom: 12px;
    position: relative;
    box-shadow: ${props => props.notFilled ? '0px 0px 4px 2px #ff0000' : 'none'};

    @media (max-width: 1279px) {
        width: 222px;
    }

    @media (max-width: 959px) {
        border-radius: 12px;
        width: 100%;
        margin-bottom: 8px;
        margin-right: 0;
    }
`;

export const To = styled.div`
    height: 60px;
    background-color: #00ddff;
    width: 286px;
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    border-radius: 12px;
    box-shadow: ${props => props.notFilled ? '0px 0px 4px 2px #ff0000' : 'none'};

    @media (max-width: 1279px) {
        width: 222px;
    }

    @media (max-width: 959px) {
        width: 100%;
        border-radius: 12px;
        margin-right: 0;
    }
`;

export const FromDate = styled.div`
    height: 60px;
    background-color: #00ddff;
    width: 400px;
    border-radius: 12px;
    margin-bottom: 12px;
    position: relative;
    margin-right: 8px;
    box-shadow: ${props => props.notFilled ? '0px 0px 4px 2px #ff0000' : 'none'};

    @media (max-width: 1279px) {
        width: 222px;
    }

    @media (max-width: 959px) {
        border-radius: 12px;
        width: 100%;
        margin-bottom: ${props => (props.marginBottom ? '40px' : '12px')};
        margin-right: 0;
    }
`;

export const PassengersAndClass = styled.div`
    position: relative;
    height: 60px;
    background-color: #00ddff;
    width: 236px;
    border-radius: 10px;

    @media (max-width: 1279px) {
        width: 222px;
    }

    @media (max-width: 959px) {
        width: 100%;
        border-radius: 12px;
        margin-bottom: 40px;
        margin-right: 0;
        position: unset;
    }
`;

export const AddRouteButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 959px) {
        width: 100%;
        margin-bottom: 16px;
    }
`;

export const AdvancedRouteAddRouteButtonContainer = styled(AddRouteButtonContainer)`
    @media (max-width: 404px) {
        margin-right: 0;
        display: flex;
        justify-content: center;
    }

    @media (max-width: 959px) {
        margin-right: 0;
        display: flex;
        justify-content: center;
    }
`;

export const AddRouteButton = styled.button`
    height: 60px;
    background-color: #52be00;
    color: #fff;
    border: none;
    border-radius: 12px;
    width: 236px;
    height: 60px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    float: right;

    @media (max-width: 1279px) {
        font-size: 1rem;
        margin-right: 22px;
        width: 218px;
    }

    @media (max-width: 959px) {
        width: 100%;
        font-size: 1rem;
        background-color: #e4e9f3;
        color: #404b5a;
    }
`;

export const AdvancedRouteAddRouteButton = styled(AddRouteButton)`
    @media (max-width: 1279px) {
        margin-right: 0;
        width: 222px;
    }

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const MaximumRoutesText = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    color: #707070;
    font-size: 0.9rem;

    width: 100%;
    height: 100%;

    @media (max-width: 959px) {
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
    }
`;

export const DesktopRemoveRouteButton = styled.button`
    background-color: #ff6663;
    color: #d60c0c;
    font-size: 14px;
    width: 236px;
    height: 60px;
    cursor: pointer;
    border-radius: 12px;
    background-color: #e4e9f3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    @media (max-width: 1279px) {
        width: 222px;
    }

    @media (max-width: 959px) {
        display: none;
        width: 222px;
    }
`;

export const RemoveRouteButton = styled.button`
    display: none;
    background-color: #ff6663;
    color: #d60c0c;
    font-size: 14px;
    width: 60px;
    height: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    background-color: transparent;
    border: none;

    @media (max-width: 959px) {
        display: flex;
        background-color: transparent;
    }
`;

export const SubmitButtonContainer = styled.div`
    width: 410px;

    @media (max-width: 1279px) {
        width: 303px;
    }

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const SubmitButton = styled.button`
    height: 60px;
    background-color: #ff6f32;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
`;
