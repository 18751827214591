import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Container,
    From,
    ReplaceRouteButton,
    ReplaceRouteButtonLeftIcon,
    ReplaceRouteButtonRightIcon,
    To,
    DateFromTo,
    PassengersAndClass,
} from './components';
import AirportField from '../../fields/airport';
import DateFromToField from '../../fields/dateFromTo';
import PassengersAndClassField from '../../fields/passengersAndClass';
import { setRoutes, setFlightType } from '../../../../../../store/searchParams/actions';
import { RT } from '../../../../../../containers/search/constants';
import { ReactComponent as ArrowLeftIcon } from '../../../../../../assets/icons/arrowLeftIcon.svg';

const SimpleRoute = () => {
    const { routes, notFilledFields } = useSelector(state => state.searchParams);
    const dispatch = useDispatch();

    const handleAirportFromSelect = value => {
        const currentRoutes = [...routes];
        currentRoutes[0].from = value;
        currentRoutes[1].to = value;
        dispatch(setRoutes(currentRoutes));
    };

    const handleAirportToSelect = value => {
        const currentRoutes = [...routes];
        currentRoutes[0].to = value;
        currentRoutes[1].from = value;
        dispatch(setRoutes(currentRoutes));
    };

    const handleDateFromToSelect = dates => {
        const currentRoutes = [...routes];
        currentRoutes[0].date_from = dates[0] !== null ? moment(dates[0]).format('YYYY-MM-DD') : null;
        if (dates[1] !== null) {
            currentRoutes[1].date_from = moment(dates[1]).format('YYYY-MM-DD');
            dispatch(setFlightType(RT));
        } else {
            currentRoutes[1].date_from = null;
        }
        dispatch(setRoutes(currentRoutes));
    };

    const handleReplaceRoute = () => {
        const currentRoutes = [...routes];
        const newRoutes = [];

        newRoutes.push({
            from: currentRoutes[0].to,
            to: currentRoutes[0].from,
            date_from: currentRoutes[0].date_from,
        });

        newRoutes.push({
            from: currentRoutes[1].to,
            to: currentRoutes[1].from,
            date_from: currentRoutes[1].date_from,
        });

        dispatch(setRoutes(newRoutes));
    };

    return (
        <Container>
            <From notFilled={notFilledFields.from}>
                <AirportField type="from" isSimple onSelect={handleAirportFromSelect} selected={routes[0].from} />
            </From>

            <ReplaceRouteButton onClick={() => handleReplaceRoute()}>
                <ReplaceRouteButtonLeftIcon>
                    <ArrowLeftIcon />
                </ReplaceRouteButtonLeftIcon>

                <ReplaceRouteButtonRightIcon>
                    <ArrowLeftIcon />
                </ReplaceRouteButtonRightIcon>
            </ReplaceRouteButton>

            <To notFilled={notFilledFields.to}>
                <AirportField type="to" isSimple onSelect={handleAirportToSelect} selected={routes[0].to} />
            </To>

            <DateFromTo notFilled={notFilledFields.date}>
                <DateFromToField
                    onSelect={handleDateFromToSelect}
                    selected={[routes[0].date_from, routes[1].date_from]}
                />
            </DateFromTo>

            <PassengersAndClass>
                <PassengersAndClassField dWidth pWidth />
            </PassengersAndClass>
        </Container>
    );
};

export default SimpleRoute;
