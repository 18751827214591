import styled from 'styled-components';

export const Alert = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    padding: 6px 16px;
    margin: 1rem 0;
`;

export const Warning = styled(Alert)`
    background-color: rgb(255, 244, 229);
    color: rgb(102, 60, 0);
`;

export const Success = styled(Alert)`
    background-color: rgb(237, 247, 237);
    color: rgb(30, 70, 32);
`;

export const Error = styled(Alert)`
    background-color: rgb(253, 237, 237);
    color: rgb(95, 33, 32);
`;

export const Info = styled(Alert)`
    background-color: rgb(229, 246, 253);
    color: rgb(1, 67, 97);
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 0.35em;
    margin-top: -2px;
`;

export const Body = styled.div``;

export const Icons = styled.div`
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;
