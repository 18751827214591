import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    carouselBlock: {
        marginTop: '8px',
        marginBottom: '32px',
    },
    carouselItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px',
        height: '100%',
        boxSizing: 'border-box',

        '@media (max-width: 1279px)': {
            padding: '12px',
        },

        '@media (max-width: 959px)': {
            padding: 0,
        },
    },
    carouselItemImage: {
        width: '825px',
        height: '410px',
        display: 'block',
        borderRadius: '20px',

        '@media screen and (max-width: 1279px)': {
            // width: '90%',
            height: '300px',
            borderRadius: 12,
        },

        '@media screen and (max-width: 959px)': {
            width: '100%',
            height: '260px',
            borderRadius: 0,
        },
    },
    carouselDots: {
        bottom: 'auto',
        top: '100%',

        '& > .slick-active > div': {
            backgroundColor: '#00ddff',
        },

        '@media screen and (max-width: 959px)': {
            top: 'calc(100% + 8px)',
        },
    },
    carouselDot: {
        width: '16px',
        height: '16px',
        backgroundColor: '#E4E9F3',
        borderRadius: '50%',
        '@media screen and (max-width: 959px)': {
            width: 14,
            height: 14,
        },

        '@media screen and (max-width: 959px)': {
            width: 12,
            height: 12,
        },
    },
});
