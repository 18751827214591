import styled from 'styled-components';

export const NotFoundComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 0;
    font-weight: bold;
    font-size: 24px;
    color: #404b5a;
`;
