import React from 'react';
import BrokenOrderContainer from '../brokenOrderContainer/index';
import Order from '../order/index';

const OrderContainer = props => {
    const { order, setOrderDate, setRedirect } = props;
    const { brokenOrder } = order;

    const setOrderRedirect = (orderId, key) => {
        setOrderDate({
            orderId,
        });

        setRedirect(true);
    };

    if (brokenOrder) {
        return <BrokenOrderContainer {...props} setOrderRedirect={setOrderRedirect} />;
    }

    return <Order {...props} setOrderRedirect={setOrderRedirect} />;
};

export default OrderContainer;
