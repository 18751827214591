import React, { useState } from 'react';
import { FormattedDateTimeWithoutYear } from '../../../../components/result/common';
import { useStyles } from './styles';
import ButtonUI from '../../../../components/ui/components/ButtonUI/ButtonUI';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReplayIcon from '@material-ui/icons/Replay';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CancelIcon from '@material-ui/icons/Cancel';
import { BookingStatus } from '../smallComponents/index';
import useLocalization from '../../../../contexts/localization/hook';

const BrokenOrderContainer = props => {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const { order, setOrderRedirect } = props;
    const [showActionButtons, setShowActionButtons] = useState(false);
    const { order_id, timestamp, order_status, timelimit, booking_status } = order;

    return (
        <Grid className={classes.orderContainer}>
            <Grid container className={classes.orderBlock}>
                <Grid
                    item
                    className={clsx(classes.orderMainBlock, classes.cursorPointer)}
                    onClick={() => setOrderRedirect(order_id)}
                >
                    <Grid item className={classes.oderIdBlock}>
                        {t.order} {order_id}
                    </Grid>
                    <Grid item className={classes.routesBlock}></Grid>
                    <Grid item className={classes.amountBlock}></Grid>
                    <Grid item className={classes.timestampBlock}>
                        {t.created} {FormattedDateTimeWithoutYear(timestamp, language)}
                    </Grid>
                    <Grid item className={classes.departuresBlock}></Grid>
                </Grid>
                <Grid item className={classes.orderMainBlock}>
                    <Grid container>
                        <Grid
                            item
                            className={classes.statusBlock}
                            onClick={() => setOrderRedirect(order_id)}
                            xs={12}
                            sm={6}
                        >
                            <BookingStatus
                                bookingStatus={booking_status}
                                orderStatus={order_status}
                                timelimit={timelimit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid className={classes.orderSubmitBlock}>
                                <ButtonUI
                                    onClick={() => setShowActionButtons(!showActionButtons)}
                                    title={t.action}
                                    endIcon={showActionButtons ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    fontSize="12px"
                                    height="32px"
                                />
                            </Grid>
                        </Grid>
                        {showActionButtons && (
                            <Grid item className={classes.actionButtonContainer}>
                                <Grid className={classes.actionButtonBlock}>
                                    <Grid className={classes.actionSubmitBlock}>
                                        <ButtonUI
                                            onClick={() => {}}
                                            title={t.to_pay}
                                            endIcon={<AddShoppingCartIcon />}
                                            fontSize="10px"
                                            isGreen={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid className={classes.actionSubmitBlock}>
                                        <ButtonUI
                                            onClick={() => {}}
                                            title={t.cancel}
                                            endIcon={<CancelIcon />}
                                            fontSize="10px"
                                            disabled={true}
                                            isLoading={false}
                                        />
                                    </Grid>
                                    <Grid className={classes.actionSubmitBlock}>
                                        <ButtonUI
                                            onClick={() => {}}
                                            title={t.refund_button_title}
                                            endIcon={<ReplayIcon />}
                                            fontSize="10px"
                                            disabled={true}
                                            isLoading={false}
                                        />
                                    </Grid>
                                    <Grid className={classes.actionSubmitBlock}>
                                        <ButtonUI
                                            onClick={() => {}}
                                            title={t.itinerary_receipt}
                                            endIcon={<ReceiptIcon />}
                                            fontSize="10px"
                                            disabled={true}
                                            isLoading={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BrokenOrderContainer;
