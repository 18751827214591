import React from 'react';
import _ from 'lodash';
import { Error } from '@material-ui/icons';

import useLocalization from '../../../../../contexts/localization/hook';

import LoadingBlock from '../../../resultPage/loadingBlock';
import {
    BrandsBlock,
    BrandsContainer,
    BrandsMessageBlock,
    BrandsMessageIcon,
    BrandsMessageTitle,
    BrandsWrapper,
    LoadingWrapper,
} from './components';
import Tariffs from './tariffs';

const DesktopTariffsBlock = props => {
    const { t } = useLocalization();

    if (props.brandFares) {
        return (
            <BrandsWrapper>
                <BrandsContainer>
                    <BrandsMessageBlock>
                        <BrandsMessageIcon>
                            <Error fontSize="small" />
                        </BrandsMessageIcon>
                        <BrandsMessageTitle>{t.route_tariffs_message}</BrandsMessageTitle>
                    </BrandsMessageBlock>

                    <BrandsBlock>
                        <Tariffs {...props} />
                    </BrandsBlock>
                </BrandsContainer>
            </BrandsWrapper>
        );
    }

    return (
        <LoadingWrapper>
            <LoadingBlock withoutIcon />
        </LoadingWrapper>
    );
};

export default DesktopTariffsBlock;
