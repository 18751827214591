import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    ordersMainContainer: {
        position: 'relative',
    },
    ordersTitle: {
        color: '#212121',
        fontSize: '34px',
        fontWeight: '500',
        paddingTop: '16px',
    },
    ordersItemsContainer: {
        minHeight: '360px',
        order: 1,

        '@media (max-width:960px)': {
            order: 2,
        },
    },
    searchFieldsContainer: {
        alignSelf: 'flex',
        paddingTop: '81px !important',
        order: 2,

        '@media (max-width: 960px)': {
            width: '100%',
            order: 1,
            paddingTop: '24px !important',
        },
    },
    paginationContainer: {
        order: 3,
    },
    helpContainer: {
        float: 'right',
        paddingTop: '70px',
    },
});
