import React, { useEffect, useState, useRef } from 'react';
import { Container } from '@material-ui/core';
import API from '../../lib/api';
import { makeStyles } from '@material-ui/styles';
import { setAppConfigs } from '../../store/appConfigs/actions';
import { useDispatch } from 'react-redux';
import useLocalization from '../../contexts/localization/hook';
import Loading from '../../components/design_v2/ui/loading/index';
import NotFoundContent from '../../components/design_v2/ui/notFoundContent';
import envConfigs from '../../config/envConfigs';

const useStyles = makeStyles({
    container: {
        margin: '64px 0',

        '@media (max-width: 959px)': {
            margin: '32px 0 64px',
        },
    },
    partnerInformHeader: {
        textAlign: 'center',
        color: '#404B5A',
    },
    partnerInfoText: {
        paddingBottom: '16px',
        fontSize: '16px',

        '& img': {
            maxWidth: '100% !important',
            height: 'auto !important',
        },
    },
});

const OurPartners = props => {
    const {
        match: { params },
    } = props;
    const { slug } = params;

    const dispatch = useDispatch();
    const { t, language } = useLocalization();

    const [notFound, setNotFound] = useState(false);
    const [partner, setPartner] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const containerRef = useRef(null);

    const classes = useStyles();

    useEffect(() => {
        document.title = `${t.page_home} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                showSearchBlock: true,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    const executeScroll = () => {
        const y = containerRef?.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    useEffect(() => {
        partnersPage();
    }, [language]);

    const partnersPage = async () => {
        setIsLoading(true);

        await API.getPartnersPage({ slug, language }).then(res => {
            var partners = res.data;

            if (partners) {
                setNotFound(false);
                partners.partner_content.map(partner => {
                    if (partner.language == language) {
                        setPartner(partner);
                        document.title = `${partner.partner_name} | ${envConfigs.appTitle}`;
                    }
                    const keywords = document.querySelector('meta[name="keywords"]');

                    if (partner.keywords) {
                        keywords.content = partner.keywords;
                    }
                });

                if (!partner) {
                    setNotFound(true);
                }
            } else {
                setNotFound(true);
            }

            setIsLoading(false);
            executeScroll();
        });
    };

    if (isLoading)
        return (
            <div className={classes.container}>
                <Loading />
            </div>
        );

    return (
        <Container fixed ref={containerRef}>
            <div className={classes.container}>
                {notFound ? (
                    <NotFoundContent />
                ) : (
                    <div>
                        <h1 className={classes.partnerInformHeader}>{partner.partner_name}</h1>
                        <p className={classes.partnerInfoText} dangerouslySetInnerHTML={{ __html: partner.body }} />
                    </div>
                )}
            </div>
        </Container>
    );
};

export default OurPartners;
