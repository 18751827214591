import { SET_ALL_LOADING_DATA, SET_BOOKING_LOADING, SET_SHOW_LOADING, SET_SEARCH_LOADING, SET_SEARCH_DATA } from './constants';
import initialState from './initialState';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_LOADING_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case SET_BOOKING_LOADING:
            return {
                ...state,
                bookingLoading: action.payload,
            };
        case SET_SHOW_LOADING:
            return {
                ...state,
                showLoading: action.payload,
            };
        case SET_SEARCH_LOADING:
            return {
                ...state,
                searchLoading: action.payload,
            };
        case SET_SEARCH_DATA:
            return {
                ...state,
                searchData: action.payload,
            };
        default:
            return state;
    }
};
