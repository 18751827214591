import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    Component,
    HideOnMobile,
    HideOnPc,
    Block,
    MainBlock,
    Column,
    LogoBlock,
    ContactUsText,
    ContactsEmail,
    ContactPhone,
    LinkToDownload,
    LinkToStaticPage,
    SocialsBlock,
    SocialsLinks,
    SocialLink,
    DontCopyText,
    PaymentMethods,
    PaymentMethodsText,
    PaymentMethodsLogos,
    PaymentMethodLogo,
    LogoImage,
    CertificatesBlock,
} from './components';
import useLocalization from '../../../contexts/localization/hook';

import { Telegram as TelegramIcon } from '@material-ui/icons';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/newDesign/whatsAppIcon.svg';
import { ReactComponent as VKIcon } from '../../../assets/icons/newDesign/vkIcon.svg';
import flyLogo from '../../../images/mainLogo/fly55invert.png';
import iataLogo from '../../../images/mainLogo/iata-logo-white.png';
import tkpLogo from '../../../images/mainLogo/others/logoTKP.png';
import visa from '../../../images/otherLogos/visa1.png';
import masterCard from '../../../images/otherLogos/masterCard.png';
import mir from '../../../images/otherLogos/mir.png';
import unionPay from '../../../images/otherLogos/unionPay.png';
import API from '../../../lib/api';
import envConfigs from '../../../config/envConfigs';

const Footer = () => {
    const { t, language } = useLocalization();
    const [state, setState] = useState([]);

    useEffect(() => {
        staticInfoDiscriptions();
    }, []);

    const staticInfoDiscriptions = async () => {
        await API.getStaticDiscriptions().then(res => {
            setState(res.data);
        });
    };

    const divideBlocks = (array, chunks) => {
        let result = [];
        let n = state.length;

        for (let i = 0; i < n; i += chunks) {
            result = [...result, array.slice(i, i + chunks)];
        }

        return result;
    };

    let columns = divideBlocks(state, 6);

    return (
        <Component>
            <Container fixed maxWidth="lg">
                <Block>
                    <MainBlock>
                        <Column>
                            <HideOnMobile>
                                <LogoBlock>
                                    <LogoImage src={flyLogo} alt="fly-55-logo-white" />
                                </LogoBlock>
                            </HideOnMobile>

                            <ContactUsText>{t.contact_us}:</ContactUsText>
                            <ContactsEmail>{envConfigs.supportPhone}</ContactsEmail>
                            <ContactsEmail>
                                {envConfigs.supportPhoneSecond} ({t.fly_ru_round_the_clock})
                            </ContactsEmail>
                            <ContactPhone>{envConfigs.saleMail}</ContactPhone>
                            <SocialsLinks>
                                <SocialLink target="_blank" href="https://t.me/Fly55ru">
                                    <TelegramIcon style={{ color: '#02c5e3' }} />
                                </SocialLink>

                                <SocialLink target="_blank" href="https://wa.me/message/LEXG5RYNAZTFG1">
                                    <WhatsAppIcon />
                                </SocialLink>

                                <SocialLink target="_blank" href="https://vk.com/club182572643">
                                    <VKIcon />
                                </SocialLink>
                            </SocialsLinks>
                        </Column>

                        <Column>
                            <LinkToStaticPage to={`/refund-order`}>
                                {t.refund_order || 'Заявление о возврате'}
                            </LinkToStaticPage>

                            {state.map((item, index) => {
                                const contentByLanguage = item.footer_content.find(
                                    content => content.language === language
                                );

                                if (contentByLanguage?.attachment) {
                                    return (
                                        <LinkToDownload
                                            href={`${envConfigs.uploadHost}${contentByLanguage?.attachment}`}
                                            key={`list-${index}`}
                                        >
                                            {contentByLanguage?.page_name}
                                        </LinkToDownload>
                                    );
                                }

                                return (
                                    <LinkToStaticPage to={`/staticInfoPage/${item.slug}`} key={`list-${index}`}>
                                        {contentByLanguage?.page_name}
                                    </LinkToStaticPage>
                                );
                            })}
                        </Column>

                        <Column>
                            <PaymentMethods>
                                <PaymentMethodsText>{t.payment_methods || 'Способы оплаты'}:</PaymentMethodsText>

                                <PaymentMethodsLogos>
                                    <PaymentMethodLogo src={visa} alt="visa-logo" />
                                    <PaymentMethodLogo src={masterCard} alt="master-card-logo" />
                                    <PaymentMethodLogo src={mir} alt="mir-logo" />
                                    <PaymentMethodLogo src={unionPay} alt="mir-logo" />
                                </PaymentMethodsLogos>
                            </PaymentMethods>
                        </Column>
                        <HideOnPc>
                            <CertificatesBlock>
                                <LogoBlock>
                                    <LogoImage src={iataLogo} alt="iata-logo-white" />
                                </LogoBlock>

                                <LogoBlock>
                                    <LogoImage src={tkpLogo} alt="tkp-logo-white" />
                                </LogoBlock>
                            </CertificatesBlock>
                        </HideOnPc>
                    </MainBlock>

                    <SocialsBlock>
                        <HideOnPc>
                            <LogoBlock>
                                <LogoImage src={flyLogo} alt="fly-55-logo-white" />
                            </LogoBlock>
                        </HideOnPc>
                    </SocialsBlock>

                    <DontCopyText>55fly.ru {t.agreement_footer_text}</DontCopyText>
                </Block>
            </Container>
        </Component>
    );
};

export default Footer;
