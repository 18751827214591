const locales = {
    ru: {
        one: 'пассажир',
        few: 'пассажира',
        more: 'пассажиров',
    },
    en: {
        one: 'passenger',
        few: 'passengers',
        more: 'passengers',
    },
    tj: {
        one: 'мусофир',
        few: 'мусофир',
        more: 'мусофир',
    },
};

export default locales;
