import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setAppConfigs } from '../../store/appConfigs/actions';

import { Form } from '../../components/design_v2/authPages/components';
import { ErrorSubtitle, SuccessSubtitle } from './components';

import API from '../../lib/api';
import useLocalization from '../../contexts/localization/hook';

const UnSubscribePage = () => {
    const { isSmallSearchBlock } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();

    const { t } = useLocalization();
    const containerRef = useRef();

    const [success, setSuccess] = useState(false);

    const unSubscribe = async () => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);

        await API.unsubscribe({
            key: params.get('key'),
        })
            .then(response => {
                const state = response.status == 404 ? false : true;
                setSuccess(state);
            })
            .catch(err => setSuccess(false));
    };

    useEffect(() => {
        unSubscribe();
        window.scrollTo({
            behavior: 'smooth',
            top: containerRef.current.offsetTop - 100,
        });
        dispatch(
            setAppConfigs({
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );

        document.title = success ? t.unsubscribe_success : t.unsubscribe_error;
    }, [isSmallSearchBlock]);

    return (
        <Container fixed ref={containerRef}>
            <Form>
                {success && <SuccessSubtitle>{t.unsubscribe_success}</SuccessSubtitle>}
                {!success && <ErrorSubtitle>{t.unsubscribe_error}</ErrorSubtitle>}
            </Form>
        </Container>
    );
};

export default withRouter(UnSubscribePage);
