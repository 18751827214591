import styled from 'styled-components';

export const Input = styled.input`
    background-color: transparent;
    width: 397px;
    height: 60px;
    margin: 0;
    padding: 8px 40px 8px 16px;
    border: none;
    border-radius: 0 10px 10px 0;
    font-size: 1rem;
    position: relative;
    cursor: pointer;
    color: #fff;

    z-index: ${props => (props.isActive ? 10 : 5)};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 1279px) {
        border-radius: 12px;
        width: 100%;
    }

    @media (max-width: 959px) {
        border-radius: 12px;
        width: 100%;
    }

    ::placeholder {
        color: #fff;
    }
`;

export const Icon = styled.img`
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    right: 8px;
    top: calc(50% - 12px);
`;
