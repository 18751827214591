import React from 'react';
import DesktopRecommendation from './desktop';
import MobileRecommendation from './mobile';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

const Recommendation = ({ recommendation, index, onBookingClick, getFareRules, getBrandFares, specialText = null }) => {
    const { width } = useWindowDimensions();

    return (
        <>
            {width >= 960 && (
                <DesktopRecommendation
                    index={index}
                    recommendation={recommendation}
                    onBookingClick={onBookingClick}
                    getFareRules={getFareRules}
                    getBrandFares={getBrandFares}
                    specialText={specialText}
                />
            )}

            {width < 960 && (
                <MobileRecommendation
                    index={index}
                    recommendation={recommendation}
                    onBookingClick={onBookingClick}
                    getBrandFares={getBrandFares}
                    getFareRules={getFareRules}
                    specialText={specialText}
                />
            )}
        </>
    );
};

export default Recommendation;
