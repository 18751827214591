import React from 'react';

import DesktopNav from './desktop';
import MobileNav from './mobile';

const Nav = () => {
    return (
        <>
            <DesktopNav />
            <MobileNav />
        </>
    );
};

export default Nav;
