import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 236px;
    height: 100%;
    padding: 8px 16px;
    user-select: none;

    border-radius: ${props => (props.isSimple ? '12px' : '12px')};

    &::after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border: 5px solid transparent;
        border-top-color: #fff;
        position: absolute;
        right: 17px;
        top: 5px;
        bottom: 0;
        margin: auto;
        -webkit-transition: -webkit-transform 0.15s ease-out;
        transition: -webkit-transform 0.15s ease-out;
        transition: transform 0.15s ease-out;
        transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
    }

    &.active::after {
        -webkit-transform: translateY(-4px) rotate(180deg);
        transform: translateY(-4px) rotate(180deg);
    }

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 1279px) {
        border-radius: ${props => (props.isSimple ? '0 0 10px 0' : '12px')};
        width: ${props => (props.pWidth ? '440px' : '220px')};
    }

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const TextsBlock = styled.div``;

export const PassengerText = styled.div`
    color: #fff;
`;

export const ClassText = styled.div`
    font-size: 0.9rem;
    color: #fff;
`;

export const DropdownBlock = styled.div`
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    z-index: 9;

    @media (max-width: 959px) {
        width: 100%;
    }
`;
