import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: ${props => (props.borderBottom ? '1px solid #cfd8dc' : 'none')};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px 0;

    @media (max-width: 959px) {
        padding: 20px 0;
    }
`;

export const Title = styled.div`
    font-weight: bold;
    color: #404b5a;
`;

export const IconBlock = styled.div`
    transform: rotate(0deg);

    &.active {
        transform: rotate(-90deg);
    }
`;

export const CaretIcon = styled.img`
    display: block;
    height: 14px;
`;

export const Body = styled.div`
    height: 0;
    overflow: hidden;

    &.active {
        height: auto;
        overflow: initial;
        padding-bottom: 8px;
    }
`;
