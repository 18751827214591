import React from 'react';
import { Container } from '@material-ui/core';

import useLocalization from '../../../../../contexts/localization/hook';

import Carousel from './carousel';
import { Header, Icon } from './components';
import Discount from '../../../../../assets/icons/percent.svg';

const ToursAndDiscounts = ({ tours }) => {
    const { t } = useLocalization();

    if (!tours.length) return null;

    return (
        <Container fixed style={{ marginBottom: '6rem' }}>
            <Header>
                <Icon src={Discount} />
                {t.tours_discount}
            </Header>

            <Carousel tours={tours} />
        </Container>
    );
};

export default ToursAndDiscounts;
