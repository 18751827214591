import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router';

import { setAppConfigs } from '../../store/appConfigs/actions';
import NewUserProfile from '../../pages/newUserProfile';
import envConfigs from '../../config/envConfigs';
import useLocalization from '../../contexts/localization/hook';

const UserProfile = () => {
    const { isAuth } = useSelector(state => state.appConfigs);
    const { t } = useLocalization();

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = `${t.page_my_profile} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    if (!isAuth) return <Redirect to="/" />;

    return <NewUserProfile />;
};
export default withRouter(UserProfile);
