import React from 'react';
import ReactDOM from 'react-dom';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const SnackbarComponent = ({ show, duration, onClose, anchorOrigin, alertVariant, alertSeverity, alertText }) => {
    return ReactDOM.createPortal(
        <Snackbar open={show} autoHideDuration={duration} onClose={onClose} anchorOrigin={anchorOrigin}>
            <Alert variant={alertVariant} severity={alertSeverity}>
                {alertText}
            </Alert>
        </Snackbar>,
        document.body
    );
};

export default SnackbarComponent;
