import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { LEFT_PAGE, RIGHT_PAGE } from '../../../../store/orders/constants';

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

const fetchPageNumbers = (totalPages, currentPage, pageNeighbours) => {
    const totalNumbers = pageNeighbours * 2 + 3;

    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
        let pages = [];

        const leftBound = currentPage - pageNeighbours;
        const rightBound = currentPage + pageNeighbours;
        const beforeLastPage = totalPages - 1;

        const startPage = leftBound > 2 ? leftBound : 2;
        const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

        pages = range(startPage, endPage);

        const pagesCount = pages.length;
        const singleSpillOffset = totalNumbers - pagesCount - 1;

        const leftSpill = startPage > 2;
        const rightSpill = endPage < beforeLastPage;

        const leftSpillPage = LEFT_PAGE;
        const rightSpillPage = RIGHT_PAGE;

        if (leftSpill && !rightSpill) {
            const extraPages = range(startPage - singleSpillOffset, startPage - 1);
            pages = [leftSpillPage, ...extraPages, ...pages];
        } else if (!leftSpill && rightSpill) {
            const extraPages = range(endPage + 1, endPage + singleSpillOffset);
            pages = [...pages, ...extraPages, rightSpillPage];
        } else if (leftSpill && rightSpill) {
            pages = [leftSpillPage, ...pages, rightSpillPage];
        }

        return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
};

function Pagination(props) {
    const classes = useStyles();
    const { totalRecords, pageLimit, pageNeighbours, onPageChanged, currentPage } = props;

    const totalPages = Math.ceil(totalRecords / pageLimit);

    const gotoPage = page => {
        const newCurrentPage = Math.max(0, Math.min(page, totalPages));

        onPageChanged(newCurrentPage);
    };

    const handleClick = (page, evt) => {
        evt.preventDefault();
        gotoPage(page);
    };

    const handleMoveLeft = evt => {
        evt.preventDefault();
        gotoPage(currentPage - pageNeighbours * 2 - 1);
    };

    const handleMoveRight = evt => {
        evt.preventDefault();
        gotoPage(currentPage + pageNeighbours * 2 + 1);
    };

    if (!totalRecords) return null;

    if (totalPages === 1) return null;

    const pages = fetchPageNumbers(totalPages, currentPage, pageNeighbours);

    return (
        <div>
            {pages.map((page, index) => {
                const isSelected = page === currentPage;

                if (page === LEFT_PAGE) {
                    return (
                        <div className={classes.notSelectedPage} key={index} onClick={e => handleMoveLeft(e)}>
                            <div className={classes.pageItem}>&laquo;</div>
                        </div>
                    );
                }

                if (page === RIGHT_PAGE) {
                    return (
                        <div className={classes.notSelectedPage} key={index} onClick={e => handleMoveRight(e)}>
                            <div className={classes.pageItem}>&raquo;</div>
                        </div>
                    );
                }

                return (
                    <div
                        className={isSelected ? classes.selectedPage : classes.notSelectedPage}
                        key={index}
                        onClick={e => handleClick(page, e)}
                    >
                        <div className={classes.pageItem}>{page}</div>
                    </div>
                );
            })}
        </div>
    );
}

Pagination.defaultProps = {
    totalRecords: 0,
    pageLimit: 30,
    pageNeighbours: 0,
};

Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func,
};

export default Pagination;
