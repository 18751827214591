import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { setClientData } from '../../store/clientData/actions';
import { setAppConfigs } from '../../store/appConfigs/actions';
import { setShowLoading } from '../../store/loading/actions';
import API from '../../lib/api';
import AuthContext from './context';
import { useLocation } from 'react-router-dom';
import MainLoading from '../../components/design_v2/mainLoading';

const token = localStorage.getItem('token');

export default props => {
    const [isLoad, setIsLoad] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!_.isUndefined(token) && !_.isNull(token)) {
            API.getClient()
                .then(response => {
                    const { data } = response;
                    dispatch(
                        setClientData({
                            id: data.idp,
                            active: data.active,
                            balance: data.balance,
                            currency: data.currency,
                            email: data.email,
                            fatherName: data.father_name,
                            firstName: data.first_name,
                            lastName: data.last_name,
                            login: data.login,
                            phone: data.phone,
                            photo: data.photo,
                        })
                    );
                    dispatch(
                        setAppConfigs({
                            isAuth: true,
                        })
                    );
                    setIsLoad(true);
                })
                .catch(() => {
                    localStorage.removeItem('token');
                    setIsLoad(true);
                });
        } else {
            setIsLoad(true);
        }
    }, []);

    useEffect(() => {
        if (isLoad && !location.pathname.includes('result') && !location.pathname.includes('order')) {
            dispatch(setShowLoading(false));
        }
    }, [isLoad]);

    const refreshUserData = () => {
        if (!_.isUndefined(token) && !_.isNull(token)) {
            API.getClient()
                .then(response => {
                    const { data } = response;

                    dispatch(
                        setClientData({
                            active: data.active,
                            balance: data.balance,
                            currency: data.currency,
                            email: data.email,
                            fatherName: data.father_name,
                            firstName: data.first_name,
                            lastName: data.last_name,
                            login: data.login,
                            phone: data.phone,
                            photo: data.photo,
                        })
                    );

                    dispatch(
                        setAppConfigs({
                            isAuth: true,
                        })
                    );
                })
                .catch(() => {
                    localStorage.removeItem('token');
                });
        }
    };

    if (!isLoad) return <MainLoading />;

    return (
        <AuthContext.Provider
            value={{
                refreshUserData,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
