import React from 'react';
import useLocalization from '../../../../contexts/localization/hook';
import { Wrapper } from './components';
import { noun, verb } from 'plural-ru';

const FlightsCount = ({ recommendationsCount }) => {
    const { t } = useLocalization();

    return (
        <Wrapper>
            {noun(recommendationsCount, t.found_one, t.found_more, t.found_more)} {`${recommendationsCount} `}
            {verb(recommendationsCount, t.flight_one, t.flights_more, t.flights_many)}
        </Wrapper>
    );
};

export default FlightsCount;
