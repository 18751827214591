import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RulesModal from './rulesModal';
import { useStyles } from '../../containers/booking/styles';
import moment from 'moment';
import {
    FormattedDateTime,
    getBaggageCountString,
    getRouteCity,
    GetTime,
    GetTransferTime,
    RenderAirlineLogo,
    RenderDurationTime,
    renderServiceClass,
} from '../result/common';
import { COLOR_THEME } from '../../containers/result/constants';
import useLocalization from '../../contexts/localization/hook';

export default function RoutesData(props) {
    const { routes, included, fareRules } = props;

    return (
        <div>
            {routes.map((route, index) => (
                <RenderRoute
                    route={route}
                    fareRules={fareRules}
                    key={index}
                    index={index}
                    included={included}
                    routes={routes}
                />
            ))}
        </div>
    );
}

function RenderRoute(data) {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const { route, index, included, fareRules, routes } = data;
    const themeColor = COLOR_THEME[index];
    const [departureCity, arrivalCity, departureTime] = getRouteCity(route, included, language);

    return (
        <Grid container className={classes.rootRoute}>
            <div style={{ backgroundColor: themeColor }} className={classes.bolderLeftLine} />

            <Grid item xs={12} className={classes.headerTitleAndTime}>
                <Grid container>
                    <Grid item xs={12} sm={8} lg={9} className={classes.routeTitleFirst}>
                        <div className={classes.departureTimeBlock}>
                            {t.departure_title} <FormattedDateTime time={departureTime} />
                        </div>
                        <div className={classes.departureRouteBlock}>
                            {departureCity} — {arrivalCity}
                        </div>
                    </Grid>
                    <div className={classes.divider1} />
                    <Grid item xs={12} sm={4} lg={3} className={classes.routeTitleSecond}>
                        <div className={classes.travelTimeLabel}>{t.travel_time}</div>
                        <div className={classes.travelTime}>
                            <RenderDurationTime duration={route.duration} />
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.divider2} />
            </Grid>

            <Grid item xs={12} className={classes.segmentsContainer}>
                {route.segments.map((segment, index) => (
                    <RenderSegment
                        segment={segment}
                        route={route}
                        key={index}
                        index={index}
                        included={included}
                        fareRules={fareRules}
                        routes={routes}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

function RenderSegment(data) {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const [openRules, setOpenRules] = useState(false);

    const handleCloseRules = () => {
        setOpenRules(false);
    };

    const { segment, route, index, included, fareRules, routes } = data;
    const {
        aircraft,
        operation_supplier,
        carrier_number,
        duration,
        flight_number,
        departure,
        arrival,
        service_class,
        baggage,
        hand_luggage,
        tech_stops,
    } = segment;
    const isTransfer = index > 0;
    let transferTime = '';
    const isTechStops = segment.tech_stops && segment.tech_stops.length > 0;

    let aircraftName = aircraft;

    if (included.aircraft[aircraft]) {
        aircraftName = included.aircraft[aircraft].name.en;
    } else {
        aircraftName = '';
    }

    const departureCode = departure.city;
    const arrivalCode = arrival.city;
    const departureCity = included.city[departureCode].name[language];
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureAirportCode = departure.airport;
    const arrivalAirportCode = arrival.airport;
    const departureAirport = included.airport[departureAirportCode].name[language];
    const arrivalAirport = included.airport[arrivalAirportCode].name[language];

    const departureCountryCode = included.airport[departureAirportCode].country;
    const departureCountry = included.country[departureCountryCode].name[language];

    if (isTransfer) {
        transferTime = GetTransferTime(route, index, t);
    }

    return (
        <Grid container className={classes.segmentBlock}>
            {isTransfer && (
                <Grid item className={classes.transferContainer}>
                    <div className={classes.transferBlock}></div>
                    <div className={classes.transferData}>
                        {t.transfers} {transferTime} • {departureCity}, {departureCountry}
                    </div>
                </Grid>
            )}
            <Grid item className={classes.flightData}>
                <div className={classes.logoBlock}>
                    <RenderAirlineLogo
                        supplier={operation_supplier}
                        style={{ backgroundPosition: 'left center' }}
                        horizontally="left"
                    />
                </div>
                <div className={classes.flightNumber}>
                    {t.flight} {carrier_number || flight_number}
                </div>
                <div className={classes.airCraft}>{aircraftName}</div>
                <div className={classes.flightNumberAndTime}>
                    <RenderDurationTime duration={duration} /> {t.on_way.toLowerCase()}
                </div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.flightTimes}>
                <Grid container>
                    <Grid item xs={5} className={classes.departureData}>
                        <div className={classes.departureTimeOnTop}>
                            <FormattedDateTime time={departure.time} />
                        </div>
                        <div className={classes.segmentTime}>
                            <GetTime time={departure.time} />
                        </div>
                        <div className={classes.pointBlock}>
                            <div className={classes.departurtime}>
                                <FormattedDateTime time={departure.time} />
                            </div>
                            <div className={classes.departCity}>{departureCity}</div>
                            <div className={classes.departAirport}>
                                {departureAirport}
                                <div className={classes.code} component="span">
                                    {departure.airport}
                                </div>
                            </div>
                            {departure.terminal && (
                                <div>
                                    {t.terminal} {departure.terminal}
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={2} className={classes.arrowBlock}></Grid>
                    <Grid item xs={5} className={classes.arrivalData}>
                        <div className={classes.departureTimeOnTop}>
                            <FormattedDateTime time={departure.time} />
                        </div>
                        <div className={classes.segmentTime}>
                            <GetTime time={arrival.time} />
                        </div>
                        <div className={classes.pointBlock}>
                            <div className={classes.departurtime}>
                                <FormattedDateTime time={arrival.time} />
                            </div>
                            <div className={classes.departCity}>{arrivalCity}</div>
                            <div className={classes.departAirport}>
                                {arrivalAirport}
                                <div className={classes.code} component="span">
                                    {arrival.airport}
                                </div>
                            </div>
                            {arrival.terminal && (
                                <div className={classes.terminal}>
                                    {t.terminal} {arrival.terminal}
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.flightInfo}>
                <div className={classes.classBlock}>{renderServiceClass(service_class, t)}</div>
                <div>
                    <div className={classes.baggageBlock}>
                        {t.baggage}: {baggage} {getBaggageCountString(baggage, t)}
                    </div>
                    <div className={classes.baggageBlock}>
                        {t.hand_luggage}: {hand_luggage} {getBaggageCountString(hand_luggage, t)}
                    </div>
                </div>
            </Grid>
            <div>
                <RulesModal
                    openRules={openRules}
                    handleCloseRules={handleCloseRules}
                    fareRules={fareRules}
                    routes={routes}
                    actualSegment={segment}
                    included={included}
                />
            </div>
            {isTechStops && (
                <Grid item className={classes.techStopContainer}>
                    <div className={classes.techStopBlock}>
                        <RenderSegmentTechStops items={tech_stops} included={included} />
                    </div>
                </Grid>
            )}
        </Grid>
    );
}

const RenderSegmentTechStops = data => {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const { items, included } = data;

    const tehStops = items
        .map(item => {
            let time = '';

            if (item.duration.hour > 0) {
                time = item.duration.hour + ' ' + t.hour_short + ' ';
            }

            time += item.duration.minute + ' ' + t.minutes_short_3;

            const startTime = moment(item.arrival.time, 'h:mm a').format('HH:mm');
            const cityCode = item.city.code;
            const countryCode = item.country.code;

            return (
                included.city[cityCode].name[language] +
                ', ' +
                included.country[countryCode].name[language] +
                ', ' +
                t.in +
                ' ' +
                startTime +
                ', ' +
                t.duration +
                ' — ' +
                time
            );
        })
        .join(', ');

    return (
        <div>
            <span className={classes.tehStopTitle}>{t.teh_stop}</span>
            <span>{tehStops}</span>
        </div>
    );
};
