import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import _ from 'lodash';

const useStyles = makeStyles({
    rootSlider: {
        width: '100%',
        paddingLeft: '13px',
        paddingRight: '8px',
    },
    rangeTitle: {
        color: '#212121',
        fontSize: '14px',
    },
    circle: {
        borderRadius: '50%',
        width: '8px',
        height: '8px',
        backgroundColor: '#fff',
    },
});

const RangeSliderItem = withStyles({
    root: {
        color: '#00ddff',
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '5px solid currentColor',
        marginTop: -9,
        marginLeft: -13,
        boxShadow: '#ebebeb 0px 2px 2px',
        '&:focus,&:hover,&$active': {
            boxShadow: '#ccc 0px 2px 3px 1px',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 4,
    },
    rail: {
        color: '#b7c7d6',
        opacity: 1,
        height: 4,
    },
})(Slider);

function ThumbComponent(props) {
    const classes = useStyles();

    return (
        <span {...props}>
            <span className={classes.circle} />
        </span>
    );
}

export default function RangeSlider(props) {
    const classes = useStyles();
    const { range, handleChange, filter, titleComponent, step } = props;
    const [value, setValue] = useState([filter.min || range.min, filter.max || range.max]);
    let disabledSlider = false;

    useEffect(() => {
        setValue([filter.min || range.min, filter.max || range.max]);
    }, [filter, range]);

    if (_.first(value) === _.last(value)) {
        disabledSlider = true;
    }

    const handleOnChange = (event, newValue) => {
        setValue(newValue);
    };

    const onChangeCommitted = (event, newValue) => {
        handleChange({
            min: _.first(newValue),
            max: _.last(newValue),
        });
    };

    return (
        <div>
            <div className={classes.rangeTitle}>
                {titleComponent ? (
                    titleComponent(_.first(value), _.last(value))
                ) : (
                    <DefaultTitleComponent min={_.first(value)} max={_.last(value)} />
                )}
            </div>
            <div className={classes.rootSlider}>
                <RangeSliderItem
                    disabled={disabledSlider}
                    ThumbComponent={ThumbComponent}
                    onChange={handleOnChange}
                    onChangeCommitted={onChangeCommitted}
                    step={step}
                    min={range.min}
                    max={range.max}
                    value={value}
                />
            </div>
        </div>
    );
}

const DefaultTitleComponent = props => {
    const { min, max } = props;

    return (
        <div>
            {min} — {max}
        </div>
    );
};

RangeSlider.defaultProps = {
    range: {
        min: 1,
        max: 100,
        minPercent: 1,
        maxPercent: 100,
        step: 1,
    },
};
