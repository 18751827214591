import React, { useState, useRef } from 'react';
import { useStyles } from '../../containers/booking/styles';
import useOnClickOutside from 'use-onclickoutside';
import configs from '../../config';
import Dropdown from '../ui/components/Dropdown/Dropdown';
import { getCountryIcon } from '../../lib/getImage';
import Input from '../ui/responsive/Input';
import { ErrorsFieldBlock } from '../../styles/common/index';
import InputMessage from '../ui/components/InputMessage/InputMessage';
import useLocalization from '../../contexts/localization/hook';

const getCitizenshipList = (cities, isValue) => {
    if (isValue === 0) {
        return getDefaultCountries();
    }

    return cities;
};

const getDefaultCountries = () => {
    return configs.defaultCountries;
};

const RenderItemComponent = props => {
    const { language } = useLocalization();
    const { item, handleOnSelect } = props;
    const { country, code } = item;

    const classes = useStyles();
    const flag = getCountryIcon(item.code);

    return (
        <div className={classes.citizenshipBlock} onClick={() => handleOnSelect(country, code)}>
            <div className={classes.citizenshipItem}>{country[language]}</div>

            {code && <div className={classes.citizenshipItemCode} style={{ backgroundImage: `url(${flag})` }} />}
        </div>
    );
};

export function CitizenshipForm(props) {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const [showDropdown, setShowDropdown] = useState(false);
    const {
        input,
        onUpdate,
        resetCountries,
        countries,
        meta: { touched, error },
    } = props;

    const dropdowWidth = '255px';
    const countriesList = getCitizenshipList(countries, input.value.length);

    let ref = useRef();
    useOnClickOutside(ref, () => setShowDropdown(false));

    const handleOpenDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleChange = e => {
        setShowDropdown(true);
        props.input.onChange(e.target.value);
    };

    const handleOnSelect = (name, code) => {
        setShowDropdown(false);
        resetCountries();
        onUpdate({ name, code });
    };

    const getFormattedValue = value => {
        if (typeof value === 'object') {
            return value['name'][language].substr(0, 20);
        }

        return value;
    };

    let flag = null;

    if (input.value['code']) {
        flag = getCountryIcon(input.value['code']);
    }

    let errorStyle = {};

    if (touched && error) {
        errorStyle = {
            border: 'solid 2px #c62828',
        };
    }

    return (
        <div className={classes.formControlCitizenship}>
            <div className={classes.labelCitizenship}>
                <span>{t.citizenship}</span>
            </div>
            <Input
                {...input}
                onClick={() => handleOpenDropdown()}
                onChange={e => handleChange(e)}
                widthSize="100%"
                heightSize="auto"
                isPadding={true}
                autoComplete="off"
                value={getFormattedValue(input.value)}
                style={errorStyle}
            />

            <ErrorsFieldBlock>{touched && error && <InputMessage text={t[error]} />}</ErrorsFieldBlock>

            {input.value['code'] && (
                <div className={classes.citizenshipInputCode} style={{ backgroundImage: `url(${flag})` }} />
            )}
            {showDropdown && (
                <div ref={ref}>
                    <Dropdown
                        items={countriesList}
                        width={dropdowWidth}
                        handleOnSelect={handleOnSelect}
                        component={RenderItemComponent}
                    />
                </div>
            )}
        </div>
    );
}

export default CitizenshipForm;
