import React, { useEffect, useState, useRef } from 'react';
import { Container, Grid } from '@material-ui/core';
import { setAppConfigs } from '../../store/appConfigs/actions';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
    AmountBlock,
    Input,
    Button,
    FormBlock,
    PaymentMethodsBlock,
    InputBlock,
    PaymentDiscription,
    PaymentIMG,
    MainHeader,
    HeaderMiddle,
    CurrentBalance,
    InputLabel,
    ChargingBalanceBlock,
    AllItems,
    Header,
} from './components';
import { useStyles } from './styles';
import API from '../../lib/api';
import config from '../../config';
import MaskedTextInput from '../../components/design_v2/ui/maskedTextInput';
import { useFormik } from 'formik';
import useLocalization from '../../contexts/localization/hook';
import defImage from '../../images/paymentMethod/kort.png';
import { CircularProgress, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Redirect } from 'react-router';

const RechargeBalancePage = () => {
    const { envConfigs } = config;

    const dispatch = useDispatch();
    const { t, language } = useLocalization();
    const user = useSelector(state => state.clientData);
    const { isFullScreenSearchBlock, currency, isAuth } = useSelector(state => state.appConfigs);
    const containerRef = useRef();

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        dispatch(
            setAppConfigs({
                isFullScreenSearchBlock: false,
            })
        );

        billingPaymentSystems();
    }, []);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, [isFullScreenSearchBlock]);

    useEffect(() => {
        document.title = `${t.page_add_balance} | ${envConfigs.appTitle}`;
    }, [language]);

    const validationSchema = Yup.object().shape({
        amount: Yup.number()
            .typeError(t.only_numbers)
            .test('minAmount', `${t.min_amount} 1 ${currency}`, val => val >= 1)
            .test('maxAmount', `${t.max_amount} 10000 ${currency}`, val => val <= 10000)
            .required(t.required),
        payment_system_id: Yup.string().required(t.required),
    });

    const chargeBalance = async values => {
        setLoadingButton(true);

        await API.postAddingBalance({
            amount: values.amount,
            payment_system_id: values.payment_system_id,
            return_url: window.location.origin,
            fail_url: window.location.origin,
        }).then(res => {
            window.location.href = `${res.message.payUrl}`;
        });
        setLoadingButton(false);
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            amount: 1,
            payment_system_id: state[0]?.id,
        },
        validationSchema,
        validateOnBlur: true,
        onSubmit: chargeBalance,
    });

    const moneyMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        integerLimit: 5,
    });

    const billingPaymentSystems = async () => {
        setIsLoading(true);
        await API.billingGetPaymentSystems().then(result => {
            const methods = result.filter(item => item.id !== 0);
            setState(methods);
            setIsLoading(false);
            setFieldValue('payment_system_id', methods[0]?.id);
        });
    };

    const loadingBlock = () => {
        return (
            <div className={classes.loading}>
                <CircularProgress size={80} style={{ color: '#00ddff' }} />
            </div>
        );
    };

    if (!isAuth) return <Redirect to="/" />;

    return (
        <Container fixed ref={containerRef}>
            <MainHeader>{t.recharging_balance}</MainHeader>
            <ChargingBalanceBlock>
                <AllItems>
                    <Header>
                        <CurrentBalance>
                            <span className={classes.currentBalanceSpan}>{t.current_balance}:</span> {user.balance || 0}{' '}
                            <span className={classes.currencySpan}>{user.currency || currency}</span>
                        </CurrentBalance>
                        <CurrentBalance>
                            <span className={classes.currentBalanceSpan}>{t.your_id}:</span> {user.id}
                        </CurrentBalance>
                    </Header>

                    <Divider />

                    <Grid item xs={12} className={classes.paymentBlock}>
                        <FormBlock error={errors.amount}>
                            <HeaderMiddle>{t.deposit_method}</HeaderMiddle>

                            <PaymentMethodsBlock>
                                {isLoading
                                    ? loadingBlock()
                                    : state.map((item, index) => {
                                          return (
                                              <InputBlock
                                                  key={`chargeBalance-${index}`}
                                                  error={errors.payment_system_id}
                                                  className={values.payment_system_id === item.id ? 'active' : null}
                                                  onClick={() => setFieldValue('payment_system_id', item.id)}
                                              >
                                                  <PaymentIMG
                                                      src={
                                                          item.logo ? `${envConfigs.uploadHost}${item.logo}` : defImage
                                                      }
                                                  />
                                                  {item.description[language] ? (
                                                      <PaymentDiscription>
                                                          {item.description[language]}
                                                      </PaymentDiscription>
                                                  ) : null}
                                                  <Input
                                                      type="radio"
                                                      name="payment_system_id"
                                                      value={item.id}
                                                      onChange={() => setFieldValue('payment_system_id', item.id)}
                                                      required
                                                      checked={values.payment_system_id === item.id}
                                                  />
                                              </InputBlock>
                                          );
                                      })}
                            </PaymentMethodsBlock>

                            <Divider />

                            <HeaderMiddle>{t.recharging_amount}</HeaderMiddle>
                            <AmountBlock>
                                <InputLabel>{currency}</InputLabel>
                                <MaskedTextInput
                                    value={values.amount}
                                    error={errors.amount}
                                    onBlur={handleBlur('amount')}
                                    label={t.amount}
                                    touched={touched.amount}
                                    onChangeText={handleChange('amount')}
                                    mask={moneyMask}
                                    required
                                />
                            </AmountBlock>

                            <Button type="submit" onClick={handleSubmit}>
                                {loadingButton ? <CircularProgress size={20} color="#fff" /> : t.recharge}
                            </Button>
                        </FormBlock>
                    </Grid>
                </AllItems>
            </ChargingBalanceBlock>
        </Container>
    );
};

export default RechargeBalancePage;
