import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveInput } from '../../../../styles/common/index';

function Input(props) {
    const {
        type,
        name,
        placeholder,
        disabled,
        autoFocus,
        uppercaseType,
        textTransform,
        value,
        defaultValue,
        ...input
    } = props;

    return (
        <ResponsiveInput
            {...input}
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            uppercase={uppercaseType}
            textTransform={textTransform}
            value={value || defaultValue}
        />
    );
}

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    textTransform: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
    type: 'text',
    name: null,
    placeholder: null,
    disabled: null,
    autoFocus: null,
    textTransform: 'none',
    defaultValue: '',
};

export default Input;
