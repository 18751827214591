import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import { Provider } from 'react-redux';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './styles/common/index';
import LocalizationProvider from './contexts/localization/provider';
import AuthProvider from './contexts/auth/provider';
import LoadingProvider from './providers/LoadingProvider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

require('dotenv').config();
const store = configureStore();
ReactDOM.render(
    <Provider store={store}>
        <GlobalStyle />
        <BrowserRouter>
            <LocalizationProvider>
                <AuthProvider>
                    <LoadingProvider>
                        <App />
                    </LoadingProvider>
                </AuthProvider>
            </LocalizationProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
