import configs from '../../config';

const initialState = {
    showHeader: true,
    showFooter: true,

    showSearchBlock: true,
    isStickySearchBlock: false,
    isSmallSearchBlock: false,
    isFullScreenSearchBlock: true,
    shouldMinimize: true,

    currency: configs.mainConfigs.defaultWebCurrency,
    availableCurrencies: ['RUB', 'TJS', 'USD', 'UZS'],
    isAuth: false,
    logoUrl: null,
};

export default initialState;
