const initialState = {
    transactionsItems: [],
    transactionsFilters: {
        dateStart: null,
        dateEnd: null,
        limit: 10,
        currentPage: 1,
        lastPage: 1,
    },
    isLoading: true,
};

export default initialState;
