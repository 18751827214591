import React, { useState } from 'react';
import BookData from './bookData';
import PaymentForm from './paymentForm';
import { Grid, Container, CircularProgress, Divider } from '@material-ui/core';
import RoutesData from './routesData';
import PriceForm from '../booking/priceForm';
import { TICKETING_DONE_CODE } from '../../containers/order/constants';
import { useStyles } from '../../containers/order/styles';
import { StyledButton, StyledVoidButton } from '../../styles/common/index';
import ScrollUpButton from 'react-scroll-up-button';
import LocalTimeText from '../ui/components/LocalTimeText/LocalTimeText';
import {
    getColorStatus,
    getCreatedTime,
    getBookStatus,
    checkIsAllowedCancel,
    checkInAccessToPaymentStatus,
} from '../result/common';
import { Redirect } from 'react-router-dom';
import HelpBlock from '../ui/components/HelpBlock/HelpBlock';
import configs from '../../config';
import ButtonUI from '../ui/components/ButtonUI/ButtonUI';
import SnackbarAlert from '../ui/components/SnackbarAlert/SnackbarAlert';
import Snackbar from '../design_v2/ui/snackbar';
import AlertModal from '../ui/components/AlertModal/AlertModal';
import useLocalization from '../../contexts/localization/hook';

const authRoutes = configs.authRoutes;

export default function OrderForm(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const {
        sendPayment,
        orderData,
        orderCancel,
        paymentMethod,
        setPaymentMethod,
        availablePaymentMethods,
        refundOrder,
        partnerCode,
        isChangedStatus,
        setIsChangedStatus,
    } = props;
    const {
        order_id,
        timestamp,
        booking_number,
        passengers_data,
        payer_email,
        payer_phone,
        included,
        routes,
        fare_rules,
        timelimit,
        session_id,
        currency,
        price,
        booking_status,
        airline_booking_number,
        order_status,
        status_at,
        partner_fees,
        total_price,
    } = orderData;

    const [redirect, setRedirect] = useState(false);
    const [voidStatus, setVoidStatus] = useState(false);
    const [isLoadingRefund, setIsLoadingRefund] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        severity: 'success',
        message: t.application_refund,
    });

    const handelSetRefundAlert = status => {
        if (status) {
            setAlertData({
                severity: 'success',
                message: t.application_refund,
            });
        } else {
            setAlertData({
                severity: 'error',
                message: t.error_creating_refund_request,
            });
        }

        setOpenAlert(true);
    };

    const handelOrderRefund = () => {
        setIsLoadingRefund(true);
        refundOrder(setIsLoadingRefund, handelSetRefundAlert);
    };

    const handelPaymentRequest = method => {
        sendPayment(() => {});
    };

    const RedirectToOrders = () => {
        setRedirect(true);
    };

    if (redirect) {
        return (
            <Redirect
                push
                to={{
                    pathname: authRoutes.orders,
                }}
            />
        );
    }

    const changedStatusMessage = () => {
        return (
            <div>
                {t.order_status_label} <b>{order_id}</b> {t.was_changed_to}:{' '}
                <b>{getBookStatus(booking_status, order_status, t)}</b>
            </div>
        );
    };

    const isAccessToPayment = checkInAccessToPaymentStatus(booking_status, order_status);

    return (
        <Container fixed>
            <Grid container>
                <Grid item xs={12}>
                    <OrderNumber orderId={order_id} />
                </Grid>
                <Grid item xs={12}>
                    <BookStatus
                        timestamp={timestamp}
                        createdAt={status_at}
                        bookStatus={booking_status}
                        orderStatus={order_status}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} md={9} lg={9} className={classes.contentContainer}>
                    {isAccessToPayment && !voidStatus && (
                        <div className={classes.paymentRoot}>
                            <PaymentForm
                                timelimit={timelimit}
                                setPaymentMethod={setPaymentMethod}
                                paymentMethod={paymentMethod}
                                availablePaymentMethods={availablePaymentMethods}
                                setPaymentRequest={handelPaymentRequest}
                            />
                        </div>
                    )}
                    <BookData
                        bookingNumber={booking_number}
                        airlineBookingNumber={airline_booking_number}
                        payerPhone={payer_phone}
                        payerEmail={payer_email}
                        passengers={passengers_data}
                        orderId={order_id}
                        bookStatus={booking_status}
                        sessionId={session_id}
                        currency={currency}
                        totalPrice={total_price}
                        partnerFees={partner_fees}
                    />
                    <RoutesTitle />
                    <RoutesData routes={routes} fareRules={fare_rules} included={included} />
                    <LocalTimeText />
                    <RenderUpButton />
                </Grid>
                <Grid item xs={12} md={4} lg={3} className={classes.sidebarContainer}>
                    <FixedRightBlock
                        orderData={orderData}
                        setVoidStatus={setVoidStatus}
                        voidStatus={voidStatus}
                        orderCancel={orderCancel}
                        refundOrder={handelOrderRefund}
                        isLoadingRefund={isLoadingRefund}
                        partnerCode={partnerCode}
                    />
                </Grid>
            </Grid>
            <SnackbarAlert
                severity={alertData.severity}
                message={alertData.message}
                isOpen={openAlert}
                setIsOpen={setOpenAlert}
            />
            <AlertModal message={changedStatusMessage()} isOpen={isChangedStatus} setOpen={setIsChangedStatus} />
        </Container>
    );
}

const handelOrderCancel = (setVoidStatus, orderCancel) => {
    setVoidStatus(true);
    orderCancel();
};

const FixedRightBlock = props => {
    const classes = useStyles();
    const { t } = useLocalization();

    const { orderData, setVoidStatus, voidStatus, orderCancel, refundOrder, isLoadingRefund, partnerCode } = props;
    const { currency, passengers_data, booking_status, order_status, partner_fees, partner_code, total_price } =
        orderData;
    const isAllowedCancel = checkIsAllowedCancel(booking_status, order_status);

    if (isAllowedCancel) {
        return (
            <div>
                <PriceForm
                    totalPrice={total_price}
                    partnerFees={partner_fees}
                    currency={currency}
                    passengers={passengers_data}
                    forOrder={true}
                />
                {voidStatus ? (
                    <>
                        <div className={classes.voidInProcessBlock}>
                            <LoadingSniper />
                            <span>{t.void_in_process}</span>
                        </div>

                        <Divider />
                    </>
                ) : (
                    <>
                        <div className={classes.voidbuttonBlock}>
                            <VoidButton setVoidStatus={setVoidStatus} orderCancel={orderCancel} />
                            <VoidText />
                        </div>

                        <Divider />
                    </>
                )}

                <CopyLinkButton orderData={orderData} />
            </div>
        );
    }

    return (
        <div>
            {false && (
                <OrderTicketsOptions
                    bookingStatus={booking_status}
                    isLoadingRefund={isLoadingRefund}
                    refundOrder={refundOrder}
                />
            )}
            <div className={classes.helpContainer}>
                <HelpBlock />
            </div>
        </div>
    );
};

const LoadingSniper = () => {
    const classes = useStyles();

    return (
        <span className={classes.loadingSniperBlock}>
            <CircularProgress color="inherit" size="1rem" />
        </span>
    );
};

const VoidText = () => {
    const classes = useStyles();
    const { t } = useLocalization();

    return (
        <div className={classes.voidBlock}>
            <span className={classes.voidText}>{t.void_text}</span>
        </div>
    );
};

const VoidButton = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { setVoidStatus, orderCancel } = props;

    return (
        <div className={classes.voidButtonBlock}>
            <StyledVoidButton onClick={() => handelOrderCancel(setVoidStatus, orderCancel)} heightSize="40px">
                {t.void_order}
            </StyledVoidButton>
        </div>
    );
};

const CopyLinkButton = ({ orderData }) => {
    const classes = useStyles();
    const { t } = useLocalization();
    const [showSnackbar, setSnackbarShow] = useState(false);

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(`${window.location.host}/order/${orderData.order_id}?key=${orderData?.key}`);
        setSnackbarShow(true);
    };

    return (
        <div className={classes.copyLinkBlock}>
            <ButtonUI title={t.copy_link} onClick={copyLinkToClipboard} />

            <div className={classes.voidBlock}>
                <span className={classes.voidText}>{t.copy_order_link_text}</span>
            </div>

            <Snackbar
                show={showSnackbar}
                duration={5000}
                onClose={() => setSnackbarShow(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                alertVariant="filled"
                alertSeverity="success"
                alertText={t.link_copied_to_clipboard}
            />
        </div>
    );
};

const RoutesTitle = () => {
    const classes = useStyles();
    const { t } = useLocalization();

    return <div className={classes.routesTitle}>{t.flight_overview}</div>;
};

const RenderUpButton = () => {
    const classes = useStyles();
    const { t } = useLocalization();

    return (
        <div className={classes.upButtonContainer}>
            <ScrollUpButton>
                <StyledButton type="button" widthSize="128px">
                    {t.to_top}
                </StyledButton>
            </ScrollUpButton>
        </div>
    );
};

export const OrderNumber = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { orderId } = props;

    return (
        <div className={classes.orderNumberContainer}>
            {t.order} {orderId}
        </div>
    );
};

export const BookStatus = props => {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const { createdAt, timestamp, bookStatus, orderStatus } = props;
    const isTicketingDoneStatus = bookStatus === TICKETING_DONE_CODE;

    const statusColor = getColorStatus(bookStatus);

    return (
        <div className={classes.statusContainer}>
            <div className={classes.circle} style={{ backgroundColor: statusColor }} />
            <div className={classes.statusBlock} style={{ color: statusColor }}>
                {getBookStatus(bookStatus, orderStatus, t)}{' '}
                {isTicketingDoneStatus && getCreatedTime(createdAt, language)}
            </div>
            <div className={classes.createdAtBlock}>
                • {t.order_created} {getCreatedTime(timestamp, language)}
            </div>
        </div>
    );
};

const OrderTicketsOptions = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { isLoadingRefund, refundOrder } = props;

    return (
        <div className={classes.ticketsOptionsButtons}>
            <ButtonUI
                fontSize="16px"
                height="38px"
                title={t.return_ticket}
                onClick={refundOrder}
                isLoading={isLoadingRefund}
                disabled={isLoadingRefund}
            />
        </div>
    );
};
