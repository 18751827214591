import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    ordersBlock: {
        paddingTop: '24px',

        '@media (max-width: 960px)': {
            paddingTop: '16px',
        },
    },
    ordersBlockTitle: {
        fontSize: '24px',
        fontWeight: '500',
        color: '#212121',
    },
});
