import React from 'react';
import { Dialog, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useLocalization from '../../../../contexts/localization/hook';

const useStyles = makeStyles({
    contentContainer: {
        padding: '32px 16px',
        fontSize: '16px',
    },
});

export default function AlertModal(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const { message, setOpen, isOpen } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'xs'} fullWidth={true}>
            <div className={classes.contentContainer}>{message}</div>
            <DialogActions>
                <Button onClick={handleClose}>{t.close}</Button>
            </DialogActions>
        </Dialog>
    );
}

AlertModal.defaultProps = {
    message: null,
    isOpen: false,
    setOpen: () => {},
};
