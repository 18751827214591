const defaultLanguages = {
    ru: {
        ru: 'Русский',
        tj: 'Таджикский',
        en: 'Английский',
    },
    tj: {
        ru: 'Русӣ',
        tj: 'Тоҷикӣ',
        en: 'Англисӣ',
    },
    en: {
        ru: 'Russian',
        tj: 'Tajik',
        en: 'English',
    },
};

export default defaultLanguages;
