import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useLocalization from '../../contexts/localization/hook';

export default function LoadingForBlock() {
    const classes = useStyles();

    return (
        <div className={classes.rootContainerLoadingBlock}>
            <RenderLoading />
        </div>
    );
}

const RenderLoading = () => {
    const classes = useStyles();
    const { t } = useLocalization();

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.rootBlockLoadingBlock}
        >
            <div className={classes.titleLoadingBlock}>{t.wait_please}</div>
            <CircularProgress size={86} className={classes.circularProgress} />
        </Grid>
    );
};
