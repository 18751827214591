import React, { useState } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import { setBrandFareRecId } from '../../../../../../store/resultData/actions';

import TariffItem from '../tariffItem';

const Tariffs = ({ brandRecId, recId, getFareRules, brandFares, brandName, currency }) => {
    const dispatch = useDispatch();
    const { brandRules } = useSelector(state => state.resultData);

    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(false);

    const handleCheckTariffs = value => {
        dispatch(setBrandFareRecId({ recId, brandRecId: value }));
    };

    const handleGetFareRules = recId => {
        getFareRules(recId);
    };

    const onUpdate = ({ translate }) => {
        if (translate > 9.5) {
            setHideLeftArrow(true);
        }

        if (translate < -1431.5) {
            setHideRightArrow(true);
        }

        if (translate < 9.5 && translate > -1431.5) {
            setHideLeftArrow(false);
            setHideRightArrow(false);
        }
    };

    const tariffsItems = brandFares.map((brandFare, index) => {
        let isChecked = false;

        if (brandRecId) {
            isChecked = brandFare.rec_id === brandRecId;
        } else {
            isChecked = brandFare.brand_name === brandName;
        }

        return (
            <TariffItem
                key={index}
                brandFare={brandFare}
                isChecked={isChecked}
                checkTariffs={handleCheckTariffs}
                handleGetFareRules={handleGetFareRules}
                brandRules={brandRules}
                currency={currency}
            />
        );
    });

    return (
        <ScrollMenu
            data={tariffsItems}
            arrowLeft={<ArrowLeft status={hideLeftArrow} />}
            arrowRight={<ArrowRight status={hideRightArrow} />}
            onUpdate={onUpdate}
            wrapperStyle={{ padding: '16px 0' }}
        />
    );
};

const Arrow = ({ type }) => {
    return (
        <div className="arrowCircle">
            <div className="arrowCircleNav">{type === 'prev' ? <NavigateBefore /> : <NavigateNext />}</div>
        </div>
    );
};

const ArrowLeft = ({ status }) => {
    if (status) {
        return <div />;
    }

    return <Arrow type="prev" />;
};

const ArrowRight = ({ status }) => {
    if (status) {
        return <div />;
    }

    return <Arrow type="next" />;
};

export default Tariffs;
