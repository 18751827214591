import React, { useState, useEffect } from 'react';
import moment from 'moment';

import useLocalization from '../../../../../../contexts/localization/hook';

import { ReactComponent as CalendarIcon } from '../../../../../../assets/icons/calendarIcon.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/closeIcon.svg';

import { FromDate, ToDate, Input, IconButton, Icon } from './components';
import ScrollingCalendar from '../../scrollingCalendar';

const DateFromToField = ({ onSelect, selected }) => {
    const { t } = useLocalization();

    const [values, setValues] = useState(selected);
    const [isActive, setIsActive] = useState(null);
    const [activeDirection, setActiveDirection] = useState('from');

    useEffect(() => {
        setValues(selected);
    }, [selected]);

    const handleFocus = input => {
        setIsActive(true);
        setActiveDirection(input);
    };

    const handleSelect = dates => {
        setValues(dates);
        onSelect(dates);
    };

    const handleClose = () => {
        setIsActive(false);
    };

    const handleRemoveDate = direction => {
        const dates = [...selected];

        if (direction === 'from') {
            dates[0] = null;
        } else {
            dates[1] = null;
        }

        setValues(dates);
        onSelect(dates);
    };

    return (
        <>
            <FromDate>
                <Input
                    type="text"
                    readOnly
                    placeholder={t.date_from}
                    onFocus={() => handleFocus('from')}
                    value={values[0] ? moment(values[0]).format('DD MMMM YYYY') : ''}
                    direction="from"
                    isActive={isActive === 'from'}
                />
                {values[0] ? (
                    <IconButton isActive={isActive === 'from'}>
                        <CloseIcon onClick={() => handleRemoveDate('from')} />
                    </IconButton>
                ) : (
                    <Icon active>
                        <CalendarIcon style={{ color: 'fff' }} />
                    </Icon>
                )}
            </FromDate>

            <ToDate>
                <Input
                    type="text"
                    readOnly
                    placeholder={t.date_to}
                    onFocus={() => handleFocus('to')}
                    value={values[1] ? moment(values[1]).format('DD MMMM YYYY') : ''}
                    direction="to"
                    isActive={isActive === 'to'}
                />
                {values[1] && (
                    <IconButton isActive={isActive === 'to'}>
                        <CloseIcon onClick={() => handleRemoveDate('to')} />
                    </IconButton>
                )}
            </ToDate>

            <ScrollingCalendar
                type="twoSelects"
                isActive={isActive}
                onClose={handleClose}
                onSelect={handleSelect}
                value={values}
                activeDirection={activeDirection}
                setActiveDirection={direction => setActiveDirection(direction)}
            />
        </>
    );
};

export default DateFromToField;
