import initialState from './initialState';
import { SET_IS_LOADING, SET_SELECTED_TYPE, SET_FLIGHTS, SET_SELECTED_AIRPORT } from './constants';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_SELECTED_TYPE:
            return {
                ...state,
                selectedType: action.payload,
            };
        case SET_FLIGHTS:
            return {
                ...state,
                arrivals: action.payload.arrivals,
                departures: action.payload.departures,
                isLoading: false,
            };
        case SET_SELECTED_AIRPORT:
            return {
                ...state,
                selectedAirport: action.payload,
            };
        default:
            return state;
    }
};
