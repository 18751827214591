import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Icon = styled.span`
    cursor: pointer;
    margin-left: 8px;
`;
