import React, { useState } from 'react';
import { CF, OW, RT } from '../../../../../containers/search/constants';
import useLocalization from '../../../../../contexts/localization/hook';

import { addToLocalStorage, getActualHistory } from '../../../../../lib/searchHistory';
import {
    getCabinFromSearchId,
    getCityInfoByCode,
    getDateFromSearchId,
    getPassengersFromSearchId,
} from '../../../../../lib/searchId';

import DesktopSearchHistory from './desktop';
import MobileSearchHistory from './mobile';

const SearchHistory = () => {
    const { t } = useLocalization();

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [cities, setCities] = useState([]);

    const parseSearchId = searchId => {
        const data = searchId.split('-');

        if (data.length > 0) {
            return getSearchParams(data[0], data);
        }

        return null;
    };

    const getSearchParams = (flightType, data) => {
        const params = {
            cabin: '',
            flightType,
            passengers: {},
            routes: [],
        };

        if (!data[2]) {
            data[2] = 'A1000';
        }

        if (flightType === OW) {
            params.routes = [getRouteFromSearchId(flightType, data[1])];
            params.cabin = getCabinFromSearchId(data[2].substr(0, 1));
            params.passengers = getPassengersFromSearchId(data[2]);
        } else if (flightType === RT) {
            params.routes = [...getRouteFromSearchId(flightType, data[1])];
            params.cabin = getCabinFromSearchId(data[2].substr(0, 1));
            params.passengers = getPassengersFromSearchId(data[2]);
        } else if (flightType === CF) {
            const routes = [];
            for (let i = 1; i < data.length - 1; i++) {
                routes.push(getRouteFromSearchId(flightType, data[i]));
            }
            params.routes = routes;
            params.cabin = getCabinFromSearchId(data[data.length - 1].substr(0, 1));
            params.passengers = getPassengersFromSearchId(data[data.length - 1]);
        }

        return params;
    };

    const getRouteFromSearchId = (flightType, route) => {
        if (flightType === RT) {
            return [
                {
                    from: route.substr(0, 3),
                    to: route.substr(7, 3),
                    date: getDateFromSearchId(route.substr(3, 4)),
                },
                {
                    from: route.substr(7, 3),
                    to: route.substr(0, 3),
                    date: getDateFromSearchId(route.substr(10)),
                },
            ];
        } else {
            return {
                from: route.substr(0, 3),
                to: route.substr(7),
                date: getDateFromSearchId(route.substr(3, 4)),
            };
        }
    };

    const getHistory = async () => {
        setLoading(true);
        const actualHistory = getActualHistory();
        const result = [];
        const citiesInSearch = [];
        const citiesInfo = [];

        for (const history of actualHistory) {
            const response = parseSearchId(history.searchId);

            result.push({
                ...response,
                ...history,
            });
        }

        for (const search of result) {
            search.routes.forEach(route => {
                if (!citiesInSearch.includes(route.from)) citiesInSearch.push(route.from);
                if (!citiesInSearch.includes(route.to)) citiesInSearch.push(route.to);
            });
        }

        for (const city of citiesInSearch) {
            const cityInfo = await getCityInfoByCode(city);

            citiesInfo.push(cityInfo);
        }

        setCities(citiesInfo);
        setItems(result.reverse());
        setLoading(false);
    };

    const flightClassItems = [
        { value: 'economy', label: t.economy },
        { value: 'business', label: t.business },
        { value: 'first', label: t.first },
        { value: 'all', label: t.all_class },
    ];

    const clearHistorySearch = () => {
        setItems([]);
        addToLocalStorage([]);
    };

    return (
        <div>
            <DesktopSearchHistory
                getHistory={getHistory}
                loading={loading}
                items={items}
                cities={cities}
                flightClassItems={flightClassItems}
                clearHistory={clearHistorySearch}
            />

            <MobileSearchHistory
                getHistory={getHistory}
                loading={loading}
                items={items}
                cities={cities}
                flightClassItems={flightClassItems}
                clearHistory={clearHistorySearch}
            />
        </div>
    );
};

export default SearchHistory;
