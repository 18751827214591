import styled from 'styled-components';

export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
`;

export const BrandsWrapper = styled.div`
    padding: 16px 0 0;
`;

export const BrandsContainer = styled.div`
    background-color: #fff;
    padding: 16px 0 0;
`;

export const BrandsMessageBlock = styled.div`
    color: #9da8ae;
    font-size: 16px;
    padding-left: 78px;
`;

export const BrandsMessageIcon = styled.div`
    display: inline-block;
`;

export const BrandsMessageTitle = styled.div`
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin-left: 6px;
`;

export const BrandsBlock = styled.div`
    padding: 16px 0 24px 0;
`;
