const initialState = {
    flightType: 'RT',
    cabin: 'all',
    passengers: {
        adt: 1,
        chd: 0,
        ins: 0,
        inf: 0,
    },
    routes: [
        {
            from: null,
            to: null,
            date_from: null,
        },
        {
            from: null,
            to: null,
            date_from: null,
        },
    ],
    hasData: false,
    notFilledFields: {
        from: false,
        to: false,
        date: false,
    }
};

export default initialState;
