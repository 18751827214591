import { SET_APP_CONFIGS, SET_CURRENCY } from './constants';

export const setAppConfigs = appConfigs => dispatch =>
    dispatch({
        type: SET_APP_CONFIGS,
        payload: appConfigs,
    });

export const setCurrency = currency => dispatch =>
    dispatch({
        type: SET_CURRENCY,
        payload: currency,
    });
