import { SET_PRE_BOOK_DATA, SET_COUNTRIES, SET_PASSENGERS_LIST, SET_PASSENGERS_LIST_FILTER } from './constants';

export const setPreBookData = data => dispatch =>
    dispatch({
        type: SET_PRE_BOOK_DATA,
        payload: data,
    });

export const setCountries = data => dispatch =>
    dispatch({
        type: SET_COUNTRIES,
        payload: data,
    });

export const setPassengersList = data => dispatch =>
    dispatch({
        type: SET_PASSENGERS_LIST,
        payload: data,
    });

export const setPassengersListFilter = data => dispatch =>
    dispatch({
        type: SET_PASSENGERS_LIST_FILTER,
        payload: data,
    });
