import React, { useCallback, useState, useEffect, useRef } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import {
    Form,
    FormTitle,
    FormSubtitle,
    FormGroup,
    FormSubmitButton,
} from '../../components/design_v2/authPages/components';
import TextInput from '../../components/design_v2/ui/textInput';

import { nonAuthRoutes } from '../../config/routes';
import API from '../../lib/api';
import useLocalization from '../../contexts/localization/hook';
import Alert from '../../components/design_v2/ui/alert';
import envConfigs from '../../config/envConfigs';

const ForgotPassword = () => {
    const { isFullScreenSearchBlock, isAuth } = useSelector(state => state.appConfigs);

    const { language, t } = useLocalization();
    const containerRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);

    const validationSchema = Yup.object().shape({
        email: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
    });

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, [isFullScreenSearchBlock]);

    useEffect(() => {
        document.title = `${t.page_forgot_password} | ${envConfigs.appTitle}`;
    }, [language]);

    const handleKeyPress = useCallback(e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleSubmit();
        }
    }, []);

    const handleFormSubmit = useCallback(
        values => {
            if (isLoading) {
                return;
            }

            setIsLoading(true);

            API.postForgotPassword(values)
                .then(response => {
                    if (response.code == 200) {
                        setSuccess(true);
                        setIsLoading(false);
                    } else if (response.code == 404) {
                        setSuccess(false);
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    setFieldError('email', error.response.data.message);
                    setSuccess(false);
                    setIsLoading(false);
                });
        },
        [isLoading]
    );

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: handleFormSubmit,
        validateOnBlur: true,
    });

    if (isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <Container fixed ref={containerRef}>
            <Form>
                <FormTitle>{t.forgot_password}</FormTitle>

                <FormSubtitle>{t.forgot_password_summary}</FormSubtitle>
                {success && (
                    <Alert type="success" title={t.forgot_success_title}>
                        {t.forgot_success_content}
                    </Alert>
                )}
                {!success && success != null && (
                    <Alert type="error" title={t.forgot_error_title}>
                        {t.forgot_error_content}
                    </Alert>
                )}
                {!success && (
                    <>
                        <FormGroup error={errors.email}>
                            <TextInput
                                value={values.email}
                                onChangeText={handleChange('email')}
                                type="email"
                                required
                                placeholder={t.enter_email}
                                label={t.email}
                                error={errors.email}
                                onBlur={handleBlur('email')}
                                touched={touched.email}
                            />
                        </FormGroup>
                        <FormSubmitButton onClick={handleSubmit}>
                            {isLoading ? <CircularProgress color="inherit" size={26} /> : `${t.continue}`}
                        </FormSubmitButton>
                    </>
                )}
            </Form>
        </Container>
    );
};

export default withRouter(ForgotPassword);
