import React, { useState, Fragment } from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import useLocalization from '../../../../contexts/localization/hook';

const useStyles = makeStyles({
    contentContainer: {
        padding: '16px 32px',
        fontSize: 14,
    },
    titleBlock: {
        paddingBottom: 8,
        fontWeight: 500,
    },
    titleContent: {
        fontStyle: 'oblique',
    },
    session: {
        fontWeight: 500,
    },
});

function ErrorModal(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const { error_code, error_desc, custom_error, sessionId, withArea } = props;
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        window.location.href = '/';
    };

    const withAreaStyle = {
        height: withArea ? '600px' : '10px',
        position: 'relative',
    };

    return (
        <Fragment>
            <div style={withAreaStyle}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    style={{
                        position: 'absolute',
                        bottom: '25%',
                    }}
                >
                    <div className={classes.contentContainer}>
                        <div className={classes.titleBlock}>
                            <div>
                                <span>{t.code}: </span>
                                <span className={classes.titleContent}>{error_code}</span>
                            </div>
                            <div>
                                <span>{t.message}: </span>
                                <span className={classes.titleContent}>{error_desc}</span>
                            </div>
                        </div>
                        <div>{custom_error || RenderMessage(sessionId)}</div>
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose}>{t.close}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fragment>
    );
}

const RenderMessage = sessionId => {
    const classes = useStyles();
    const { t } = useLocalization();

    if (sessionId) {
        return (
            <Fragment>
                <div>
                    {t.error_message_part_1} {t.error_message_part_2}:
                    <span className={classes.session}>{sessionId}</span>.
                </div>
                <div>{t.error_message_part_3}</div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div>{t.error_message_part_1}.</div>
            <div>{t.error_message_part_3}</div>
        </Fragment>
    );
};

ErrorModal.defaultProps = {
    error_code: null,
    error_desc: null,
    sessionId: null,
    withArea: true,
};

export default ErrorModal;
