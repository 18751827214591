import React, { Component } from 'react';
import { WarningMessage } from '../../../../styles/common/index';

class InputWarning extends Component {
    render() {
        const { text } = this.props;

        return <WarningMessage>{text}</WarningMessage>;
    }
}

export default InputWarning;
