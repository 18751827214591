import styled from 'styled-components';

export const Component = styled.div`
    position: relative;
    width: 100%;
`;

export const Label = styled.div`
    font-size: 16px;
    color: #404b5a;
    margin-bottom: 4px;
    font-weight: bold;
`;

export const SelectBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    border: 1px solid #c3d6e8;
    border-radius: 12px;
    padding: 0 10px;
    background-color: #fff;
    cursor: pointer;
`;

export const Value = styled.span`
    font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
    color: ${props => props.color};
`;

export const Icon = styled.span`
    transition: all 0.3s ease;

    &.active {
        transition: all 0.3s ease;
        transform: rotate(180deg);
    }
`;

export const DropdownBlock = styled.div`
    display: ${props => (props.isActive ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: calc(100% + 8px);
    width: 100%;
    background-color: #fff;
    z-index: 15;
    padding: 8px 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    border-radius: 8px;
`;

export const Item = styled.button`
    width: 100%;
    padding: 16px;
    background-color: transparent;
    text-align: left;
    border: none;

    &:hover {
        background-color: #c3d6e8;
        cursor: pointer;
    }
`;
