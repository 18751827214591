import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import configs from '../../config';
import API from '../../lib/api';
import LocalizationContext from './context';
import { setAppConfigs } from '../../store/appConfigs/actions';
import MainLoading from '../../components/design_v2/mainLoading';

const language = configs.mainConfigs.language;
const langDataArray = JSON.parse(localStorage.getItem('available_languages'));

export default props => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [appLanguage, setAppLanguage] = useState(language);
    const [langData, setLangData] = useState(langDataArray);
    const [availableLanguages, setAvailableLanguages] = useState(langDataArray ? Object.keys(langDataArray) : []);

    useEffect(() => {
        API.getRequisites().then(response => {
            const { available_languages, logo, available_currencies } = response.data;

            let currency = localStorage.getItem('currency');
            const availableCurrencies = JSON.parse(available_currencies);

            if (currency) {
                const include = _.includes(availableCurrencies, currency);
                if (!include) {
                    localStorage.setItem('currency', availableCurrencies[0]);
                    currency = availableCurrencies[0];
                }
            } else {
                localStorage.setItem('currency', availableCurrencies[0]);
                currency = availableCurrencies[0];
            }

            const serializedResponse = JSON.stringify(available_languages);

            localStorage.setItem('available_languages', serializedResponse);
            const languages = Object.keys(available_languages);

            if (languages.findIndex(x => x === appLanguage) > -1) {
                localStorage.setItem('language', appLanguage);
                document.documentElement.setAttribute('lang', appLanguage);
                moment.locale(appLanguage === 'tj' ? 'tg' : appLanguage);
            } else {
                localStorage.setItem('language', languages[0]);
                setAppLanguage(languages[0]);
                document.documentElement.setAttribute('lang', languages[0]);
                moment.locale(languages[0] === 'tj' ? 'tg' : languages[0]);
            }

            setAvailableLanguages(languages);
            setLangData(available_languages);
            dispatch(setAppConfigs({ logoUrl: logo, currency, availableCurrencies }));

            setLoading(false);
        });
    }, []);

    const switchLang = lang => {
        setAppLanguage(lang);
        moment.locale(lang === 'tj' ? 'tg' : lang);
        localStorage.setItem('language', lang);
        document.documentElement.setAttribute('lang', lang);
    };

    if (loading) return <MainLoading />

    return (
        <LocalizationContext.Provider
            value={{
                language: appLanguage,
                switchLang,
                availableLanguages,
                t: langData ? (_.isUndefined(langData[appLanguage]) ? {} : langData[appLanguage]) : {},
            }}
        >
            {props.children}
        </LocalizationContext.Provider>
    );
};
