import styled from 'styled-components';

export const Input = styled.input`
    background-color: transparent;
    width: 286px;
    height: 60px;
    margin: 0;
    padding: 8px 64px 8px 20px;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    position: relative;
    color: #fff;
    cursor: pointer;

    z-index: 5;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 1279px) {
        width: 100%;
        border-radius: 12px;
    }

    @media (max-width: 959px) {
        width: 100%;
        border-radius: 12px;
    }

    ::placeholder {
        color: #fff;
    }
`;

export const IataCode = styled.span`
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    right: 16px;
    top: calc(50% - 0.45rem);
    font-size: 0.9rem;
    text-transform: uppercase;
    line-height: 1;
    color: #fff;
`;