import React from 'react';

import useLocalization from '../../../contexts/localization/hook';

import { StyledButton } from '../../../styles/common/index';
import { useStyles } from '../../../containers/booking/styles';

export const Submit = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { disabled } = props;

    return (
        <div className={classes.submitBlock}>
            <StyledButton type="submit" widthSize="100%" disabled={!disabled}>
                {t.book_and_proceed_to_payment}
            </StyledButton>
        </div>
    );
};
