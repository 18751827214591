import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    component: {
        width: '100%',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 100,
        backgroundColor: '#F6F9FF',
        height: '100px',
        maxHeight: 100,
        color: '#fff',

        '@media (max-width: 599px)': {
            maxHeight: 80,
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
    },
});

export default useStyles;
