import styled from 'styled-components';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';

export const Image = styled.img`
    width: 100%;
    max-height: 500px;
`;

export const Title = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #404b5a;
`;

export const Block = styled.div`
    padding: 32px 0 64px;
`;

export const AccordionHeader = styled(AccordionSummary)`
    padding: 8px 16px !important;
    font-size: 20px;
    font-weight: bold;
    color: #404b5a !important;
`;

export const AccordionBody = styled(AccordionDetails)`
    width: 100%;
`;

export const LinksBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const Link = styled.button`
    margin-bottom: 16px;
    color: #00ddff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    text-align: left;
`;

export const HtmlView = styled.div`
    & img {
        max-width: 100% !important;
    }
`;

export const MobileModalTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #404b5a;
`;
