import React, { useState, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import _ from 'lodash';
import { useStyles } from '../../containers/order/styles';
import configs from '../../config';
import TimelimitCounter from '../ui/components/TimelimitCounter/TimelimitCounter';
import PaymentMethods from './paymentMethods';
import ButtonUI from '../ui/components/ButtonUI/ButtonUI';
import { parserLangCode } from '../result/common';
import useLocalization from '../../contexts/localization/hook';
import { useSelector } from 'react-redux';
import { B2C } from '../../containers/order/constants';

export default function PaymentForm(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const { timelimit, setPaymentMethod, paymentMethod, availablePaymentMethods, setPaymentRequest } = props;
    const [timerActive, setTimerActive] = useState(timelimit > 0);

    const disableTimer = () => {
        setTimerActive(false);
    };

    if (timerActive) {
        return (
            <div className={classes.paymentContainer}>
                <Grid container className={classes.paymentBlock}>
                    <Grid item xs={12} className={classes.paymentTitle}>
                        {t.payment_method}
                    </Grid>
                    <Grid item xs={12} className={classes.paymentMethodItem}>
                        <PaymentMethodsItems
                            setPaymentMethod={setPaymentMethod}
                            paymentMethod={paymentMethod}
                            availablePaymentMethods={availablePaymentMethods}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7} md={12} lg={'auto'}>
                                <div className={classes.paymentTimelimitBlock}>
                                    <TimelimitCounter timelimit={timelimit} disableTimer={disableTimer} />
                                    <TimelimitLabels timelimit={timelimit} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5} md={12} lg={'auto'}>
                                <div className={classes.paymentButtonBlock}>
                                    {!_.isNull(paymentMethod) && (
                                        <PaymentButton
                                            setPaymentRequest={setPaymentRequest}
                                            paymentMethod={paymentMethod}
                                        />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <div className={classes.paymentContainer}>
            <Grid container className={classes.paymentBlock}>
                <Grid item className={classes.paymentTimelimitBlock}>
                    <TimelimitCounter timelimit={0} disableTimer={disableTimer} />
                </Grid>
            </Grid>
        </div>
    );
}

const PaymentMethodsItems = props => {
    const classes = useStyles();
    const { availablePaymentMethods, setPaymentMethod, paymentMethod } = props;
    const { isAuth } = useSelector(state => state.appConfigs);

    return _.map(availablePaymentMethods, (item, index) => {
        if (!isAuth && item.code === B2C) {
            return null;
        }

        return (
            <Fragment key={index}>
                <div className={classes.cardBlock}>
                    <PaymentMethods
                        onChange={setPaymentMethod}
                        checkedPaymentMethod={paymentMethod}
                        methodData={item}
                    />
                </div>
                <Grid item className={classes.lineBlock}>
                    <Divider className={classes.line} />
                </Grid>
            </Fragment>
        );
    });
};

const PaymentButton = props => {
    const { t } = useLocalization();
    const { paymentMethod, setPaymentRequest } = props;

    let buttonTitle = t.pay_for_the_order;

    return (
        <ButtonUI
            onClick={() => setPaymentRequest(paymentMethod)}
            title={buttonTitle}
            fontSize="20px"
            height="50px"
            isGreen={true}
        />
    );
};

const TimelimitLabels = props => {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const { timelimit } = props;
    const timelimitTime = moment()
        .locale(parserLangCode(language))
        .add(timelimit, 'seconds')
        .format(configs.mainConfigs.serverDateTimeFormat);

    return (
        <div className={classes.timelimitLabelsContainer}>
            <div>{t.left_before_the_time_limit}</div>
            <div>
                {t.expires} {timelimitTime}
            </div>
        </div>
    );
};
