import { FormSubtitle } from '../../components/design_v2/authPages/components';
import styled from 'styled-components';

export const SuccessSubtitle = styled(FormSubtitle)`
    font-size: 24px;
    color: #32cd32;
    margin-top: 40px;
`;

export const ErrorSubtitle = styled(FormSubtitle)`
    font-size: 24px;
    color: red;
    margin-top: 40px;
`;
