export function checkForMIMEType(response, mimetype) {
    let blob;

    if (mimetype == 'pdf') {
        blob = converBase64toBlob(response, 'application/pdf');
    } else if (mimetype == 'doc') {
        blob = converBase64toBlob(response, 'application/msword');
    }

    let blobURL = URL.createObjectURL(blob);

    window.open(blobURL);
}

function converBase64toBlob(content, contentType) {
    contentType = contentType || '';

    let sliceSize = 512;
    let byteCharacters = window.atob(content);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {

        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, {
        type: contentType
    });

    return blob;
}