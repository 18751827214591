import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/tg';

import {
    Month,
    MonthBlock,
    Months,
    MonthTitle,
    MonthWeeks,
    WeekBlock,
    WeekdayButton,
    WeekName,
    WeekNames,
    WeekNamesBlock,
    Wrapper,
} from './components';
import { getMonths, getWeeks } from './utils';

const Calendar = ({ selectedDates, handleSelect, type, activeDirection, lastSelectedDate }) => {
    const [hoveredDay, setHoveredDay] = useState(null);

    const handleMouseHover = day => {
        if (activeDirection === 'to') {
            setHoveredDay(day);
        }
    };

    const handleMouseLeave = () => {
        setHoveredDay(null);
    };

    const renderWeekHeader = () => {
        const weekDays = [...Array(7).keys()];

        return (
            <WeekNames>
                {weekDays.map(day => {
                    return <WeekName key={`calendar-week-day-${day}`}>{moment().weekday(day).format('dd')}</WeekName>;
                })}
            </WeekNames>
        );
    };

    const renderWeek = (week, index, monthIndex) => (
        <WeekBlock key={`month-${monthIndex}-week-${index}`}>
            {week.map((day, ind) => {
                if (day) {
                    return (
                        <WeekdayButton
                            key={`month-${monthIndex}-week-${index}-day-${ind}`}
                            disabled={day.disabled}
                            active={day.active}
                            between={day.between}
                            onClick={() => handleSelect(day)}
                            onMouseEnter={() => handleMouseHover(day.date)}
                            onMouseLeave={() => handleMouseLeave()}
                        >
                            {day.date.format('D')}
                        </WeekdayButton>
                    );
                } else {
                    return (
                        <WeekdayButton disabled key={`month-${monthIndex}-week-${index}-day-${ind}`}></WeekdayButton>
                    );
                }
            })}
        </WeekBlock>
    );

    const renderMonth = (month, index) => {
        let weeks = null;

        if (type === 'twoSelects') {
            weeks = getWeeks(month, selectedDates, hoveredDay, type);
        } else {
            weeks = getWeeks(month, selectedDates, lastSelectedDate, type);
        }

        return (
            <MonthBlock key={`calendar-month-${index}`}>
                <Month>
                    <MonthTitle>
                        {month.startDate.format('MMMM')} {month.startDate.format('YYYY')}
                    </MonthTitle>
                    <MonthWeeks>{weeks.map((week, weekIndex) => renderWeek(week, weekIndex, index))}</MonthWeeks>
                </Month>
            </MonthBlock>
        );
    };

    const renderMonths = () => {
        const monthsArray = getMonths(moment().startOf('month'));

        return monthsArray.map((value, index) => renderMonth(value, index));
    };

    return (
        <Wrapper>
            <WeekNamesBlock>{renderWeekHeader()}</WeekNamesBlock>
            <Months>{renderMonths()}</Months>
        </Wrapper>
    );
};

export default Calendar;
